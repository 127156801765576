import React, {Component} from 'react';

import Verificar from './Verificar';

import './Verificar.css';

class VerificarIndex extends Component {
	render() {
		return (
			<div className='VerificarIndex'>
				
				<Verificar/>
			
			</div>
		);
	}
}

export default VerificarIndex;
