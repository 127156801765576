import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import {HowToVoteOutlined, CalendarTodayOutlined, DeviceHubOutlined, PinDropOutlined, VerifiedUser} from "@material-ui/icons"
import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {RutaService} from "../../../../services/_Sis/RutaService/RutaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {ExpedienteService} from "../../../../services/_Sis/ExpedienteService/ExpedienteService";
import {KeyboardDatePicker} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ArchivosExpedientes from "./ArchivosExpedientes";
import SeccionExpedientes from "./SeccionExpedientes";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {Dropbox} from 'dropbox';
import Fab from "@material-ui/core/Fab";
import ModalValidacion from "./ModalValidacion";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import moment from "moment";


class ModalExpedientes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_expediente: (props.item || {}).id_expediente,
			id_acreditado: '',
			curp: '',
			rfc: '',
			fecha_nacimiento: null,
			fecha_ingreso: null,
			id_cat_sexo: '',
			id_cat_identificacion: '1',
			direccion: '',
			id_cat_comprobante_ingreso: '1',
			quien_es_quien: '1',
			actua_por: '1',
			curp_valido: '2',
			rfc_valido: '2',
			activo: true,
			
			listar_expedientes: [],
			listar_usuario_acreditado: [],
			listar_cat_sexo: [],
			listar_cat_identificacion: [],
			listar_cat_comprobante_ingreso: [],
			listar_cat_quien_es_quien: [],
			
			listar_archivo_integracion_expediente: [],
			archivos: [],
			
			validacion: {}
			
		};
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		//this.ListarExpediente();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		try {
			if (!FieldsJs.Field(this.state.fecha_ingreso)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo fecha de ingreso es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.id_acreditado)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo Acreditado es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.curp)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo CURP es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.rfc)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo RFC es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.fecha_nacimiento)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo fecha nacimiento es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.id_cat_sexo)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo Genero es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.direccion)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo dirección es requerido.",
				});
			}
			
			let ftar = this.state.listar_archivo_integracion_expediente;
			let doc_required = '';
			let cont_doc = 0;
			let cont_doc_completado = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (ftar[i].is_required) {
					cont_doc++;
				}
				if (!FieldsJs.Field(doc_required) && !FieldsJs.Field(ftar[i].archivo) && ftar[i].is_required) {
					doc_required = ftar[i].archivo_integracion_expediente;
				}
				if (!FieldsJs.Field(doc_required) && FieldsJs.Field(ftar[i].archivo) && ftar[i].is_required) {
					cont_doc_completado++;
				}
			}
			if (cont_doc_completado <= (cont_doc - 1) || cont_doc_completado === 0) {
				this.props.showSnackBars('error', "Faltan algunos documentos que anexar, por favor agrégalos lo antes posible.");
			} else if (FieldsJs.Field(doc_required)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "El documento "+doc_required+" es requerido.",
				});
			}
			
			showSpinner('spinner');
			
			if (this.state.id_expediente > 0) {
				let guardar = new Promise((resolve, reject) => {
					this.guardarExpedienteAcreditadoDbx().then(response => {
						console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
						this.setState({
							archivos: response
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", response);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					ExpedienteService.Modificar(this.state).then((response) => {
						this.close();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				})
				
			} else {
				let guardar = new Promise((resolve, reject) => {
					this.guardarExpedienteAcreditadoDbx().then(response => {
						console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
						this.setState({
							archivos: response
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", response);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					ExpedienteService.Agregar(this.state).then((response) => {
						this.close();
						this.props.RefreshList(response.data);
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				})
			}
			
			
		} catch (e) {
			if (!!e.popup_alert === true) {
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarPromotor('si')
					}
				});
			} else {
				this.props.showSnackBars('error', e.mensaje);
			}
		}
	};
	
	
	guardarExpedienteAcreditadoDbx = () => {
		let ftar = this.state.listar_archivo_integracion_expediente;
		console.log("1 FTAR::: ", ftar);
		
		let files;
		let archivos = [];
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					band++;
				}
			}
			
			if (band === 0) {
				resolve(archivos);
			}
			
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					console.log("2 FTAR::: ", ftar);
					files = ftar[i].file || undefined;
					
					if (FieldsJs.Field(files) === true) {
						DropboxApi.Upload('/Expedientes_Acreditados/' + this.state.curp + '/' + ftar[i].titulo + '.' + ftar[i].formato, files).then(function (response) {
							ftar[i].id_archivo = response.id;
							
							archivos.push(
								{
									"id_cat_archivo_integracion_expediente": ftar[i].id_cat_archivo_integracion_expediente,
									"titulo": ftar[i].titulo,
									"tipo_doc": ftar[i].tipo_doc,
									"validado": ftar[i].validado,
									"descripcion": ftar[i].descripcion,
									"id_archivo": ftar[i].id_archivo,
									"archivo": response.path_display
								}
							);
							
							band2++;
							
							console.log("DROPBOX UPLOAD: ", response, band, band2);
							
							if (band === band2) {
								resolve(archivos);
							}
						}).catch(function (error) {
							reject(error);
						});
					} else {
						archivos.push(
							{
								"id_cat_archivo_integracion_expediente": ftar[i].id_cat_archivo_integracion_expediente,
								"titulo": ftar[i].titulo,
								"tipo_doc": ftar[i].tipo_doc,
								"validado": ftar[i].validado,
								"descripcion": ftar[i].descripcion,
								"id_archivo": ftar[i].id_archivo,
								"archivo": ftar[i].archivo
							}
						);
						band2++;
						
						if (band === band2) {
							resolve(archivos);
						}
					}
				}
				
			}
		});
	};
	
	viewDbx = (item) => {
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let path = item.archivo;
		console.log("ITEM::: ", item, path);
		
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
	};
	
	add = () => {
		this.ListarUsuarioAcreditado();
		this.ListarSexo();
		this.ListarComprobanteIngreso();
		this.ListarIdentificacion();
		this.ListarArchivoIntegracionExpediente();
		
		console.log(this.props);
		this.setState({
			id_expediente: '',
			id_acreditado: '',
			curp: '',
			rfc: '',
			curp_valido: '2',
			rfc_valido: '2',
			fecha_nacimiento: null,
			fecha_ingreso: null,
			id_cat_sexo: '',
			id_cat_identificacion: '1',
			direccion: '',
			id_cat_comprobante_ingreso: '1',
			quien_es_quien: '1',
			actua_por: '1',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_expediente: item.id_expediente || '',
			curp: item.curp || '',
			rfc: item.rfc || '',
			curp_valido: item.curp_valido || '2',
			rfc_valido: item.rfc_valido || '2',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			id_cat_identificacion: item.id_cat_identificacion || '1',
			direccion: item.direccion || '',
			id_cat_comprobante_ingreso: item.id_cat_comprobante_ingreso || '1',
			quien_es_quien: item.quien_es_quien || '1',
			actua_por: item.actua_por || '1',
			fecha_ingreso: item.fecha_ingreso ? moment(item.fecha_ingreso) : null,
			listar_archivo_integracion_expediente: item.listar_archivo_integracion_expediente || [],
			activo: (item.activo === 1),
		});
		
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || '',
			});
		});
		this.ListarComprobanteIngreso();
		this.ListarIdentificacion();
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_expediente: item.id_expediente || '',
			curp: item.curp || '',
			rfc: item.rfc || '',
			curp_valido: item.curp_valido || '2',
			rfc_valido: item.rfc_valido || '2',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			id_cat_identificacion: item.id_cat_identificacion || '1',
			direccion: item.direccion || '',
			id_cat_comprobante_ingreso: item.id_cat_comprobante_ingreso || '1',
			quien_es_quien: item.quien_es_quien || '1',
			actua_por: item.actua_por || '1',
			fecha_ingreso: item.fecha_ingreso ? moment(item.fecha_ingreso) : null,
			listar_archivo_integracion_expediente: item.listar_archivo_integracion_expediente || [],
			activo: (item.activo === 1),
		});
		this.ListarUsuarioAcreditado().then((response) => {
			this.setState({
				id_acreditado: item.id_acreditado || '',
			});
		});
		this.ListarSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || '',
			});
		});
		this.ListarComprobanteIngreso();
		this.ListarIdentificacion();
		this.open();
	};
	
	/*ListarExpediente = () => {
		ExpedienteService.Listar().then((response) => {
			this.setState({
				listar_expedientes: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_expedientes: []
			});
		});
	}*/
	
	ListarUsuarioAcreditado = () => {
		return new Promise((resolve) => {
			CatService.ListUsuarioAcreditado().then((response) => {
				this.setState({
					listar_usuario_acreditado: response.data
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_usuario_acreditado: []
				});
			});
		});
	}
	
	ListarSexo = () => {
		return new Promise((resolve) => {
			CatService.ListSexo().then((response) => {
				this.setState({
					listar_cat_sexo: response.data
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_cat_sexo: []
				});
			});
		});
	}
	
	ListarComprobanteIngreso = () => {
		CatService.ListComprobanteIngreso().then((response) => {
			this.setState({
				listar_cat_comprobante_ingreso: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_cat_comprobante_ingreso: []
			});
		});
	}
	
	ListarIdentificacion = () => {
		CatService.ListIdentificacion().then((response) => {
			this.setState({
				listar_cat_identificacion: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_cat_identificacion: []
			});
		});
	}
	
	ListarArchivoIntegracionExpediente = () => {
		CatService.ListArchivoIntegracionExpediente().then((response) => {
			this.setState({
				listar_archivo_integracion_expediente: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_archivo_integracion_expediente: []
			});
		});
	}
	
	disabledFieldPromotorIntegracionExpediente = () => {
		
		let disabledFieldPromotorIntegracionExpediente = true;
		
		if (FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario)) {
			disabledFieldPromotorIntegracionExpediente = false;
		}
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	$BroadcastModalSeleccionarArchivoElaboracionPropuesta = (data) => {
		console.log(data);
		if (data.status) {
			switch (data.accion) {
				case "add_archivo_elaboracion_propuesta":
					let arr_temp_add = this.state.listar_archivo_integracion_expediente;
					if (data.id_cat_archivo_integracion_expediente > 0) {
						for (let i = 0; i < arr_temp_add.length; i++) {
							if (arr_temp_add[i].id_cat_archivo_integracion_expediente === data.id_cat_archivo_integracion_expediente) {
								arr_temp_add[i].id_expediente_archivo = data.id_expediente_archivo;
								arr_temp_add[i].id_cat_archivo_integracion_expediente = data.id_cat_archivo_integracion_expediente;
								arr_temp_add[i].titulo = data.titulo;
								arr_temp_add[i].tipo_doc = data.tipo_doc;
								arr_temp_add[i].validado = data.validado;
								arr_temp_add[i].descripcion = data.descripcion;
								arr_temp_add[i].base64Tipo = data.base64Tipo;
								arr_temp_add[i].archivo = data.archivo;
								arr_temp_add[i].formato = data.formato;
								arr_temp_add[i].file = data.file;
							}
						}
					} else {
						arr_temp_add.push({
							id_expediente_archivo: data.id_expediente_archivo,
							id_cat_archivo_integracion_expediente: data.id_cat_archivo_integracion_expediente,
							titulo: data.titulo,
							tipo_doc: data.tipo_doc,
							validado: data.validado,
							descripcion: data.descripcion,
							base64Tipo: data.base64Tipo,
							archivo: data.archivo,
							formato: data.formato,
							file: data.file
						});
					}
					this.setState({
						listar_archivo_integracion_expediente: arr_temp_add
					});
					break;
				case "update_archivo_elaboracion_propuesta":
					let arr_temp_update = this.state.listar_archivo_integracion_expediente;
					for (let i = 0; i < arr_temp_update.length; i++) {
						if (arr_temp_update[i].id_expediente_archivo === data.id_expediente_archivo) {
							arr_temp_update[i].titulo = data.titulo;
							arr_temp_update[i].tipo_doc = data.tipo_doc;
							arr_temp_update[i].validado = data.validado;
							arr_temp_update[i].descripcion = data.descripcion;
							arr_temp_update[i].base64Tipo = data.base64Tipo;
							arr_temp_update[i].archivo = data.archivo;
							arr_temp_update[i].formato = data.formato;
							arr_temp_update[i].file = data.file;
						}
					}
					this.setState({
						listar_archivo_integracion_expediente: arr_temp_update
					});
					break;
				default:
					this.setState({
						listar_archivo_integracion_expediente: []
					});
			}
		}
	};
	
	removefilepromotor = (item, key) => {
		// console.log(item, key);
		var arr_temp = FieldsJs.Copy(this.state.listar_archivo_integracion_expediente);
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Deseas borrar este archivo?').then((r) => {
			if (r.button === 'Aceptar') {
				arr_temp.splice(key, 1);
				this.setState({
					listar_archivo_integracion_expediente: arr_temp
				});
			}
		});
	};
	
	valido = (tipo, valido) => {
		let arr_temp_update = this.state.listar_archivo_integracion_expediente;
		if (this.state.tipo !== 'view') {
			if (Number(tipo) === 1) {
				for (let i = 0; i < arr_temp_update.length; i++) {
					if (arr_temp_update[i].archivo_integracion_expediente === 'CURP') {
						arr_temp_update[i].validado = valido.toString();
					}
				}
					this.setState({
					curp_valido: valido,
					listar_archivo_integracion_expediente: arr_temp_update
				})
			} else {
				for (let i = 0; i < arr_temp_update.length; i++) {
					if (arr_temp_update[i].archivo_integracion_expediente === 'RFC') {
						arr_temp_update[i].validado = valido.toString();
					}
				}
				this.setState({
					rfc_valido: valido,
					listar_archivo_integracion_expediente: arr_temp_update
				})
			}
		}
	}
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const disabledFieldPromotorIntegracionExpediente = this.state.disabledFieldPromotorIntegracionExpediente;
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_expediente}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Expediente Acreditado'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											fullWidth
											clearable
											format={'dd/MM/yyyy'}
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											disabled={this.state.tipo === 'view'}
											label="Fecha de Ingreso"
											value={this.state.fecha_ingreso}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_ingreso');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									required
									name="id_acreditado"
									label="Seleccionar Acreditado"
									value={this.state.id_acreditado}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_usuario_acreditado.map(option => (
										<option key={option.id_acreditado} value={option.id_acreditado}>
											{ Number(option.id_cat_tipo_persona) === 1 ? option.nombre_completo:option.razon_social }
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormLabel component="legend">Identificación Oficial</FormLabel>
								<RadioGroup
									row
									aria-label="position"
									label={"Identificación Oficial"}
									name="id_cat_identificacion"
									value={this.state.id_cat_identificacion}
									onChange={(e) => {
										this.handleChange(e);
									}}>
									{this.state.listar_cat_identificacion.map((value, index) => (
										<FormControlLabel
											key={index}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={value.id_cat_identificacion.toString()}
											label={value.identificacion}
											disabled={this.state.tipo === 'view'}
										/>
									))}
								</RadioGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="curp"
											required
											onChange={this.handleChange}
											label="CURP" autoComplete={'off'}
											value={this.state.curp}
											inputProps={{maxLength: 18}}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								{/*<ModalValidacion
									tipo={'add'}
									item={{curp: this.state.curp, validated: 'curp'}}
									componente={
										<Fab variant="extended" size="small" color="primary" className={'w-100 v-center'} style={{backgroundColor: "#29823c",fontSize: 11,}}>
											<VerifiedUser style={{fontSize: 14, marginRight: 5}}/> Validar CURP
										</Fab>
									}
									RefreshList={this.props.RefreshList}
									showSnackBars={this.props.showSnackBars}
								/>*/}
								
								<FormLabel component="legend" className={'margin-10-B px-13'}>CURP Válido</FormLabel>
								<ButtonGroup variant="outlined" aria-label="outlined button group">
									<Button tabindex="-1" size="small" disabled={this.state.tipo === 'view'} style={{borderColor: 'lightgray', background: Number(this.state.curp_valido) === 1 ? '#37a037' : '', color: Number(this.state.curp_valido) === 1 ? 'white' : 'lightgray'}} onClick={() => this.valido(1, 1)}>SI</Button>
									<Button tabindex="-1" size="small" disabled={this.state.tipo === 'view'} style={{borderColor: 'lightgray', background: Number(this.state.curp_valido) !== 1 ? '#901C30' : '', color: Number(this.state.curp_valido) !== 1 ? 'white' : 'lightgray'}} onClick={() => this.valido(1, 2)}>NO</Button>
								</ButtonGroup>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormLabel component="legend">Comprobante de Ingresos</FormLabel>
								<RadioGroup
									row
									aria-label="position"
									name="id_cat_comprobante_ingreso"
									value={this.state.id_cat_comprobante_ingreso}
									onChange={(e) => {
										this.handleChange(e);
									}}>
									{this.state.listar_cat_comprobante_ingreso.map((value, index) => (
										<FormControlLabel
											key={index}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={value.id_cat_comprobante_ingreso.toString()}
											label={value.comprobante_ingreso}
											disabled={this.state.tipo === 'view'}
										/>
									))}
								</RadioGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="rfc"
											required
											onChange={this.handleChange}
											label="RFC" autoComplete={'off'}
											value={this.state.rfc}
											className={'uppercase'}
											inputProps={{maxLength: 13}}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								{/*<ModalValidacion
									tipo={'add'}
									item={{rfc: this.state.rfc, validated: 'rfc'}}
									componente={
										<Fab variant="extended" size="small" color="primary" className={'w-100 v-center'} style={{backgroundColor: "#3f50b6", fontSize: 11}}>
											<VerifiedUser style={{fontSize: 14, marginRight: 5}}/> Validar RFC
										</Fab>
									}
									RefreshList={this.props.RefreshList}
									showSnackBars={this.props.showSnackBars}
								/>*/}
								
								<FormLabel component="legend" className={'margin-10-B px-13'}>RFC Válido</FormLabel>
								<ButtonGroup variant="outlined" aria-label="outlined button group">
									<Button tabindex="-1" size="small" disabled={this.state.tipo === 'view'} style={{borderColor: 'lightgray', background: Number(this.state.rfc_valido) === 1 ? '#37a037' : '', color: Number(this.state.rfc_valido) === 1 ? 'white' : 'lightgray'}} onClick={() => this.valido(2, 1)}>SI</Button>
									<Button tabindex="-1" size="small" disabled={this.state.tipo === 'view'} style={{borderColor: 'lightgray', background: Number(this.state.rfc_valido) !== 1 ? '#901C30' : '', color: Number(this.state.rfc_valido) !== 1 ? 'white' : 'lightgray'}} onClick={() => this.valido(2, 2)}>NO</Button>
								</ButtonGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Quién es quién</FormLabel>
									<RadioGroup
										row
										aria-label="position"
										label={"Quién es quién"}
										name="quien_es_quien"
										value={this.state.quien_es_quien}
										onChange={(e) => {
											this.handleChange(e);
										}}>
										
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"1"}
											label={"Ok"}
											disabled={this.state.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"2"}
											label={"No apto"}
											disabled={this.state.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											clearable
											required
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											disabled={this.state.tipo === 'view'}
											label="Fecha nacimiento"
											value={this.state.fecha_nacimiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_nacimiento');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Actua por Cta. Propia</FormLabel>
									<RadioGroup
										row
										aria-label="position"
										name="actua_por"
										value={this.state.actua_por}
										onChange={(e) => {
											this.handleChange(e);
										}}>
										
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"1"}
											label={"Si"}
											disabled={this.state.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"2"}
											label={"No"}
											disabled={this.state.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<DeviceHubOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_cat_sexo"
											label="Genero"
											value={this.state.id_cat_sexo}
											required
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_cat_sexo.map(option => (
												<option key={option.id_cat_sexo}
												        value={option.id_cat_sexo}>
													{option.sexo}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="direccion"
											required
											onChange={this.handleChange}
											label="Dirección" autoComplete={'off'}
											value={this.state.direccion}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								
								<div className={'margin-10-B margin-20-B margin-7-T'}>
									Expedientes del Acreditado:
								</div>
								
								<div className={'w-100 h-100'}>
									<div className={'w-100-26-px h-auto'}>
										<div className={'grid-20-65-px'}>
											
											{this.state.listar_archivo_integracion_expediente.map((item, key) =>
												<SeccionExpedientes
													key={key}
													especifico={{
														label: item.archivo_integracion_expediente || null,
														is_required: Number(item.is_required) === 1
													}}
													item={item}
													// tipo={disabledFieldPromotorRequerimientoCliente ? 'view' : (item.archivo_elaboracion_propuesta ? 'add' : 'edit')}
													tipo={this.state.tipo}
													removefilepromotor={this.removefilepromotor}
													index={key}
													showSnackBars={this.props.showSnackBars}
													$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
												/>
											)}
											
											{/*<SeccionExpedientes
												item={{}}
												tipo={'add'}
												especifico={{
													label: null,
													is_required: false
												}}
												removefilepromotor={this.removefilepromotor}
												$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
											/>*/}
										</div>
									</div>
								</div>
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_expediente}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalExpedientes.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalExpedientes;
