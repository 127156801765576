import React, {Component} from 'react';

import Registro from './Registro';

import './Registro.css';

class RegistroIndex extends Component {
	render() {
		return (
			<div className='RegistroIndex'>
				
				<Registro/>
			
			</div>
		);
	}
}

export default RegistroIndex;
