import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import {VentasService} from "../../../../services/_Sis/VentasService/VentasService";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import circulo_a from '../../../../assets/img/icons/circulo-aprobado.svg';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

class ModalEnviarTodoAlmacen extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			id_venta: null,
			item: {},
			venta_producto: []
		};
	}
	
	openModal = () => {
		const {id_venta, item} = this.props;
		let venta_producto = item.venta_producto;
		for (let i = 0; i < venta_producto.length; i++) {
			venta_producto[i].temp_disabled = false;
			venta_producto[i].temp_seleccionado = false;
			venta_producto[i].temp_cantidad = '';
		}
		this.setState({
			modal: {
				open: true
			},
			
			id_venta: id_venta,
			item: item,
			venta_producto: venta_producto
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	handleChange(e) {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === "checkbox" ? checked : value
		});
	}
	
	handleChangeInArray(variable, key, campo, value) {
		let arr_temp = this.state[variable];
		for (let i = 0; i < arr_temp.length; i++) {
			if (key === i) {
				arr_temp[i][campo] = value;
			}
		}
		this.setState({
			[variable]: arr_temp
		});
	}
	
	save = () => {
		try {
			
			let producto_entrega_almacen = [];
			
			for (let i = 0; i < this.state.venta_producto.length; i++) {
				if (this.state.venta_producto[i].temp_cantidad > 0) {
					producto_entrega_almacen.push({
						id_venta_producto: this.state.venta_producto[i].id_venta_producto,
						cantidad: this.state.venta_producto[i].temp_cantidad
					});
				}
			}
			
			VentasService.VentasProductoEntregarAlmacen(this.state.id_venta, producto_entrega_almacen).then((response) => {
				this.closeModal();
				this.props.watchEnviarTodoAlmacen();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		const bg_circulo_a = {
			backgroundImage: `url(${circulo_a})`
		};
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Enviar a almacén
					</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<table style={{width: "100%"}}>
									<thead>
									<tr>
										<th className={'padding-10 padding-0-L'} align={'left'}>Producto</th>
										<th className={'padding-10'} align={'center'}>Cantidad</th>
										<th className={'padding-10'} align={'center'}>Pendiente</th>
										<th className={'padding-10 padding-0-R'} align={'right'}>Entrega</th>
									</tr>
									</thead>
									<tbody>
									{this.state.venta_producto.map((item, key) => (
										<tr key={key}>
											<td className={'padding-10 padding-0-L'} align={'left'}>
												{item.venta_producto}<br/>
												<p className={'px-12 margin-0'}>
													{item.venta_descripcion} {item.requerimientos ? "(" + item.requerimientos + ")" : ""}
												</p>
											</td>
											<td className={'padding-10'} align={'center'}>
												{item.venta_entregado_almacen} / {item.venta_cantidad}
											</td>
											<td className={'padding-10'} align={'center'}>
												<b className={'px-18 margin-0'}>{item.venta_cantidad - item.venta_entregado_almacen}</b>
											</td>
											<td className={'padding-10 padding-0-R'} align={'right'}>
												{item.venta_cantidad - item.venta_entregado_almacen <= 0 ? (
													<div style={bg_circulo_a}
													     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
													/>
												) : (
													<div className="vertical-inline">
														<div className="v-center">
															<input type="text" name={'temp_cantidad'}
															       className={'native margin-10 padding-5 w-40-px text-center'}
															       disabled={!!item.temp_disabled}
															       value={item.temp_cantidad}
															       onChange={(e) => this.handleChangeInArray('venta_producto', key, 'temp_cantidad', e.target.value)}
															/>
														</div>
														<div className="v-center">
															<FormControlLabel
																className={'margin-0-R'}
																label={'Todo'}
																control={
																	<Checkbox type="checkbox" color="primary"
																	          checked={item.temp_seleccionado}
																	          onChange={(e) => {
																		          let checked = e.target.checked;
																		          this.handleChangeInArray('venta_producto', key, 'temp_seleccionado', checked);
																		          if (checked) {
																			          let valor = (item.venta_cantidad - item.venta_entregado_almacen);
																			          this.handleChangeInArray('venta_producto', key, 'temp_cantidad', valor);
																			          this.handleChangeInArray('venta_producto', key, 'temp_disabled', true);
																		          } else {
																			          this.handleChangeInArray('venta_producto', key, 'temp_cantidad', '');
																			          this.handleChangeInArray('venta_producto', key, 'temp_disabled', false);
																		          }
																	          }}
																	/>
																}
															/>
														</div>
													</div>
												)}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							
							</Grid>
						
						</Grid>
					</DialogContent>
					
					<DialogActions>
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						<Button onClick={() => this.save()} color="primary">
							Aceptar
						</Button>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalEnviarTodoAlmacen.propTypes = {
	id_venta: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	watchEnviarTodoAlmacen: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEnviarTodoAlmacen;
