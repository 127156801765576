import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {AcreditadosService} from '../../../../services/_Sis/AcreditadosService/AcreditadosService';
import IconButton from "@material-ui/core/IconButton";
import {SearchOutlined, EditOutlined, TurnedInNotOutlined} from '@material-ui/icons';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalAcreditados extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_acreditado: props.item.id_acreditado || 0,
			tipo: props.tipo,
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange(e) {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	}
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	save = () => {
		if (this.state.id_acreditado > 0) {
			AcreditadosService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			AcreditadosService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_acreditado: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			celular: '',
			correo_electronico: '',
			activo: true,
			tipo_persona: 'fisica',
			nombre_comercial: '',
			razon_social: '',
			id_cat_tipo_persona: '',
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_acreditado: item.id_acreditado || '',
			id_cat_tipo_persona: item.id_cat_tipo_persona || '',
			tipo_persona: item.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			celular: item.celular || '',
			correo_electronico: item.correo_electronico || '',
			activo: (item.activo === 1),
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {id_acreditado, item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_acreditado: item.id_acreditado || '',
			id_cat_tipo_persona: item.id_cat_tipo_persona || '',
			tipo_persona: item.id_cat_tipo_persona === 1 ? 'fisica' : 'moral',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			celular: item.celular || '',
			correo_electronico: item.correo_electronico || '',
			activo: (item.activo === 1),
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_acreditado > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Acreditado
					</DialogTitle>
					
					<DialogContent className={'padding-20 padding-0-T'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<RadioGroup row aria-label="position" name="tipo_persona"
								            value={this.state.tipo_persona}
								            onChange={(e) => {
									            this.handleChange(e);
								            }}
								>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'fisica'}
										label="Física"
										disabled={this.props.tipo === 'view'}
									/>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'moral'}
										label="Moral"
										disabled={this.props.tipo === 'view'}
									/>
								</RadioGroup>
							</Grid>
							
							{this.state.tipo_persona === 'moral' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<TextField
											type={'text'}
											fullWidth
											name="nombre_comercial"
											onChange={this.handleChange}
											label="Nombre comercial" autoComplete={'off'}
											value={this.state.nombre_comercial}
											required
											disabled={this.props.tipo === 'view'}
										/>
									
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											type={'text'}
											fullWidth
											name="razon_social"
											onChange={this.handleChange}
											label="Razón social" autoComplete={'off'}
											value={this.state.razon_social}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								
								</Fragment>
							) : ''}
							
							{this.state.tipo_persona === 'fisica' ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="nombre"
											label="Nombre(s)"
											defaultValue={this.state.nombre}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="apellido_paterno"
											label="Apellido Paterno"
											defaultValue={this.state.apellido_paterno}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="apellido_materno"
											label="Apellido Materno"
											defaultValue={this.state.apellido_materno}
										/>
									</Grid>
								</Fragment>
							) : null}
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									type={'text'}
									fullWidth
									name="celular"
									onChange={this.handleChange}
									label="Celular" autoComplete={'off'}
									value={this.state.celular}
									inputProps={{maxLength: 10}}
									required
									disabled={this.state.tipo === 'view'}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									type={'text'}
									fullWidth
									name="telefono"
									onChange={this.handleChange}
									label="Teléfono" autoComplete={'off'}
									value={this.state.telefono}
									inputProps={{maxLength: 10}}
									disabled={this.state.tipo === 'view'}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									name="correo_electronico"
									label="Correo electrónico"
									defaultValue={this.state.correo_electronico}
								/>
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_usuario > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAcreditados.propTypes = {
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalAcreditados);
