import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {AlternateEmailOutlined, GrainOutlined, HdrStrongOutlined, HdrWeakOutlined, HowToVoteOutlined, ImageAspectRatioOutlined, PhoneIphoneOutlined, PhoneOutlined, PinDropOutlined, StreetviewOutlined, TurnedInNotOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

class ModalCliente extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cliente: (props.item || {}).id_cliente || null,
			ic_cat_tipo_persona: '',
			tipo_persona: 'fisica',
			razon_social: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			telefono: '',
			celular: '',
			rfc: '',
			activo: true,
			
			id_cliente_direccion: null,
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			
			cat_estado: [],
			cat_municipio: [],
			cliente_como_te_enteraste: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			this.ListEstado();
			this.ListComoTeEnteraste();
			if (this.state.id_cat_estado > 0) {
				this.ListMunicipio(null, this.state.id_cat_estado);
			}
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	ListComoTeEnteraste = () => {
		CatService.ListComoTeEnteraste().then((response) => {
			const {item} = this.props;
			let cliente_como_te_enteraste = FieldsJs.Copy(response.data);
			let ids_cliente_como_te_enteraste = FieldsJs.Copy(item.ids_cliente_como_te_enteraste || []);
			for (let i = 0; i < cliente_como_te_enteraste.length; i++) {
				cliente_como_te_enteraste[i].checked = false;
				for (let j = 0; j < ids_cliente_como_te_enteraste.length; j++) {
					if (Number(cliente_como_te_enteraste[i].id_cat_como_te_enteraste) === Number(ids_cliente_como_te_enteraste[j])) {
						cliente_como_te_enteraste[i].checked = true;
					}
				}
			}
			this.setState({
				cliente_como_te_enteraste: cliente_como_te_enteraste
			});
		});
	};
	
	save = () => {
		if (this.state.id_cliente > 0) {
			ClienteService.Modificar(this.state, this.state.cliente_como_te_enteraste).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClienteService.Agregar(this.state, this.state.cliente_como_te_enteraste).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		this.setState({
			id_cliente: (item.item || {}).id_cliente || null,
			ic_cat_tipo_persona: 1,
			tipo_persona: tipo_persona,
			razon_social: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			telefono: '',
			celular: '',
			rfc: '',
			activo: true,
			
			id_cliente_direccion: null,
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			id_cat_estado: '',
			id_cat_municipio: '',
		});
		this.open();
	};
	
	edit = () => {
		console.log(this.props);
		const {item} = this.props;
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		this.setState({
			id_cliente: item.id_cliente || null,
			ic_cat_tipo_persona: (item.ic_cat_tipo_persona === 1 || item.ic_cat_tipo_persona === 2 ? item.ic_cat_tipo_persona : 1),
			tipo_persona: tipo_persona,
			razon_social: item.razon_social || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			celular: item.celular || '',
			rfc: item.rfc || '',
			activo: (item.activo === 1),
			
			id_cliente_direccion: item.id_cliente_direccion || null,
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
		});
		this.open();
	};
	
	view = () => {
		console.log(this.props);
		const {item} = this.props;
		let tipo_persona = 'fisica';
		if (item.id_cat_tipo_persona === 2) {
			tipo_persona = 'moral';
		}
		this.setState({
			id_cliente: item.id_cliente || null,
			ic_cat_tipo_persona: (item.ic_cat_tipo_persona === 1 || item.ic_cat_tipo_persona === 2 ? item.ic_cat_tipo_persona : 1),
			tipo_persona: tipo_persona,
			razon_social: item.razon_social || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			celular: item.celular || '',
			rfc: item.rfc || '',
			activo: (item.activo === 1),
			
			id_cliente_direccion: item.id_cliente_direccion || null,
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
		});
		this.open();
	};
	
	handleChangeComoTeEnteraste = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cliente}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'cliente'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<RadioGroup row aria-label="position" name="tipo_persona"
								            value={this.state.tipo_persona}
								            onChange={(e) => {
									            this.handleChange(e);
								            }}
								>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'fisica'}
										label="Física"
										disabled={this.state.tipo === 'view'}
									/>
									<FormControlLabel
										labelPlacement="end"
										control={<Radio color="primary"/>}
										value={'moral'}
										label="Moral"
										disabled={this.state.tipo === 'view'}
									/>
								</RadioGroup>
							</Grid>
							
							{this.state.tipo_persona === 'moral' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={9} lg={9} xl={9}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="razon_social"
												           onChange={this.handleChange}
												           label="Razón social" autoComplete={'off'}
												           value={this.state.razon_social}
												           required
												           disabled={this.state.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
								
								</Fragment>
							) : ''}
							
							{this.state.tipo_persona === 'fisica' ? (
								<Fragment>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="nombre"
												           onChange={this.handleChange}
												           label="Nombre" autoComplete={'off'}
												           value={this.state.nombre}
												           required
												           disabled={this.state.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="apellido_paterno"
												           onChange={this.handleChange}
												           label="Apellido Paterno" autoComplete={'off'}
												           value={this.state.apellido_paterno}
												           required
												           disabled={this.state.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<TurnedInNotOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="apellido_materno"
												           onChange={this.handleChange}
												           label="Apellido Materno" autoComplete={'off'}
												           value={this.state.apellido_materno}
												           disabled={this.state.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
								
								</Fragment>
							) : ''}
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneIphoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="celular"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Celular" autoComplete={'off'}
										           value={this.state.celular}
										           required
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="rfc"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 13}}
										           label="R.F.C." autoComplete={'off'}
										           value={this.state.rfc}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="calle"
										           onChange={this.handleChange}
										           label="Calle" autoComplete={'off'}
										           value={this.state.calle}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_exterior"
										           onChange={this.handleChange}
										           label="Numero Exterior" autoComplete={'off'}
										           value={this.state.numero_exterior}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_interior"
										           onChange={this.handleChange}
										           label="Numero Interior" autoComplete={'off'}
										           value={this.state.numero_interior}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           label="Código Postal" autoComplete={'off'}
										           value={this.state.codigo_postal}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="colonia"
										           onChange={this.handleChange}
										           label="Colonia" autoComplete={'off'}
										           value={this.state.colonia}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.ListMunicipio(e);
											}}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_estado"
											label="Estado"
											value={this.state.id_cat_estado}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.cat_estado.map(option => (
												<option key={option.id_cat_estado}
												        value={option.id_cat_estado}>
													{option.estado}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_municipio"
											label="Municipio"
											value={this.state.id_cat_municipio}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.cat_municipio.map(option => (
												<option key={option.id_cat_municipio}
												        value={option.id_cat_municipio}>
													{option.municipio}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item>
										<FormGroup row className={'margin-3-L'}>
											<FormControlLabel
												control={
													<Checkbox
														type="checkbox" name='activo'
														checked={this.state.activo}
														onChange={this.handleChange} value="activo"
														color="primary"
														disabled={this.state.tipo === 'view'}/>
												}
												label={'Activo'}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Typography className={'text-left text-black px-14'}>
									¿Como te enteraste?
								</Typography>
								
								<div align={'left'}>
									{this.state.cliente_como_te_enteraste.map((item, key) => (
										<Fragment key={key}>
											<FormControlLabel
												className={'h-30-px'}
												control={
													<Checkbox
														type="checkbox" color="primary"
														name={'como_te_enteraste__' + key}
														onChange={(e) => this.handleChangeComoTeEnteraste(e, 'cliente_como_te_enteraste', 'checked')}
														checked={!!item.checked}
														disabled={this.state.tipo === 'view'}
													/>
												}
												label={item.como_te_enteraste}
												labelPlacement="end"
											/>
										</Fragment>
									))}
								</div>
							
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cliente}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCliente.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCliente;
