import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {EnteroSolo} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";


class FormMercadoPago extends Component {
	
	render() {
		
		return (
			<Fragment>
				<div className={'borde-punteado-gray-1 padding-15'}>
					<Grid container spacing={1}>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Número de comprobante de pago
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="folio"
									           value={this.props.form.folio}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 100,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Últimos 4 dígitos de la tarjeta
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="cuenta_origen"
									           value={this.props.form.cuenta_origen}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 4,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>*/}
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Monto pagado
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="monto"
									           value={this.props.form.monto}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<div className={'margin-10-B margin-10-T'}>
								<BoxSelectFile
									FormatAccepted={["image/png", "image/jpeg", "application/pdf"]}
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Comprobante de pago</b>
									)}
									style={{
										color: 'black',
										height: '115px',
									}}
									item={{
										base64: this.props.form.imagen_base64,
										base64Tipo: this.props.form.imagen_base64Tipo,
										archivo: this.props.form.imagen_archivo,
										formato: this.props.form.imagen_formato,
									}}
									onChange={(r) => {
										this.props.handleChangePago({
											target: {
												name: 'imagen_base64',
												value: r.base64
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_archivo',
												value: r.archivo
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>
							</div>
						</Grid>
						
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Button variant="contained" color="primary" onClick={this.props.Pagar}
							        className={'btn-default-primary'}>
								Guardar pago
							</Button>
						</Grid>
					
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

FormMercadoPago.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
};

export default FormMercadoPago;
