import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './Home.css';
import logo_svg from '../../../assets/img/logo.png';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import EstiloBotonAccesoDirectoInicio from "../../Include/MiniComponents/EstiloBotonAccesoDirectoInicio";
import acceso_lista_venta from '../../../assets/img/icons/acceso-lista-venta.svg'
import acceso_nueva_venta from '../../../assets/img/icons/acceso-nueva-venta.svg'
import Grid from "@material-ui/core/Grid";

import $State from "../../../settings/$State/$State";
import HomeResponsive from "./Includes/responsive";

// import Button from "@material-ui/core/Button";

class Home extends Component {
	
	state = {}
	
	constructor(props) {
		super(props);
		this.state = {
			settings: {}
		}
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	lista_venta = () => {
		$State.go(this.props, "ventas", {});
	};
	
	nueva_venta = () => {
		$State.go(this.props, "ordendeventa", {});
	};
	
	updateDimensions = () => {
		HomeResponsive.get().then(settings => {
			this.setState({
				settings: settings
			});
		});
	};
	
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', () => {
			this.updateDimensions();
		});
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', () => {
			this.updateDimensions();
		});
	}
	
	render() {
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center 35%',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
		};
		
		let settings = this.state.settings;
		
		return (
			<div className='Home ContaineViewComponet bg-logo-svg' style={bg_logo_svg}>
				<div className={'w-auto content-image vertical-inline'} style={{backgroundColor: "rgba(248, 248, 255, 0.0)"}}>
					<div className={'w-100 v-center top-actions'}>

						<Grid container spacing={settings.spacing || 10} direction={"row"} alignItems={"center"} alignContent={"center"}>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#e91e63'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>NUEVO PEDIDO</h4>
									)}
									iconRight={acceso_nueva_venta}
									onClick={() => this.nueva_venta()}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#e91e63'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>SEGUIR PEDIDO</h4>
									)}
									iconLeft={acceso_lista_venta}
									onClick={() => this.lista_venta()}
								/>
							</Grid>
						
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

Home.propTypes = {};

export default IntegrationNotistack(Home);
