import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import {VentasService} from '../../../../services/_Sis/VentasService/VentasService';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {EditOutlined, HdrStrong, SearchOutlined, LocalAtm, LocalShipping} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import Typography from "@material-ui/core/Typography";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import icon_ticket from "../../../../assets/img/icons/icon-ticket.svg";
import {Checkbox} from "@material-ui/core";
import OnContextMenu from "../../../Include/MiniComponents/OnContextMenu";


class ModalValidarPago extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_venta: (props.item || '').id_venta,
			pagos: (props.item || []).pagos,
			tipo: props.tipo,
			id_cat_venta_status_proceso: '',
			lista_cat_venta_status_proceso: [],
		};
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListCatCronogramaEstatus = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCatVentaProceso().then((response) => {
				this.setState({
					lista_cat_venta_status_proceso: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_venta_status_proceso: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	
	save = () => {
		try {
			VentasService.Validar(this.state).then((response) => {
				this.props.RefreshList();
				this.modalClose();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_venta: item.id_venta || '',
			id_cat_venta_status_proceso: '',
			lista_cat_venta_status_proceso: [],
			activo: true,
		});
		
		this.ListCatCronogramaEstatus();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_venta: item.id_venta || '',
			id_cat_venta_status_proceso: item.id_cat_venta_status_proceso.toString() || null,
			pagos: item.pagos || [],
			lista_cat_venta_status_proceso: item.lista_cat_venta_status_proceso || [],
			activo: (item.activo === 1),
		});
		
		let band_nuevo_pago = false;
		for (let i = 0; i < item.pagos.length; i++) {
			if (Number(item.pagos[i].validado) === 0) {
				band_nuevo_pago = true;
			}
		}
		if (band_nuevo_pago) {
			this.openModal('paper');
		} else {
			this.showSnackBars('error', "No existe un pago nuevo para validar.");
		}
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListCatCronogramaEstatus().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			
		});
		
		this.setState({
			id_venta: item.id_venta || '',
			id_cat_venta_status_proceso: item.id_cat_venta_status_proceso || null,
			lista_cat_venta_status_proceso: item.lista_cat_venta_status_proceso || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_venta > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Validar Pago">
						<IconButton aria-label="Validar Pago" onClick={() => this.edit()}>
							<LocalAtm/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<LocalAtm/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={'text-left'}>
								{'Validar Pago'}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={'text-right'}>
								No. Pedido: <span style={{color: 'blue'}}>
								<b className={'vertical-inline v-center cursor-pointer ff-arial '}
								   style={{
									   background: "#e4e4e4",
									   padding: '1px 5px',
									   borderRadius: '4px',
									   color: "black"
								   }}>
										{this.props.item.numero_pedido}
									</b>
							</span>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
								{/*<span className={'text-left text-black px-13'}>Estatus de la actividad:</span>*/}
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<div className={'padding-10'}>
										<table className={'w-100'}>
											<thead>
											<tr>
												<th className={'text-center px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Comprobante
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Forma de pago
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Fecha de pago
												</th>
												<th className={'text-right px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Monto
												</th>
												<th className={'text-right px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Validación
												</th>
											</tr>
											</thead>
											<tbody>
											{this.state.pagos.map((item, key) => (
												<tr key={key}>
													<td align={'center'}>
														<ModalImage
															tipo={'view'}
															desing={1}
															component={<div
																className={'bg-img-contain-x-center-y-center h-25-px w-25-px cursor-pointer'} style={bg_icon_ticket}/>}
															item={{
																base64: item.base64 || '',
																base64Tipo: item.base64Tipo || '',
																archivo: item.archivo ? item.archivo : '',
																formato: item.formato || '',
															}}
														/>
														{/*<div
															className={'bg-img-contain-x-center-y-center h-25-px w-25-px cursor-pointer'}
															style={bg_icon_ticket}
															onClick={() => this.ticket(item)}/>*/}
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{item.forma_pago}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{DateFormat.FormatText(item.created_at, true)}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-right'}>
															${FieldsJs.Currency(item.monto)}
														</Typography>
													</td>
													<td>
														{item.validado ? (
															<FormControlLabel
																className={'px-12'}
																control={
																	<Checkbox
																		checked={item.validado}
																		onChange={(e) => {
																			this.handleChange(e, 'pagos', 'validado');
																		}}
																		name={"validado__" + key}
																		color="primary"
																		disabled={item.validado}
																	/>
																}
																labelPlacement="start"
																label={"Validado"}
															/>
														) : (
															<FormControlLabel
																className={'px-12'}
																control={
																	<Checkbox
																		checked={item.validacion}
																		onChange={(e) => {
																			this.handleChange(e, 'pagos', 'validacion');
																		}}
																		name={"validado__" + key}
																		color="primary"
																		disabled={item.validado}
																	/>
																}
																labelPlacement="start"
																label={item.validado ? "Validado" : "Por validar"}
															/>
														)}
													
													</td>
												</tr>
											))}
											<tr>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														TOTAL PAGADO:
													</Typography>
												</td>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.props.pagos.ws_total_pagado)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														SALDO:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.props.pagos.ws_por_pagar)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														TOTAL VENTA:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency((this.props.pagos.ws_total_venta - this.props.pagos.ws_descuento_venta))}
													</Typography>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
									<Grid container spacing={0}>
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={'text-left'}>
											<Button onClick={() => this.modalClose()} color="primary">
												{'Cancelar'}
											</Button>
										</Grid>
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={'text-right'}>
											{this.state.tipo !== 'view' ? (
												<Button onClick={() => this.save()} color="primary">
													{'Validar'}
												</Button>
											) : ''}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					{/*<DialogActions>
					
					
					
					
					
					</DialogActions>*/}
				
				</Dialog>
			
			</div>
		);
	}
}

ModalValidarPago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	pagos: PropTypes.array.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalValidarPago);
