import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {EnteroSolo} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AccountBalanceWallet, CompareArrows} from '@material-ui/icons';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";

class FormTransferenciaBancaria extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			value: 0
		};
	};
	
	handleChangeTab = (e, tab) => {
		this.setState({
			value: tab
		});
	};
	
	handleChangeDropzoneArea = (file) => {
		this.setState({files:file}, () => {
			if (this.state.files.length > 0) {
				this.FileBase64(this.state.files[0]);
			}
		})
	};
	
	FileBase64 = (e, id) => {
		
		let fil = {files: [e]};
		console.log("FILEEE:::::",e);
		let formatos = [
			"image/jpeg",
			"image/png"
		];
		FileBase64.Base64(fil, formatos).then((response) => {
			this.props.handleChangePago({
				target: {
					name: 'imagen_base64',
					value: response.base64
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_base64Tipo',
					value: response.base64Tipo
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_archivo',
					value: response.archivo
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_formato',
					value: response.formato
				}
			});
		}).catch((error) => {
			console.log("ERROR:::::",error);
			this.props.showSnackBars('error', error.mensaje);
			this.setState({
				imagen_base64: "",
				imagen_base64Tipo: "",
				imagen_archivo: "",
				imagen_formato: "",
			});
		});
	};
	
	render() {
		
		
		return (
			<Fragment>
				
				<AppBar position="static">
					<Tabs
						className={'bg-white text-black'}
						value={this.state.value}
						onChange={this.handleChangeTab}
						variant="fullWidth"
						aria-label="Transferencia / Depósito bancario"
					>
						<Tab icon={<CompareArrows/>} label="Transferencia"/>
						<Tab icon={<AccountBalanceWallet/>} label="Depósito bancario"/>
					</Tabs>
				</AppBar>
				
				<TabPanel value={this.state.value} index={0}>
					
					<div className={'borde-punteado-gray-1 padding-15'}>
						<Grid container spacing={1}>
							
							<Grid item className={'text-left'} xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Clave de rastreo
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="clave_rastreo"
										           value={this.props.form.clave_rastreo}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Numero de referencia
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="numero_referencia"
										           value={this.props.form.numero_referencia}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Monto pagado
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="monto"
										           value={this.props.form.monto}
										           onKeyPress={EnteroSolo}
										           inputProps={{
											           maxLength: 15,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'margin-10-B margin-10-T'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={true}
										filesLimit={1}
										dropzoneText={'Arrastre y suelte una imagen del pago aquí o haga clic'}
										acceptedFiles={["image/png", "image/jpeg","image/*"]}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
									
									{/*<BoxSelectFile
										FormatAccepted={["image/png", "image/jpeg", "application/pdf"]}
										MegaByte={5}
										// button={(
										// 	<AddOutlined/>
										// )}
										label={(
											<b className={'vertical-inline v-center text-blue'}>Comprobante de pago</b>
										)}
										style={{
											color: 'black',
											height: '115px',
										}}
										item={{
											base64: this.props.form.imagen_base64,
											base64Tipo: this.props.form.imagen_base64Tipo,
											archivo: this.props.form.imagen_archivo,
											formato: this.props.form.imagen_formato,
										}}
										onChange={(r) => {
											this.props.handleChangePago({
												target: {
													name: 'imagen_base64',
													value: r.base64
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_base64Tipo',
													value: r.base64Tipo
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_archivo',
													value: r.archivo
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_formato',
													value: r.formato
												}
											});
										}}
										showSnackBars={this.props.showSnackBars}
									/>*/}
								</div>
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button variant="contained" color="primary"
								        onClick={() => this.props.Pagar(this.state.value)}
								        className={'btn-default-primary'}>
									Guardar pago
								</Button>
							</Grid>
						
						</Grid>
					</div>
				
				</TabPanel>
				
				<TabPanel value={this.state.value} index={1}>
					
					<div className={'borde-punteado-gray-1 padding-15'}>
						<Grid container spacing={1}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											No. folio, Recibo, Voucher
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="folio"
										           value={this.props.form.folio}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={'px-14 text-center'}>
												Monto pagado
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
											           onChange={this.props.handleChangePago}
											           name="monto"
											           value={this.props.form.monto}
											           onKeyPress={EnteroSolo}
											           inputProps={{
												           maxLength: 15,
												           style: {
													           paddingTop: "8px"
												           }
											           }}
											/>
										</Grid>
									</Grid>
								
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'margin-10-B margin-10-T'}>
									<BoxSelectFile
										FormatAccepted={["image/png", "image/jpeg"]}
										MegaByte={5}
										// button={(
										// 	<AddOutlined/>
										// )}
										label={(
											<b className={'vertical-inline v-center text-blue'}>Comprobante de pago</b>
										)}
										style={{
											color: 'black',
											height: '115px',
										}}
										item={{
											base64: this.props.form.imagen_base64,
											base64Tipo: this.props.form.imagen_base64Tipo,
											archivo: this.props.form.imagen_archivo,
											formato: this.props.form.imagen_formato,
										}}
										onChange={(r) => {
											this.props.handleChangePago({
												target: {
													name: 'imagen_base64',
													value: r.base64
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_base64Tipo',
													value: r.base64Tipo
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_archivo',
													value: r.archivo
												}
											});
											this.props.handleChangePago({
												target: {
													name: 'imagen_formato',
													value: r.formato
												}
											});
										}}
										showSnackBars={this.props.showSnackBars}
									/>
								</div>
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button variant="contained" color="primary"
								        onClick={() => this.props.Pagar(this.state.value)}
								        className={'btn-default-primary'}>
									Guardar pago
								</Button>
							</Grid>
						
						</Grid>
					</div>
				
				</TabPanel>
			
			</Fragment>
		);
	}
}

FormTransferenciaBancaria.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
};

export default FormTransferenciaBancaria;


function TabPanel(props) {
	const {children, value, index, ...other} = props;
	
	return (
		<Typography
			className={'padding-0 margin-0'}
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			<Box p={3} className={'padding-0 margin-0'}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
