import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

import {FieldsJs} from '../../../../settings/General/General';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {
	AccountBalanceOutlined,
	AttachMoneyOutlined,
	CallMadeOutlined,
	CancelOutlined,
	CheckCircleOutlineOutlined,
	CheckOutlined,
	EditOutlined,
	ForwardOutlined,
	HowToRegOutlined,
	ImageAspectRatioOutlined,
	LocalShipping,
	MailOutline,
	PictureAsPdfOutlined,
	PostAdd,
	SearchOutlined,
	WatchLaterOutlined,
	Web
} from "@material-ui/icons";

import $State from "../../../../settings/$State/$State";
import {withRouter} from "react-router-dom";

import icon_mas from '../../../../assets/img/icons/expandir-mas.svg';
import icon_menos from '../../../../assets/img/icons/expandir-menos.svg';
import icon_p from '../../../../assets/img/icons/icon-pre-cotizacion.svg';
import icon_i from '../../../../assets/img/icons/icon-interno.svg';
import icon_f from '../../../../assets/img/icons/icon-factura.svg';
import icon_c from '../../../../assets/img/icons/icon-cotizacion.svg';
import icon_n from '../../../../assets/img/icons/icon-nota-sencilla.svg';
import circulo_a from '../../../../assets/img/icons/circulo-aprobado.svg';
import circulo_n from '../../../../assets/img/icons/circulo-negado.svg';
import Typography from "@material-ui/core/Typography";
import icon_ticket from "../../../../assets/img/icons/icon-ticket.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

import ModalSeleccionarCliente from "./ModalSeleccionarCliente";
import ModalEnviarAlmacen from "./ModalEnviarAlmacen";
import ModalHorasTrabajadas from "./ModalHorasTrabajadas";
import Grid from "@material-ui/core/Grid";
import ModalEnviarCliente from "./ModalEnviarCliente";
import ModalEnviarTodoAlmacen from "./ModalEnviarTodoAlmacen";
import ModalEnviarTodoCliente from "./ModalEnviarTodoCliente";
import OnContextMenu from "../../../Include/MiniComponents/OnContextMenu";
import ModalAccionPDFVentas from "./ModalAccionPdfVentas";
import ModalTipoTicket from "./ModalTipoTicket";
import ModalEstatus from "./ModalEstatus";
import ModalValidarPago from "./ModalValidarPago";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
		}
	}
	
	openModal = () => {
		this.setState({open: true});
	}
	/*openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	*/
	closeModal = () => {
		this.setState({
			open: false
		});
	};
	
	detalles = (item) => {
		$State.go(this.props, 'ordendeventa', {id_venta: item.id_venta})
	};
	
	editar = (item) => {
		$State.go(this.props, 'ordendeventa', {editar: 'editar', id_venta: item.id_venta})
	};
	
	cancelar = (item) => {
		this.props.cancelar(item);
	};
	
	activar = (item) => {
		this.props.activar(item);
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	ticket_de_entrega = (value) => {
		HttpRequest.export('texid', ['ticket'], {
			id_venta_producto: value.id_venta_producto
		}, true, 450, 680)
	};
	
	is_cotizacion = (item) => {
		return item.cotizacion === 1 && item.factura === 0 && item.nota_sencilla === 0 && item.interno === 0;
	};
	
	is_cancel = (item) => {
		return item.activo === 0;
	};
	
	is_edited = (item) => {
		return FieldsJs.inArray([1, 2], Number(item.id_cat_venta_status_proceso)) || (item.edicion === "Aprobado" || item.edicion === "Forzar edicion");
	};
	
	crear_array_list = (item) => {
		
		let item_list = [];
		
		if (item.factura === 1) {
			item_list.push({
				id: 1,
				name: 'desactivar_factura',
				label: 'Desactivar el cliente requiere una factura'
			});
		}
		
		if (item.nota_sencilla === 1) {
			item_list.push({
				id: 2,
				name: 'desactivar_nota_sencilla',
				label: 'Desactivar nota sencilla'
			});
		}
		
		if (item.interno === 1) {
			item_list.push({
				id: 3,
				name: 'desactivar_interno',
				label: 'Desactivar orden de tipo interno'
			});
		}
		
		if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Sin accion") {
			item_list.push({
				id: 4,
				name: 'solicitar_edicion',
				label: 'Solicitar edición'
			});
		}
		
		if (item.edicion === "Solicitar") {
			item_list.push({
				id: 6,
				name: 'cancelar_solicitud_de_edicion',
				label: 'Cancelar solicitud de edición'
			});
		}
		
		if (FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
			
			if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Solicitar") {
				item_list.push({
					id: 5,
					name: 'aprobar_edicion',
					label: 'Aprobar edición'
				});
			}
			
			if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
				item_list.push({
					id: 7,
					name: 'cancelar_edicion',
					label: 'Cancelar edición'
				});
			}
			
		}
		
		if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
			item_list.push({
				id: 7,
				name: 'finalizar_edicion',
				label: 'Finalizar edición'
			});
		}
		
		return item_list;
	};
	
	status_lista_click_derecho = (item) => {
		let status = [];
		if (item.factura === 1) {
			status.push(1)
		}
		if (item.nota_sencilla === 1) {
			status.push(2)
		}
		if (item.interno === 1) {
			status.push(3)
		}
		return status.length > 1;
	};
	
	obtenerTotal = (item) => {
		let productos = FieldsJs.Copy(item.venta_producto);
		let total = 0;
		
		if (productos.length > 0) {
			for (let i = 0; i < productos.length; i++) {
				total = total + (productos[i].venta_cantidad * productos[i].venta_precio_unitario);
			}
		}
		
		return total;
	}
	
	obtenerTotalBolsas = (bolsas) => {
		let total = 0;
		for (let i = 0; i < bolsas.length; i++) {
			total += bolsas[i].cantidad_bolsa * bolsas[i].costo;
		}
		
		return total;
	}
	
	render() {
		
		const textoClaseIcono = "bg-img-contain-x-center-y-center h-15-px w-15-px cursor-pointer margin-2-R margin-2-L";
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		const bg_p = {
			backgroundImage: `url(${icon_p})`
		};
		
		const bg_i = {
			backgroundImage: `url(${icon_i})`
		};
		
		const bg_f = {
			backgroundImage: `url(${icon_f})`
		};
		
		const bg_c = {
			backgroundImage: `url(${icon_c})`
		};
		
		const bg_n = {
			backgroundImage: `url(${icon_n})`
		};
		
		const bg_circulo_a = {
			backgroundImage: `url(${circulo_a})`
		};
		
		const bg_circulo_n = {
			backgroundImage: `url(${circulo_n})`
		};
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		return (<Paper className={classes.root}>
			<Table className={classes.table + ' desing-acresco'}>
				<TableHead>
					<TableRow>
						<TableCell component="th" align={'center'} className={'w-20-px padding-20-L padding-20-R'}/>
						{/*<TableCell component="th" align={'left'} className={'padding-10-L padding-10-R'}>
							Folio
						</TableCell>*/}
						<TableCell component="th" align={'left'} className={'w-30-px padding-10-L padding-10-R'}>
							{this.props.tipo_vista === 'cotizacion' ? "No. cotización" : "No. Pedido"}
						</TableCell>
						{/*{this.ver_root() ? (
							<TableCell component="th" align={'left'}>Usuario</TableCell>
						) : null}*/}
						<TableCell component="th" align={'left'}>Cliente</TableCell>
						<TableCell component="th" align={'center'}>Fecha alta</TableCell>
						{/*<TableCell component="th" align={'center'}>Fecha entrega</TableCell>*/}
						<TableCell component="th" align={'center'}>Progreso</TableCell>
						<TableCell component="th" align={'right'}>Pagado</TableCell>
						<TableCell component="th" align={'right'}>Por pagar</TableCell>
						<TableCell component="th" align={'right'}>Total</TableCell>
						<TableCell component="th" align={'center'}>Estatus pago</TableCell>
						<TableCell component="th" align={'center'}>Estatus pedido</TableCell>
						{/*<TableCell component="th" align={'center'}>Dirección de entrega</TableCell>*/}
						<TableCell component="th" align={'center'}>Código rastreo</TableCell>
						<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lista.map((item, key) => (
						<Fragment key={key}>
							<TableRow key={key}>
								
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									<div style={!!item.expandir ? bg_menos : bg_mas}
									     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
									     onClick={() => this.props.SubTabla(item, key)}
									/>
								</TableCell>
								
								{/*<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
									<Typography style={{fontWeight: 'bold', textDecoration: 'underline wavy red'}}>
										{item.id_venta}
									</Typography>
								</TableCell>*/}
								
								<TableCell align={'left'} className={'padding-10-L padding-10-R'}>
									
									<div className={'vertical-inline v-center'}>
										
										<OnContextMenu
											id={"factura_" + key}
											componente={
												<b className={'vertical-inline v-center cursor-pointer ff-arial ' + (this.is_cancel(item) ? 'text-tachado' : '')}
												   style={{
													   background: this.status_lista_click_derecho(item) ? "#da7300" : "#e4e4e4",
													   padding: '1px 5px',
													   borderRadius: '4px',
													   color: this.status_lista_click_derecho(item) ? "white" : "black"
												   }}>
													{this.props.tipo_vista === 'cotizacion' ? item.numero_cotizacion : item.numero_pedido}
												</b>
											}
											itemsPopover={this.crear_array_list(item)}
											onClick={(option) => {
												this.props.DesactivarFacturaNotaSencillaInterno(option, item);
											}}
										/>
										
										<div className={'w-20-px h-15-px text-center'}>
											{item.venta_envio && Number(item.venta_envio.id_cat_envio_estatus) > 0 ? (
												<Tooltip TransitionComponent={Zoom} placement={"left"} title={item.venta_envio.envio_estatus}>
													<LocalShipping style={{
														borderRadius: 10,
														padding: 2,
														fontSize: 12,
														color: 'white',
														backgroundColor: Number(item.venta_envio.id_cat_envio_estatus) === 1 ? '#b9996f' : Number(item.venta_envio.id_cat_envio_estatus) === 2 ? '#2884a5' : '#3fb459'
													}}/>
												</Tooltip>
											) : ''}
										</div>
										
										{/*{item.pre_cotizacion === 1 ? (
											<div style={bg_p} className={textoClaseIcono}/>
										) : ''}*/}
										{item.pre_cotizacion === 1 ? (
											<Tooltip TransitionComponent={Zoom} placement={"left"}
											         title={'Pre-Cotización: ' + item.numero_pre_cotizacion}>
												<div style={bg_p} className={textoClaseIcono}/>
											</Tooltip>
										) : ''}
										{item.cotizacion === 1 ? (
											<Tooltip TransitionComponent={Zoom} placement={"left"}
											         title={'Cotización: ' + item.numero_cotizacion}>
												<div style={bg_c} className={textoClaseIcono}/>
											</Tooltip>
										) : ''}
										{/*{item.interno === 1 ? (
											<div style={bg_i} className={textoClaseIcono}/>
										) : ''}
										{item.nota_sencilla === 1 ? (
											<div style={bg_n} className={textoClaseIcono}/>
										) : ''}
										{item.factura === 1 ? (
											<div style={bg_f} className={textoClaseIcono}/>
										) : ''}*/}
									
									</div>
									
									{/*<div className={'vertical-inline v-center'}>
										{item.edicion === "Solicitar" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-warning"}>
												Edición solicitado
											</div>
										) : (item.edicion === "Aprobado" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-success"}>
												Edición aprobado
											</div>
										) : (item.edicion === "Forzar edicion" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-info"}>
												Edición forzada aprobado
											</div>
										) : ""))}
									</div>*/}
								
								</TableCell>
								
								
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{item.usuario_nombre_completo}
								</TableCell>
								
								{/*<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{item.cliente_nombre_completo}
								</TableCell>*/}
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{DateFormat.FormatText(item.fecha_alta)}
								</TableCell>
								
								{/*<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{DateFormat.FormatText(item.fecha_entrega)}
								</TableCell>*/}
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<div className={'w-100 h-20-px bg-lightgray b-r-3'}>
										<div className={'h-20-px bg-green b-r-3'}
										     style={{width: (item.progreso || 0) + "%"}}/>
									</div>
									{item.progreso || 0}%
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										${FieldsJs.Currency(item.pagado)}
									</p>
								</TableCell>
								
								<TableCell align={'right'}
								           className={(item.porpagar > 0 ? 'px-20 text-danger' : '') + ' ' + (this.is_cancel(item) ? 'text-tachado' : '')}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										${FieldsJs.Currency(item.porpagar)}
									</p>
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											<b>${FieldsJs.Currency((item.total))}</b>
										</p>
									</Fragment>
									{/*{item.descuento > 0 ? (
										<Fragment>
											<p className={'w-100 padding-0 margin-0 px-10 ff-arial'}>
												${FieldsJs.Currency((item.total))}
											</p>
											<p className={'w-100 padding-0 margin-0 px-10 ff-arial'}>
												Des. - ${FieldsJs.Currency((item.descuento))}
											</p>
											<hr className={'w-100 padding-0 margin-0'}/>
											<p className={'w-100 padding-0 margin-0 ff-arial'}>
												<b>${FieldsJs.Currency((item.total - item.descuento))}</b>
											</p>
										</Fragment>
									) : (
										<Fragment>
											<p className={'w-100 padding-0 margin-0 ff-arial'}>
												<b>${FieldsJs.Currency((item.total))}</b>
											</p>
										</Fragment>
									)}*/}
								
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											{item.estatus_pago}
										</p>
									</Fragment>
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											{item.estatus_proceso}
										</p>
									</Fragment>
								</TableCell>
								
								{/*<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											{item.direccion}
										</p>
									</Fragment>
								</TableCell>*/}
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											{item.tipo_mensajeria}
										</p>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											{item.numero_rastreo ? item.numero_rastreo:'-'}
										</p>
									</Fragment>
								</TableCell>
								
								<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
									
									<div className={'vertical-inline'}>
										{FieldsJs.inArray([1,2], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<ModalEstatus
													tipo={'edit'}
													item={item}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											):null
										}
										
										{FieldsJs.inArray([1,2], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<ModalValidarPago
													tipo={'edit'}
													item={item}
													pagos={item.pagos}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											):null
										}
										
										{!this.is_cancel(item) ? (
											<Fragment>
												
												{/*{(
													<div className={'v-center'} onClick={this.openModal}>
														
														<ModalAccionPDFVentas
															open={this.state.open}
															componente={
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar nota de venta">
																	<IconButton aria-label="Enviar nota de venta">
																		<MailOutline/>
																	</IconButton>
																</Tooltip>
															}
															closeModal={this.closeModal}
															showSnackBars={this.props.showSnackBars}
															id_venta={item.id_venta}
															cliente={item}
														/>
													</div>
												)}*/}
												
												{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalSeleccionarCliente
															item={item} id_venta={item.id_venta}
															icono={(
																<Tooltip TransitionComponent={Zoom}
																         placement={"top"}
																         title="Seleccionar o cambiar cliente">
																	<IconButton
																		aria-label="Seleccionar o cambiar cliente">
																		<HowToRegOutlined color={'primary'}/>
																	</IconButton>
																</Tooltip>
															)}
															watchAsignarCliente={this.props.watchAsignarCliente}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : ''}*/}
												
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title={this.is_cotizacion(item) ? "Ver cotización" : "Ver orden de venta"}>
															<IconButton
																aria-label={this.is_cotizacion(item) ? "Ver cotización" : "Ver orden de venta"}
																onClick={() => this.props.exportar(item)}>
																<PictureAsPdfOutlined/>
															</IconButton>
														</Tooltip>
													</div>
										
												
												{!this.is_cotizacion(item) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title={item.porpagar > 0 ? (item.pagado > 0 ? 'Ver ticket o realizar pagos' : 'Realizar pagos') : 'Ver ticket o desglose de pagos'}>
															<IconButton
																aria-label={item.porpagar > 0 ? (item.pagado > 0 ? 'Ver ticket o realizar pagos' : 'Realizar pagos') : 'Ver ticket o desglose de pagos'}
																onClick={() => this.props.OrdenDeVentaContinuar(item)}>
																<AttachMoneyOutlined
																	className={item.porpagar > 0 ? 'text-danger' : ''}/>
															</IconButton>
														</Tooltip>
													</div>
												) : ""}
												
												{/*<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"}
													         title="Detalles pedido">
														<IconButton aria-label="Detalles pedido"
														            onClick={() => this.detalles(item)}>
															<SearchOutlined/>
														</IconButton>
													</Tooltip>
												</div>*/}
												
												{/*{this.is_edited(item) ? (*/}
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title={"Editar Pedido"}>
															<IconButton aria-label={"Editar Pedido"} onClick={() => this.editar(item)}>
																<EditOutlined style={{color: ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion") ? "blue" : "rgba(0, 0, 0, 0.54)")}}/>
															</IconButton>
														</Tooltip>
													</div>
												{/*) : ''}*/}
											
											</Fragment>
										) : ''}
										
										{!this.is_cancel(item) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"}
												         title="Cancelar pedido">
													<div>
														<IconButton aria-label="Cancelar pedido"
														            onClick={() => this.cancelar(item)}
														            disabled={!FieldsJs.inArray([1], item.id_cat_venta_status_proceso) && !FieldsJs.inArray([1, 2], this.Usr.id_usuario)}
														>
															<CancelOutlined
																className={(!FieldsJs.inArray([1], item.id_cat_venta_status_proceso) && !FieldsJs.inArray([1, 2], this.Usr.id_usuario) ? 'text-disabled' : 'text-danger')}/>
														</IconButton>
													</div>
												</Tooltip>
											</div>
										) : (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"}
												         title="Reactivar pedido">
													<div>
														<IconButton aria-label="Reactivar pedido"
														            onClick={() => this.activar(item)}
														            disabled={!FieldsJs.inArray([1], item.id_cat_venta_status_proceso)}
														>
															<CheckCircleOutlineOutlined className={'text-info'}/>
														</IconButton>
													</div>
												</Tooltip>
											</div>
										)}
									
									</div>
								
								</TableCell>
							</TableRow>
							{!!item.expandir ? (
								<TableRow>
									<TableCell
										align={'center'}
										className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
										colSpan={14}
									>
										
										<div className={'bg-white card-2'}>
											
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
												</Grid>
												<Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
													<Typography className={'px-14 text-center bg-white padding-10'}>
														PRODUCTOS
													</Typography>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													
													<table width={'100%'} className={'bg-white'} style={{width: '100% !important', borderSpacing: "0px"}}>
														<thead>
														<tr>
															<th className={'text-left padding-20-L'}>Categoría</th>
															{/*<th className={'text-left'}>Código</th>*/}
															<th className={'text-left'}>Partida</th>
															<th className={'text-left'}>Modelo</th>
															<th className={'text-left'}>Descripción</th>
															<th className={'text-center'}>Color</th>
															<th className={'text-center'}>Talla</th>
															<th className={'text-center'}>Cantidad</th>
															<th className={'text-right padding-20-R'}>Precio</th>
															<th className={'text-right padding-20-R'}>Total</th>
															{/*<th>Anticipo</th>
															<th>Entregado a almacén</th>
															<th>Entregado al cliente</th>
															<th>Progreso</th>
															<th className={'text-right padding-30-R'}>Acciones</th>*/}
														</tr>
														</thead>
														<tbody>
														{item.venta_producto.map((value, index) => (
															<tr key={index}>
																<td className={'text-left padding-20-L'}>{value.venta_categoria || "-"}</td>
																{/*<td className={'text-left'}>{value.venta_codigo || "-"}</td>*/}
																<td className={'text-left'}>{item.numero_pedido + '-' + value.id_venta_producto}</td>
																<td className={'text-left'}>{value.venta_modelo}</td>
																<td className={'text-left'}>{value.venta_descripcion}</td>
																<td className={'text-center'}>{value.venta_color}</td>
																<td className={'text-center'}>{value.venta_talla}</td>
																<td className={'text-center'}>{value.venta_cantidad}</td>
																<td className={'text-right padding-20-R'}>${FieldsJs.Currency(value.venta_precio_unitario)}</td>
																<td className={'text-right padding-20-R'}>${FieldsJs.Currency(value.venta_cantidad * value.venta_precio_unitario)}</td>
															</tr>
														))}
														<tr>
															<td colSpan={3} className={'text-left'} style={{fontWeight: 'bold', color: '#2b4ca0'}}>Bolsa(s):
																<o className={'w-100 padding-0 margin-0 margin-15-L ff-arial'} style={{color: 'black'}}>
																	{item.bolsas_seleccionadas.length === 0 ? 'N/A':item.bolsas_seleccionadas.map((bolsa, key) => (
																		<Fragment>
																			{bolsa.cantidad_bolsa + ' ' + bolsa.bolsa + ' - $' + FieldsJs.Currency(bolsa.costo) + (item.bolsas_seleccionadas.length > 1 && key < item.bolsas_seleccionadas.length - 1 ? ' | ':'')}
																		</Fragment>
																	))}
																</o>
																<o className={'text-left margin-30-L'} style={{fontWeight: 'bold', color: '#2b4ca0'}}>Total Bolsas:
																	<o className={'w-100 padding-0 margin-0 margin-15-L ff-arial'} style={{color: 'black'}}>
																		<Fragment>
																			${FieldsJs.Currency(this.obtenerTotalBolsas(item.bolsas_seleccionadas))}
																		</Fragment>
																	</o>
																</o>
															</td>
															<td className={'text-right'} style={{fontWeight: 'bold', color: '#2b4ca0'}}>Costo de envió:</td>
															<td className={'text-center'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.costo_mensajeria || 0)}</td>
															<td className={'text-right'} style={{fontWeight: 'bold', color: '#2b4ca0'}}>Total Cantidad: </td>
															<td className={'text-center'} style={{fontWeight: 'bold'}}>{item.total_cantidad || 0}</td>
															<td className={'text-right padding-20-R'} style={{fontWeight: 'bold', color: '#2b4ca0'}}>Total: </td>
															<td className={'text-right  padding-20-R'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.total_precio || 0)}</td>
														</tr>
														</tbody>
													</table>
												</Grid>
											</Grid>
										</div>
									
									</TableCell>
								</TableRow>
							) : null}
						</Fragment>
					))}
				</TableBody>
			</Table>
		</Paper>)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	exportar: PropTypes.func.isRequired,
	exportarTicket: PropTypes.func.isRequired,
	modalDisenador: PropTypes.func.isRequired,
	// almacen: PropTypes.func.isRequired,
	// entregar: PropTypes.func.isRequired,
	cancelar: PropTypes.func.isRequired,
	activar: PropTypes.func.isRequired,
	produccion: PropTypes.func.isRequired,
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	
	watchHorasTrabajadas: PropTypes.func.isRequired,
	watchAsignarCliente: PropTypes.func.isRequired,
	watchEnviarTodoAlmacen: PropTypes.func.isRequired,
	watchEnviarTodoCliente: PropTypes.func.isRequired,
	watchEnviarAlmacen: PropTypes.func.isRequired,
	watchEnviarCliente: PropTypes.func.isRequired,
	watchAprobarCotizacion: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	DesactivarFacturaNotaSencillaInterno: PropTypes.func.isRequired,
	tipo_vista: PropTypes.string.isRequired,
	
};

export default withStyles(styles)(withRouter(ListaTabla));
