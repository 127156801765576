import React, {Component} from 'react';

import Recuperar from './Recuperar';

import './Recuperar.css';

class RecuperarIndex extends Component {
	render() {
		return (
			<div className='RecuperarIndex'>
				
				<Recuperar/>
			
			</div>
		);
	}
}

export default RecuperarIndex;
