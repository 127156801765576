import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {CadenaDomicilio, FieldsJs} from "../../../../settings/General/General";
import {
	GrainOutlined,
	HdrStrongOutlined,
	HdrWeakOutlined,
	ImageAspectRatioOutlined,
	PinDropOutlined,
	StreetviewOutlined
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {PerfilService} from "../../../../services/_Sis/PerfilService/PerfilService";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from "@material-ui/icons/EditOutlined";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import Fab from "@material-ui/core/Fab";
import Map from "@material-ui/icons/Map";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalProducto from "../../Producto/Includes/ModalProducto";
import Table from "@material-ui/core/Table";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ProductoService} from "../../../../services/_Sis/ProductoService/ProductoService";


class ModalCambiarDomicilio extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			id_cat_municipio: '',
			id_cat_estado: '',
			id_usuario_direccion: '',
			domicilio_edit: '',
			list_cat_estado: [],
			list_cat_municipio: [],
			list_domicilios: props.list_domicilios || [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {form} = this.props;
		console.log(form);
		this.setState({
			modal: {
				open: true
			},
			domicilio_edit: '',
			list_domicilios: form.list_domicilios || [],
			/*calle: form.calle || '',
			numero_exterior: form.numero_exterior || '',
			numero_interior: form.numero_interior || '',
			codigo_postal: form.codigo_postal || '',
			colonia: form.colonia || '',
			id_cat_municipio: form.id_cat_municipio || '',
			id_cat_estado: form.id_cat_estado || '',*/
		});
		this.ListEstado();
		/*if (form.id_cat_estado > 0) {
			this.ListMunicipio({target: {value: form.id_cat_estado}});
		}*/
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			},
			domicilio_edit: '',
			calle: '',
			referencia: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			id_cat_municipio: '',
			id_cat_estado: '',
		});
	};
	
	editar = (item) => {
		if (FieldsJs.Field(this.state.id_usuario_direccion)) {
			this.setState({
				id_usuario_direccion: '',
				calle: '',
				referencia: '',
				numero_exterior: '',
				numero_interior: '',
				codigo_postal: '',
				colonia: '',
				id_cat_municipio: '',
				id_cat_estado: '',
			});
		} else {
			this.setState({
				domicilio_edit: item.id_usuario_direccion,
				id_usuario_direccion: item.id_usuario_direccion || '',
				calle: item.calle || '',
				referencia: item.referencia || '',
				numero_exterior: item.numero_exterior || '',
				numero_interior: item.numero_interior || '',
				codigo_postal: item.codigo_postal || '',
				colonia: item.colonia || '',
				id_cat_estado: item.id_cat_estado || '',
			});
			
			this.ListMunicipio(item.id_cat_estado).then(() => {
				this.setState({
					id_cat_municipio: item.id_cat_municipio || '',
				});
			});
		}
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				list_cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (id_cat_estado) => {
		this.setState({
			id_cat_municipio: ''
		});
		
		return new Promise((resolve, reject) => {
			CatService.ListMunicipio(id_cat_estado).then((response) => {
				this.setState({
					list_cat_municipio: response.data
				});
				resolve(response);
			});
		});
	};
	
	agregar = () => {
		PerfilService.PerfilUsuariosAgregarDomicilio(this.state).then(response => {
			this.props.showSnackBars('success', response.mensaje);
			this.props.refresh();
			this.closeModal();
		}).catch(error => {
			this.props.showSnackBars('error', error.mensaje);
		})
	};
	
	actualizar = () => {
		PerfilService.PerfilUsuariosActualizarDomicilio(this.state).then(response => {
			this.props.showSnackBars('success', response.mensaje);
			this.props.refresh();
			this.closeModal();
		}).catch(error => {
			this.props.showSnackBars('error', error.mensaje);
		})
	};
	
	eliminar = (item, id) => {
		let msg = `¿Deseas eliminar el domicilio con id ${id}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				PerfilService.PerfilUsuariosEliminarDomicilio(item).then(response => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.refresh();
					this.closeModal();
				}).catch(error => {
					this.props.showSnackBars('error', error.mensaje);
				})
			}
		});
		
		
	};
	
	preferido = () => {
		PerfilService.PerfilUsuariosPreferidoDomicilio(this.state).then(response => {
			this.props.showSnackBars('success', response.mensaje);
			this.props.refresh();
			this.closeModal();
		}).catch(error => {
			this.props.showSnackBars('error', error.mensaje);
		})
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.tipo === 'edit') {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar dirección">
					<IconButton aria-label="Editar dirección" onClick={() => this.openModal()}>
						<EditOutlined/>
					</IconButton>
				</Tooltip>
			);
		} else if (this.state.tipo === 'view') {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
					<IconButton aria-label="Detalles" onClick={() => this.openModal()}>
						<SearchOutlined/>
					</IconButton>
				</Tooltip>
			);
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar dirección">
					<IconButton aria-label="Agregar dirección" onClick={() => this.openModal()} variant="contained" color="primary">
						<Map/>
					</IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<Grid>
				
				{BTN_ACTION}
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					<DialogTitle>Domicilio(s)</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="calle"
										           onChange={this.handleChange}
										           label="Calle" autoComplete={'off'}
										           value={this.state.calle}
										           required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_exterior"
										           onChange={this.handleChange}
										           label="Numero Exterior" autoComplete={'off'}
										           value={this.state.numero_exterior}
										           required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_interior"
										           onChange={this.handleChange}
										           label="Numero Interior" autoComplete={'off'}
										           value={this.state.numero_interior}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           label="Código Postal" autoComplete={'off'}
										           value={this.state.codigo_postal}
										           required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="colonia"
										           onChange={this.handleChange}
										           label="Colonia" autoComplete={'off'}
										           value={this.state.colonia}
										           required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.ListMunicipio(e.target.value);
											}}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_estado"
											label="Estado"
											value={this.state.id_cat_estado}
											required
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_estado.map(option => (
												<option key={option.id_cat_estado}
												        value={option.id_cat_estado}>
													{option.estado}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_municipio"
											label="Municipio"
											value={this.state.id_cat_municipio}
											required
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_municipio.map(option => (
												<option key={option.id_cat_municipio}
												        value={option.id_cat_municipio}>
													{option.municipio}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="referencia"
										           onChange={this.handleChange}
										           label="Referencia" autoComplete={'off'}
										           value={this.state.referencia}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.state.list_domicilios.length > 0 ? (
									<Table className={'desing-acresco'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'} className={'padding-20-L padding-20-R'}>Id</TableCell>
												<TableCell component="th" align={'center'}>Dirección</TableCell>
												{/*<TableCell component="th" align={'left'}>Preferido</TableCell>*/}
												<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.list_domicilios.map((item, key) => (
												<TableRow key={key}>
													
													<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
														{key + 1}
													</TableCell>
													
													<TableCell align={'left'}>
														{CadenaDomicilio(item || {})}
													</TableCell>
													
													{/*<TableCell align={'left'}>*/}
													{/*	{item.descripcion}*/}
													{/*</TableCell>*/}
													
													
														<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
															{ Number(item.id_usuario_direccion) !== 1 ? (
															<div className={'w-auto vertical-inline'}>
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																		<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item, key + 1)}>
																			<DeleteOutlined/>
																		</IconButton>
																	</Tooltip>
																</div>
																
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Actualizar">
																		<IconButton aria-label="Actualizar" onClick={() => this.editar(item)}>
																			<EditOutlined style={{ color: FieldsJs.Field(this.state.id_usuario_direccion) && Number(this.state.domicilio_edit) === Number(item.id_usuario_direccion) ? '#2b4ca0':'rgba(0, 0, 0, 0.54)', }}/>
																		</IconButton>
																	</Tooltip>
																</div>
															</div>
															) : <div> - </div> }
														</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								) : null}
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.closeModal()} variant="contained" color="primary" className={'btn-default-primary text-transform-none cursor-pointer'}>
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => !FieldsJs.Field(this.state.id_usuario_direccion) ? this.agregar() : this.actualizar()} variant="contained" color="primary" className={'btn-default-primary text-transform-none cursor-pointer'}>
									{FieldsJs.Field(this.state.id_usuario_direccion) ? "Actualizar domicilio":"Agregar domicilio"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}


ModalCambiarDomicilio.propTypes = {
	form: PropTypes.object.isRequired,
	tipo: PropTypes.string.isRequired,
	component: PropTypes.element.isRequired,
	refresh: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCambiarDomicilio;
