import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";

class ItemFormaPago extends Component {
	
	render() {
		
		return (
			<Fragment>
				<div
					className={'cursor-pointer ' + (this.props.selected ? 'color-item-forma-pago-azul' : 'color-item-forma-pago-gris')}>
					<div className={'bg-img-contain-x-right-y-center padding-10 w-100'} style={this.props.bg_item}>
						
						<div className={'row-flex h-100'}>
							<div className={'w-60-px'}>
								<div className={'bg-img-contain-x-center-y-center h-40-px w-40-px'}
								     style={this.props.bg_icon}/>
							</div>
							<div className={'w-100-60-px vertical-inline'}>
								<div className={'v-center'}>
									<Typography className={'px-16'}>
										{this.props.title}
									</Typography>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</Fragment>
		);
	}
}

ItemFormaPago.propTypes = {};

ItemFormaPago.propTypes = {
	title: PropTypes.string.isRequired,
	bg_icon: PropTypes.object.isRequired,
	bg_item: PropTypes.object.isRequired,
};

export default ItemFormaPago;
