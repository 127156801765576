import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import './OrdenDeVenta.css';

import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {OrdenDeVentaService} from "../../../services/_Sis/OrdenDeVentaService/OrdenDeVentaService";
import ContenidoOrdenVenta from './Includes/ContenidoOrdenVenta'
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {FieldsJs} from "../../../settings/General/General";

import ModalAccionPDF from './Includes/ModalAccionPDF';
import {ClienteService} from "../../../services/_Sis/ClienteService/ClienteService";
import ModalPagarOrdenDeVenta from "./Includes/ModalPagarOrdenDeVenta";
import {PopupService} from "../../../settings/PoPup/PoPup";
import moment from "moment";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalProductos from "./Includes/ModalProductos";
import $State from "../../../settings/$State/$State";


let productos = [];

const formulario = {
	id_cliente: null,
	id_cat_tipo_persona: 1,
	tipo_persona: 'fisica',
	razon_social: '',
	nombre: '',
	apellido_paterno: '',
	apellido_materno: '',
	correo_electronico: '',
	telefono: '',
	celular: '',
	rfc: '',
	activo: true,
	
	id_cliente_direccion: null,
	calle: '',
	numero_exterior: '',
	numero_interior: '',
	codigo_postal: '',
	colonia: '',
	id_cat_estado: '',
	id_cat_municipio: '',
	estado: '',
	municipio: '',
	id_cat_tipo_mensajeria: '',
	ids_cliente_como_te_enteraste: []
};

const formulariopagos = {
	id_cat_forma_pago: '',
	venta_total: '',
	monto: '',
	pago_con: '',
	numero_referencia: '',
	folio: '',
	clave_rastreo: '',
	cuenta_origen: '',
	cantidad: '',
	
	productos: '',
};

class OrdenDeVenta extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		let fecha_alta = DateFormat.FormatText(DateFormat.ConvertTimestamp('', 'fecha', 'MySql'));
		let fecha_entrega = null;
		
		this.state = {
			modal: {
				open: false,
				title: '',
				tipo: ''
			},
			
			form: formulario,
			form_seleccionado: {},
			
			modalpagos: {
				open: false,
				title: '',
				tipo: ''
			},
			
			modalorden: {
				open: false,
				title: '',
				tipo: ''
			},
			
			formpagos: formulariopagos,
			
			open: false,
			
			id_cliente: null,
			
			fecha_solicitud: null,
			
			id_venta_pagar_exportar: null,
			cliente_pagar_exportar: {},
			
			ws_lista_pagos: [],
			ws_por_pagar: 0,
			ws_total_pagado: 0,
			ws_total_venta: 0,
			ws_descuento_venta: 0,
			ws_anticipo_completo: false,
			ws_numero_pedido: '',
			
			id_venta: props.params.id_venta || undefined,
			
			numero_pedido: '',
			fecha_alta: fecha_alta,
			fecha_entrega: fecha_entrega,
			porcentaje_descuento: '',
			//factura_cotizacion_nota_sencilla_interno: ReactLocalStorageService.get('lista_para_ordenventa') === 'cotizacion' ? 'cotizacion' : 'nota_sencilla',
			factura_cotizacion_nota_sencilla_interno: 'nota_sencilla',
			
			por_hora: false,
			anticipo: '',
			observaciones: '',
			
			cliente_deja_anexo: false,
			cliente_envia_muestra_por_correo: false,
			
			productos: FieldsJs.Copy(productos),
			
			cat_costo_mensajeria: [],
			cat_tipo_requerimiento: [],
			cat_como_te_enteraste: [],
			cat_estado: [],
			cat_municipio: [],
			cat_grupo: [],
			cat_planes: [],
			cat_productos: [],
			cat_categorias: [],
			
			clientes_datos_suggestions: [],
			
			_fecha_solicitud: null,
			_producto: '',
			_descripcion: '',
			_id_cat_grupo: '',
			_cantidad: '',
			_precio_unitario: '',
			_otro_requerimiento: '',
			_codigo_producto: '',
			
			producto_requerimiento_adicional: [],
			
			cliente_como_te_enteraste: [],
			
			productos_anticipo: [],
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		this.OrdenDeVentaDatos(this.state.id_venta);
		// this.ListarDeCliente();
		//this.ListComoTeEnteraste();
		this.ListCostoMensajeria();
		this.ListProductos();
		this.ListCategorias();
		
		console.log('PARAMS>>>>', ReactLocalStorageService.get('lista_para_ordenventa'));
		
		ReactLocalStorageService.set('lista_para_ordenventa', '');
	}
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 20;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	ListComoTeEnteraste = () => {
		CatService.ListComoTeEnteraste().then((response) => {
			this.setState({
				cliente_como_te_enteraste: response.data
			});
		});
	};
	
	ListCostoMensajeria = () => {
		CatService.ListCostoMensajeria().then((response) => {
			this.setState({
				cat_costo_mensajeria: response.data
			});
		});
	};
	
	Listar = () => {
		this.ListProductos();
	}
	
	ListProductos = () => {
		CatService.ListProductos(this.state.paginacion).then((response) => {
			this.setState({
				cat_productos: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		});
	};
	
	ListCategorias = () => {
		CatService.ListCategoria().then((response) => {
			this.setState({
				cat_categorias: response.data
			});
		});
	};
	
	handleChangeSingle = (e, name) => {
		console.log(name, e);
		
		let form = FieldsJs.Copy(formulario);
		
		for (let i = 0; i < this.state.clientes_datos_suggestions.length; i++) {
			if (this.state.clientes_datos_suggestions[i].id_cliente === e.value) {
				for (let key in formulario) {
					form[key] = this.state.clientes_datos_suggestions[i][key];
				}
			}
		}
		
		this.setState({
			[name]: e.value,
			form: FieldsJs.Copy(form),
			form_seleccionado: FieldsJs.Copy(form)
		});
	};
	
	handleChangeForm = (e) => {
		const {value, name, checked, type} = e.target;
		let form = this.state.form || {};
		form[name] = type === 'checkbox' ? checked : value;
		this.setState({
			form: form
		});
	};
	
	handleChangePago = (e) => {
		let formpagos = this.state.formpagos || {};
		const {value, name, checked, type} = e.target;
		formpagos[name] = type === 'checkbox' ? checked : value;
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChangePagoIdCatFormaPago = (v, n) => {
		let desglose = this.updateDesglose(1);
		let formpagos = FieldsJs.Copy(formulariopagos) || {};
		console.log(desglose.descuento);
		formpagos[n] = v;
		formpagos["total_venta"] = desglose.total;
		formpagos["monto"] = desglose.total;
		formpagos["descuento_venta"] = desglose.descuento;
		formpagos["productos"] = desglose.productos;
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	handleChangeRange = (e, variable, key) => {
		console.log("HANDLECHANGERANGE:::", e);
		const {startDate, endDate} = e;
		
		let arr_temp = this.state[variable];
		
		for (let i = 0; i < arr_temp.length; i++) {
			
			if (key === i) {
				arr_temp[i]['fecha_inicio'] = startDate;
				arr_temp[i]['fecha_fin'] = endDate;
			}
		}
		
		this.setState({
			[variable]: arr_temp
		});
	};
	
	resetState = () => {
		
		let fecha_alta = DateFormat.FormatText(DateFormat.ConvertTimestamp('', 'fecha', 'MySql'));
		let fecha_entrega = null;
		
		let form = FieldsJs.Copy(formulario);
		
		this.setState({
			
			id_cliente: null,
			form: form,
			form_seleccionado: form,
			
			numero_pedido: '',
			fecha_alta: fecha_alta,
			fecha_entrega: fecha_entrega,
			porcentaje_descuento: 0,
			//factura_cotizacion_nota_sencilla_interno: ReactLocalStorageService.get('lista_para_ordenventa') === 'cotizacion' ? 'cotizacion':'nota_sencilla',
			por_hora: false,
			anticipo: '',
			observaciones: '',
			
			cliente_deja_anexo: '',
			cliente_envia_muestra_por_correo: '',
			
			productos: FieldsJs.Copy(productos),
		});
		
		setTimeout(() => {
			this.handleChangeSingle({value: 1}, "id_cliente");
		}, 500);
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	
	updatePrecio = () => {
		/*let product = this.state.productos;
		for (let i = 0; i < product.length; i++) {
			if (product[i].venta_cantidad > 0 && product[i].venta_precio_unitario > 0) {
				product[i].venta_precio = (product[i].venta_cantidad * product[i].venta_precio_unitario) + Number(product[i].venta_costo_adicional);
			} else {
				product[i].venta_precio = '';
			}
		}
		this.setState({
			productos: product
		});*/
		let productos = FieldsJs.Copy(this.state.productos);
		for (let i = 0; i < productos.length; i++) {
			let item = productos[i];
			if (item.venta_cantidad > 0 && item.venta_precio_unitario > 0) {
				let total = 0;
				total += (item.venta_cantidad * item.venta_precio_unitario);
				for (let j = 0; j < item.venta_producto_tipo_requerimiento.length; j++) {
					total += (item.venta_producto_tipo_requerimiento[j].costo_adicional || 0)
				}
				for (let j = 0; j < item.venta_producto_requerimiento_adicional.length; j++) {
					total += (item.venta_producto_requerimiento_adicional[j].costo_adicional || 0)
				}
				item.venta_precio = total;
				item.venta_descuento = this.total_descuento(total, (item.venta_porcentaje_descuento || 0), (this.state.porcentaje_descuento || 0));
				productos[i].venta_precio = item.venta_precio;
				productos[i].venta_descuento = item.venta_descuento;
			} else {
				productos[i].venta_precio = '';
				productos[i].venta_descuento = '';
			}
			
			if (productos[i].fecha_inicio && productos[i].fecha_fin) {
				productos[i].fecha_inicio = new Date(productos[i].fecha_inicio);
				productos[i].fecha_fin = new Date(productos[i].fecha_fin);
			}
		}
		this.setState({
			productos: productos
		}, () => {
			console.log('PRODUCTOS::: ', this.state.productos);
		});
	};
	
	agregar_producto_requerimiento_adicional = () => {
		
		console.log(this.state);
		
		let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
		let requerimiento_adicional = this.state._otro_requerimiento;
		
		try {
			
			if (!requerimiento_adicional) {
				throw Object({
					status: false,
					mensaje: 'Escribe el requerimiento'
				});
			}
			
			producto_requerimiento_adicional.push({
				requerimiento_adicional: requerimiento_adicional,
				costo_adicional: ''
			});
			
			this.setState({
				producto_requerimiento_adicional: producto_requerimiento_adicional,
				_otro_requerimiento: ''
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_producto_requerimiento_adicional = (key) => {
		
		console.log(this.state);
		
		try {
			
			let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
			
			producto_requerimiento_adicional.splice(key, 1);
			
			this.setState({
				producto_requerimiento_adicional: producto_requerimiento_adicional
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_requerimiento = (key, keyTR) => {
		
		console.log(this.state);
		
		try {
			
			let btn = ['Cancelar', 'Aceptar'];
			let msg = '¿Deseas eliminar esta propiedad del producto?';
			
			PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === btn[1]) {
					let product = FieldsJs.Copy(this.state.productos);
					
					for (let i = 0; i < product.length; i++) {
						if (i === key) {
							product[i].venta_producto_tipo_requerimiento.splice(keyTR, 1);
						}
					}
					
					for (let i = 0; i < product.length; i++) {
						let it = product[i];
						let costo_adicional = 0;
						for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
							costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
						}
						for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
							costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
						}
						product[i].venta_costo_adicional = costo_adicional;
						product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
					}
					
					this.setState({
						productos: product
					})
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_producto_atributo = (key, keyTR) => {
		
		console.log(this.state);
		
		try {
			
			let btn = ['Cancelar', 'Aceptar'];
			let msg = '¿Deseas eliminar esta propiedad del producto?';
			
			PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === btn[1]) {
					let product = FieldsJs.Copy(this.state.productos);
					
					for (let i = 0; i < product.length; i++) {
						if (i === key) {
							product[i].venta_producto_requerimiento_adicional.splice(keyTR, 1);
						}
					}
					
					for (let i = 0; i < product.length; i++) {
						let it = product[i];
						let costo_adicional = 0;
						for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
							costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
						}
						for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
							costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
						}
						product[i].venta_costo_adicional = costo_adicional;
						product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
					}
					
					this.setState({
						productos: product
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	agregar_producto_atributo = (key, item) => {
		
		console.log(item, this.state);
		
		try {
			
			let product = FieldsJs.Copy(this.state.productos);
			
			for (let i = 0; i < product.length; i++) {
				if (i === key) {
					product[i].venta_producto_requerimiento_adicional.push({
						requerimiento_adicional: item.requerimiento_adicional,
						costo_adicional: item.costo_adicional,
					});
				}
			}
			
			for (let i = 0; i < product.length; i++) {
				let it = product[i];
				let costo_adicional = 0;
				for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
					costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
				}
				for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
					costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
				}
				product[i].venta_costo_adicional = costo_adicional;
				product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
			}
			
			this.setState({
				productos: product
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	mas = (item) => {
		console.log("PRODUCTO::::   " + JSON.stringify(item));
		
		try {
			/*if (!this.state._fecha_solicitud) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Fecha Solicitud."
				});
			}*/
			if (!FieldsJs.Field(item.id_producto)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Producto."
				});
			}
			if (!FieldsJs.Field(item.color_pedido)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Color."
				});
			}
			if (!FieldsJs.Field(item.cantidad_pedido && item.cantidad_pedido > 0)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Cantidad."
				});
			}
			if (!FieldsJs.Field(item.talla_pedido && item.talla_pedido > 0)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Talla."
				});
			}
			if (!FieldsJs.Field(item.precio)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Precio unitario."
				});
			}
			/*if (this.state._otro_requerimiento) {
				throw Object({
					status: false,
					mensaje: "Has escrito en otros requerimientos, para poder agregar el producto, asegúrate de agregar el requerimiento, o limpiar otros requerimientos"
				});
			}*/
			
			let product = FieldsJs.Copy(this.state.productos);
			let costo_adicional = 0;
			
			let cat_tipo_requerimiento = FieldsJs.Copy(this.state.cat_tipo_requerimiento);
			let venta_producto_tipo_requerimiento = [];
			let item1 = {};
			for (let i = 0; i < cat_tipo_requerimiento.length; i++) {
				
				item1 = FieldsJs.Copy(cat_tipo_requerimiento[i]);
				
				if (item1.checked) {
					
					item1.costo_adicional = (item1.costo_adicional > 0 ? Number(item1.costo_adicional) : 0);
					costo_adicional += Number(item1.costo_adicional);
					
					venta_producto_tipo_requerimiento.push(item1);
					cat_tipo_requerimiento[i].checked = false;
					cat_tipo_requerimiento[i].costo_adicional = '';
				}
			}
			
			let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
			let venta_producto_requerimiento_adicional = [];
			let item2 = {};
			for (let i = 0; i < producto_requerimiento_adicional.length; i++) {
				
				item2 = FieldsJs.Copy(producto_requerimiento_adicional[i]);
				
				item2.id_venta_producto_requerimiento_adicional = null;
				item2.costo_adicional = (item2.costo_adicional > 0 ? Number(item2.costo_adicional) : 0);
				costo_adicional += Number(item2.costo_adicional);
				
				venta_producto_requerimiento_adicional.push(item2);
			}
			product.push({
				fecha_solicitud: DateFormat.FormatSql(new Date()),
				id_producto: item.id_producto,
				venta_producto: item.producto,
				venta_categoria: item.categoria,
				venta_modelo: item.producto,
				venta_codigo: item.clave,
				venta_descripcion: item.descripcion,
				id_cat_grupo: item.id_cat_grupo,
				venta_cantidad: Number(item.cantidad_pedido),
				venta_precio_unitario: item.precio_unitario,
				venta_costo_adicional: costo_adicional,
				venta_precio: (item.cantidad_pedido * item.precio_unitario) + Number(costo_adicional),
				venta_porcentaje_descuento: 0,
				venta_descuento: 0,
				venta_talla: item.talla_pedido,
				venta_color: item.color_pedido,
				venta_producto_tipo_requerimiento: venta_producto_tipo_requerimiento,
				venta_producto_requerimiento_adicional: venta_producto_requerimiento_adicional,
				is_plan: false,
				id_cat_plan: false,
				fecha_inicio: false,
				fecha_fin: false
			});
			
			item.cantidad_pedido = '';
			item.talla_pedido = '';
			item.color_pedido = '';
			
			this.setState({
				productos: product,
				_fecha_solicitud: null,
				_id_producto: '',
				_producto: '',
				_descripcion: '',
				_id_cat_grupo: '',
				_cantidad: '',
				_precio_unitario: '',
				_otro_requerimiento: '',
				_codigo_producto: '',
				cat_tipo_requerimiento: cat_tipo_requerimiento,
				producto_requerimiento_adicional: [],
			});
			
			setTimeout(() => {
				this.updatePrecio();
			}, 1000);
			
			this.showSnackBars('success', "Has agregado un producto a tu pedido correctamente.");
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	menos = (item, key) => {
		
		let btn = ['Cancelar', 'Aceptar'];
		let msg = '¿Deseas eliminar este producto de la orden de venta?';
		
		PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === btn[1]) {
				let product = FieldsJs.Copy(this.state.productos);
				
				product.splice(key, 1);
				
				this.setState({
					productos: product
				});
			}
		});
		
	};
	
	OrdenDeVentaDatos = (id_venta) => {
		OrdenDeVentaService.OrdenDeVentaDatos(id_venta).then((response) => {
			
			let venta = response.data.venta || {};
			
			let fecha_alta = DateFormat.FormatText(DateFormat.ConvertTimestamp(venta.fecha_alta, 'fecha', 'MySql'));
			
			if (FieldsJs.Array(venta)) {
				
				let fecha_entrega = moment(venta.fecha_entrega);
				
				let factura_cotizacion_nota_sencilla_interno = '';
				
				if (venta.factura === 1) {
					factura_cotizacion_nota_sencilla_interno = 'factura';
				} else if (venta.nota_sencilla === 1) {
					factura_cotizacion_nota_sencilla_interno = 'nota_sencilla';
				} else if (venta.interno === 1) {
					factura_cotizacion_nota_sencilla_interno = 'interno';
				} else if (venta.cotizacion === 1) {
					factura_cotizacion_nota_sencilla_interno = 'cotizacion';
				}
				factura_cotizacion_nota_sencilla_interno = 'nota_sencilla';
				
				let productos = this.state.productos;
				
				if (productos.length >= venta.venta_producto.length) {
					
					for (let k = 0; k < venta.venta_producto.length; k++) {
						let item = venta.venta_producto[k];
						
						productos[k].id_venta_producto = item.id_venta_producto;
						productos[k].id_venta = item.id_venta;
						productos[k].id_producto = item.id_producto;
						
						productos[k].venta_producto = item.venta_producto;
						productos[k].venta_descripcion = item.venta_descripcion;
						productos[k].id_cat_grupo = item.id_cat_grupo;
						productos[k].venta_cantidad = item.venta_cantidad;
						productos[k].venta_precio_unitario = item.venta_precio_unitario;
						productos[k].venta_costo_adicional = item.venta_costo_adicional;
						productos[k].venta_porcentaje_descuento = item.venta_porcentaje_descuento;
						productos[k].venta_descuento = item.venta_descuento;
						productos[k].venta_precio = item.venta_precio;
						productos[k].venta_producto_tipo_requerimiento = item.venta_producto_tipo_requerimiento || [];
						productos[k].venta_producto_requerimiento_adicional = item.venta_producto_requerimiento_adicional || [];
					}
					
				} else {
					
					productos = [];
					
					for (let k = 0; k < venta.venta_producto.length; k++) {
						let item = venta.venta_producto[k];
						productos.push({
							id_venta_producto: item.id_venta_producto,
							id_venta: item.id_venta,
							id_producto: item.id_producto,
							
							venta_producto: item.venta_producto,
							venta_descripcion: item.venta_descripcion,
							id_cat_grupo: item.id_cat_grupo,
							venta_cantidad: item.venta_cantidad,
							venta_precio_unitario: item.venta_precio_unitario,
							venta_costo_adicional: item.venta_costo_adicional,
							venta_porcentaje_descuento: item.venta_porcentaje_descuento,
							venta_descuento: item.venta_descuento,
							venta_precio: item.venta_precio,
							venta_producto_tipo_requerimiento: item.venta_producto_tipo_requerimiento || [],
							venta_producto_requerimiento_adicional: item.venta_producto_requerimiento_adicional || [],
							
							venta_categoria: item.categoria,
							venta_modelo: item.modelo,
							venta_codigo: item.codigo,
							venta_talla: item.talla,
							venta_color: item.color,
							
							is_plan: false,
							id_cat_plan: false,
							fecha_inicio: false,
							fecha_fin: false
						});
					}
				}
				
				/*
				* Recuperar como te enteraste y tipo requerimientos
				* */
				
				let venta_como_te_enteraste = venta.venta_como_te_enteraste || [];
				
				
				let cat_como_te_enteraste = [];
				
				for (let i = 0; i < response.data.cat_como_te_enteraste.length; i++) {
					let item0 = response.data.cat_como_te_enteraste[i];
					item0.checked = false;
					for (let j = 0; j < venta_como_te_enteraste.length; j++) {
						let item1 = venta_como_te_enteraste[j];
						if (item0.id_cat_como_te_enteraste === item1.id_cat_como_te_enteraste) {
							item0.id_venta_como_te_enteraste = item1.id_venta_como_te_enteraste;
							item0.checked = true;
						}
					}
					cat_como_te_enteraste.push(item0);
				}
				
				let cat_tipo_requerimiento = [];
				
				for (let i = 0; i < response.data.cat_tipo_requerimiento.length; i++) {
					let item = response.data.cat_tipo_requerimiento[i];
					item.checked = false;
					item.costo_adicional = '';
					cat_tipo_requerimiento.push(item);
				}
				
				let form = {
					id_cliente: venta.id_cliente || '',
					id_cat_tipo_persona: venta.id_cat_tipo_persona || 1,
					tipo_persona: venta.tipo_persona || 'fisica',
					razon_social: venta.razon_social || '',
					nombre: venta.cliente_nombre || '',
					apellido_paterno: venta.cliente_apellido_paterno || '',
					apellido_materno: venta.cliente_apellido_materno || '',
					correo_electronico: venta.cliente_correo_electronico || '',
					celular: venta.cliente_celular || '',
					telefono: venta.cliente_telefono || '',
					rfc: venta.cliente_rfc || '',
					
					id_cliente_direccion: venta.cliente_id_cliente_direccion || '',
					calle: venta.cliente_calle || '',
					numero_exterior: venta.cliente_numero_exterior || '',
					numero_interior: venta.cliente_numero_interior || '',
					codigo_postal: venta.cliente_codigo_postal || '',
					colonia: venta.cliente_colonia || '',
					id_cat_estado: venta.cliente_id_cat_estado || '',
					id_cat_municipio: venta.cliente_id_cat_municipio || '',
					id_cat_tipo_mensajeria: venta.id_cat_tipo_mensajeria || '',
					bolsas_seleccionadas: venta.bolsas_seleccionadas || [],
					
				};
				
				this.setState({
					
					numero_pedido: venta.numero_pedido,
					fecha_alta: fecha_alta,
					fecha_entrega: fecha_entrega,
					porcentaje_descuento: venta.porcentaje_descuento,
					factura_cotizacion_nota_sencilla_interno: factura_cotizacion_nota_sencilla_interno,
					por_hora: venta.por_hora,
					anticipo: venta.anticipo,
					observaciones: venta.observaciones || '',
					
					cliente_deja_anexo: venta.cliente_deja_anexo || '',
					cliente_envia_muestra_por_correo: venta.cliente_envia_muestra_por_correo || '',
					
					id_cliente: venta.id_cliente || '',
					
					
					form: form,
					
					form_seleccionado: form,
					
					productos: productos,
					
					cat_como_te_enteraste: cat_como_te_enteraste,
					cat_tipo_requerimiento: cat_tipo_requerimiento,
					cat_grupo: response.data.cat_grupo || [],
					cat_planes: response.data.cat_planes || [],
					
					_producto: '',
					_descripcion: '',
					_id_cat_grupo: '',
					_cantidad: '',
					_precio_unitario: '',
					_otro_requerimiento: '',
					_codigo_producto: '',
					
				});
				
			} else {
				for (let i = 0; i < response.data.cat_tipo_requerimiento.length; i++) {
					response.data.cat_tipo_requerimiento[i].checked = false;
					response.data.cat_tipo_requerimiento[i].costo_adicional = '';
				}
				this.setState({
					cat_como_te_enteraste: response.data.cat_como_te_enteraste,
					cat_tipo_requerimiento: response.data.cat_tipo_requerimiento,
					cat_grupo: response.data.cat_grupo || [],
					cat_planes: response.data.cat_planes || [],
					
					_producto: '',
					_descripcion: '',
					_id_cat_grupo: '',
					_cantidad: '',
					_precio_unitario: '',
					_otro_requerimiento: '',
					_codigo_producto: '',
				});
				
			}
			
			if (!id_venta) {
				setTimeout(() => {
					this.handleChangeSingle({value: 1}, "id_cliente");
				}, 500);
			}
			
		}).catch((error) => {
			this.setState({
				cat_tipo_requerimiento: [],
				cat_como_te_enteraste: [],
				cat_grupo: []
			});
		})
	};
	
	
	OrdenDeVentaContinuar = () => {
		
		console.log(this.state);
		
		let cat_como_te_enteraste = FieldsJs.Copy(this.state.cat_como_te_enteraste);
		let ids = [];
		for (let i = 0; i < cat_como_te_enteraste.length; i++) {
			if (!!cat_como_te_enteraste[i].checked) {
				ids.push(cat_como_te_enteraste[i].id_cat_como_te_enteraste);
			}
		}
		
		try {
			/*if (!this.state.factura_cotizacion_nota_sencilla_interno) {
				throw Object({
					status: false,
					mensaje: 'Selecciona el tipo de documento (Factura, Cotización, Nota sencilla)'
				});
			}*/
			/*if (this.state.factura_cotizacion_nota_sencilla_interno === "factura") {
				if (!this.state.form_seleccionado.rfc) {
					throw Object({
						status: false,
						mensaje: 'Es necesario el RFC del cliente para la factura'
					});
				}
			}*/
			if (!(this.state.productos.length > 0)) {
				throw Object({
					status: false,
					mensaje: 'Agrega los productos para la orden'
				});
			}
			/*if (!FieldsJs.Array(ids)) {
				throw Object({
					status: false,
					mensaje: 'Selecciona como te enteraste'
				});
			}*/
			/*if (!this.state.fecha_entrega) {
				throw Object({
					status: false,
					mensaje: 'Selecciona la fecha de entrega'
				});
			}*/
			
			if (this.state.por_hora) {
				if (this.state.productos.length > 1) {
					throw Object({
						status: false,
						mensaje: 'Por el momento solo puedes agregar un solo producto al seleccionar Definir x hrs.'
					});
				}
			}
			
			if (this.state.factura_cotizacion_nota_sencilla_interno === "cotizacion" || this.state.factura_cotizacion_nota_sencilla_interno === "interno" || this.state.por_hora === true) {
				let msg = '';
				if (this.state.factura_cotizacion_nota_sencilla_interno === "cotizacion") {
					msg = '¿Estas seguro de guardar la cotización?';
				}
				if (this.state.factura_cotizacion_nota_sencilla_interno === "interno") {
					msg = '¿Estas seguro de guardar la orden de venta de tipo interno?';
				}
				if (this.state.por_hora === true) {
					msg = '¿Estas seguro de guardar la orden de venta como pago por horas trabajadas?';
				}
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						this.OrdenDeVentaGuardar(this.state.factura_cotizacion_nota_sencilla_interno, null, this.state.por_hora);
					}
				});
			} else {
				this.handleChangePagoIdCatFormaPago(this.state.productos, "productos");
				this.openModal(true, 'modalpagos');
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	Pagar = (tab) => {
		
		console.log(this.state);
		
		if (!this.state.id_venta_pagar_exportar) {
			this.OrdenDeVentaGuardar(null, tab);
		} else {
			this.OrdenDeVentaPagar(null, this.state.formpagos, tab);
		}
	};
	
	OrdenDeVentaActualizar = () => {
		let btn = ['Cancelar', 'Aceptar'];
		let msg = '¿Deseas actualizar la orden de venta?';
		
		PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === btn[1]) {
				this.OrdenDeVentaGuardar();
			}
		});
	};
	
	OrdenDeVentaGuardar = (id_cat_tipo_mensajeria, costo_mensajeria, id_usuario_direccion, bolsas_seleccionadas) => {
		return new Promise((resolve, reject) => {
			OrdenDeVentaService.OrdenDeVentaGuardar(this.state, id_cat_tipo_mensajeria, costo_mensajeria, id_usuario_direccion, bolsas_seleccionadas).then((response) => {
				
				this.showSnackBars('success', response.mensaje);
				
				if (this.props.params.editar === 'editar') {
					setTimeout(() => {
						window.history.back();
					}, 2000);
				} else {
					
					this.resetState();
					
					let info = response.data.pagos;
					
					let productos_anticipo = this.state.productos_anticipo;
					
					for (let i = 0; i < info.venta_producto.length; i++) {
						for (let j = 0; j < productos_anticipo.length; j++) {
							if (i === j) {
								productos_anticipo[j].id_venta_producto = info.venta_producto[i].id_venta_producto;
							}
						}
					}
					
					this.setState({
						id_venta_pagar_exportar: response.data.id_venta,
						cliente_pagar_exportar: response.data.cliente,
						
						ws_lista_pagos: info.pagos || [],
						ws_por_pagar: info.por_pagar || 0,
						ws_total_pagado: info.total_pagado || 0,
						ws_total_venta: info.total_venta || 0,
						ws_descuento_venta: info.descuento_venta || 0,
						ws_anticipo_completo: info.anticipo_completo || false,
						ws_numero_pedido: info.numero_pedido || '',
						
						productos_anticipo: productos_anticipo,
					});
					this.VerExportarOrden();
					/*if (factura_cotizacion_nota_sencilla_interno === "cotizacion" || factura_cotizacion_nota_sencilla_interno === "interno" || por_hora === true) {
						this.VerExportarOrden();
					} else {
						this.OrdenDeVentaPagar(response.data.id_venta, this.state.formpagos, tab);
					}*/
					
				}
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	OrdenDeVentaPagar = (id_venta, data, tab) => {
		if (!id_venta) {
			id_venta = this.state.id_venta_pagar_exportar;
		}
		OrdenDeVentaService.OrdenDeVentaPagar(id_venta, data, this.state.productos_anticipo, tab).then((response) => {
			
			let info = response.data || [];
			
			let productos_anticipo = info.venta_producto;
			
			let arr_temp = this.state.productos_anticipo;
			for (let i = 0; i < productos_anticipo.length; i++) {
				if (productos_anticipo[i].cubrir_anticipo === 0) {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					for (let j = 0; j < arr_temp.length; j++) {
						if (arr_temp[j].id_venta_producto === productos_anticipo[i].id_venta_producto) {
							productos_anticipo[i].cubrir_anticipo_checked = (arr_temp[j].cubrir_anticipo_checked || false);
						}
					}
				} else {
					productos_anticipo[i].cubrir_anticipo_checked = false;
				}
				if (productos_anticipo[i].cubrir_total === 0) {
					productos_anticipo[i].cubrir_total_checked = false;
					for (let j = 0; j < arr_temp.length; j++) {
						if (arr_temp[j].id_venta_producto === productos_anticipo[i].id_venta_producto) {
							productos_anticipo[i].cubrir_total_checked = (arr_temp[j].cubrir_total_checked || false);
						}
					}
				} else {
					productos_anticipo[i].cubrir_total_checked = false;
				}
			}
			
			this.setState({
				ws_lista_pagos: info.pagos || [],
				ws_por_pagar: info.por_pagar || 0,
				ws_total_pagado: info.total_pagado || 0,
				ws_total_venta: info.total_venta || 0,
				ws_descuento_venta: info.descuento_venta || 0,
				ws_anticipo_completo: info.anticipo_completo || false,
				ws_numero_pedido: info.numero_pedido || '',
				
				productos_anticipo: productos_anticipo,
			});
			this.handleChangePagoIdCatFormaPago(null, "id_cat_forma_pago");
			
			this.confirmacion(info.anticipo_completo, info.por_pagar);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	confirmacion = (anticipo_completo, por_pagar) => {
		
		if (anticipo_completo) {
			
			let msg = '';
			let btn = [];
			
			if (por_pagar === 0) {
				msg = `¿Se ha completado el pago de la orden de venta?`;
				btn = [null, 'Ver o exportar orden'];
			} else {
				msg = `¿Se ha completado el 50% o más del pago de la orden de venta?`;
				btn = ['Agregar otro pagos', 'Ver o exportar orden'];
			}
			
			PopupService.Confirm(btn, 'success', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === btn[0]) {
				}
				
				/*if (r.button === btn[1]) {
					this.VerExportarOrden();
				}*/
				
			});
		}
	};
	
	VerExportarOrden = () => {
		this.openModal();
	};
	
	ListarDeCliente = () => {
		ClienteService.Listar({activo: 1}, {}).then((response) => {
			
			let clt = response.data;
			
			let form = this.state.form;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form.id_cliente)) {
					form = clt[i];
				}
			}
			
			let form_seleccionado = this.state.form_seleccionado;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form_seleccionado.id_cliente)) {
					form_seleccionado = clt[i];
				}
			}
			
			this.setState({
				clientes_datos_suggestions: clt,
				form: form,
				form_seleccionado: form_seleccionado,
			});
		});
	};
	
	update_campos_tipo_persona = () => {
		if (this.state.form.tipo_persona === "moral") {
			let form = this.state.form;
			form.id_cat_tipo_persona = 2;
			form.nombre = '';
			form.apellido_paterno = '';
			form.apellido_materno = '';
			this.setState({
				form: form,
				form_seleccionado: form
			});
		}
		
		if (this.state.form.tipo_persona === "fisica") {
			let form = this.state.form;
			form.id_cat_tipo_persona = 1;
			form.razon_social = '';
			this.setState({
				form: form,
				form_seleccionado: form
			})
		}
	};
	
	RefreshListCliente = (id_cliente) => {
		console.log(id_cliente, this.state)
		if (id_cliente > 0) {
			this.update_campos_tipo_persona();
			setTimeout(() => {
				let form = FieldsJs.Copy(this.state.form);
				form.id_cliente = id_cliente;
				this.setState({
					id_cliente: form.id_cliente,
					form: FieldsJs.Copy(form),
					form_seleccionado: FieldsJs.Copy(form),
				});
				console.log('111111', form);
				this.ListarDeCliente();
			});
		} else {
			this.update_campos_tipo_persona();
			setTimeout(() => {
				let form = FieldsJs.Copy(this.state.form);
				this.setState({
					id_cliente: this.state.form.id_cliente,
					form: FieldsJs.Copy(form),
					form_seleccionado: FieldsJs.Copy(form),
				});
				console.log('000000', form);
				this.ListarDeCliente();
			});
		}
	};
	
	openModal = (index, tipo, item) => {
		console.error(item);
		if (index) {
			let m = "";
			let f = "";
			let title = "";
			let subtitle = "";
			let modalsettings = {};
			let forms = {};
			switch (tipo) {
				case 'modalpagos':
					m = "modalpagos";
					f = "formpagos";
					title = "Formas de pago";
					subtitle = "";
					//subtitle = "Toda orden de venta requiere como mínimo el 50% de anticipo";
					modalsettings = {
						open: true,
						title: title,
						subtitle: "",
						tipo: tipo
					};
					
					let desglose = this.updateDesglose(); // se abre pro primera vez los los productos parámetro null
					console.log("DESGLOSE:::: ", desglose);
					if (!(desglose.total > 0)) {
						this.showSnackBars('error', "Genera una venta para poder continuar");
						return;
					}
					
					let productos_anticipo = desglose.productos;
					
					forms = {
						id_cat_forma_pago: '',
						total_venta: desglose.total || '',
						descuento_venta: desglose.descuento || '',
						monto: desglose.total || '',
						pago_con: '',
						numero_referencia: '',
						folio: '',
						clave_rastreo: '',
						cuenta_origen: '',
						cantidad: '',
						
						productos: productos_anticipo,
					};
					break;
				case 'modalorden':
					m = "modalverorden";
					f = "formpagosorden";
					title = "Productos a ordenar";
					subtitle = "Toda orden de productos requiere realizar el pago y subir el comprobante en el modulo de seguir orden.";
					modalsettings = {
						open: true,
						title: title,
						subtitle: "",
						tipo: tipo
					};
					break;
				default:
			}
			
			let cliente_como_te_enteraste = FieldsJs.Copy(this.state.cliente_como_te_enteraste);
			
			if (FieldsJs.inArray(['add', 'edit', 'view'], tipo)) {
				let ids_cliente_como_te_enteraste = FieldsJs.Copy(item.ids_cliente_como_te_enteraste || []);
				
				for (let i = 0; i < cliente_como_te_enteraste.length; i++) {
					cliente_como_te_enteraste[i].checked = false;
					for (let j = 0; j < ids_cliente_como_te_enteraste.length; j++) {
						if (Number(cliente_como_te_enteraste[i].id_cat_como_te_enteraste) === Number(ids_cliente_como_te_enteraste[j])) {
							cliente_como_te_enteraste[i].checked = true;
						}
					}
				}
			}
			
			this.setState({
				[m]: modalsettings,
				[f]: forms,
				
				id_venta_pagar_exportar: null,
				ws_lista_pagos: [],
				ws_por_pagar: forms.total_venta || 0,
				ws_total_pagado: 0,
				ws_total_venta: forms.total_venta || 0,
				ws_descuento_venta: forms.descuento_venta || 0,
				ws_anticipo_completo: false,
				ws_numero_pedido: '',
				
				cliente_como_te_enteraste: cliente_como_te_enteraste,
				
				productos_anticipo: forms.productos
			});
			
			if (tipo === "modalpagos") {
				setTimeout(() => {
					this.toggle_linea_produccion(false);
				}, 700);
			}
			
			console.log(this.state);
			
		} else {
			this.setState({open: true});
		}
	};
	
	closeModalPedido = () => {
		this.closeModal();
		$State.go(this.props, 'ventas', {});
	}
	
	closeModal = (index, confirmacion) => {
		if (confirmacion && this.state.ws_anticipo_completo === false) {
			
			//let msg = `No se ha completado el pago del 50% de la orden de venta ¿Deseas cerrar la ventana formas de pago?`;
			let msg = `¿Deseas cerrar la ventana formas de pago?`;
			let btn = ['Cerrar', 'Realizar otro pago'];
			
			PopupService.Confirm(btn, 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === btn[0]) {
					if (index) {
						this.setState({
							modal: {
								open: false,
								title: '',
								subtitle: '',
								tipo: ''
							},
							modalpagos: {
								open: false,
								title: '',
								subtitle: '',
								tipo: ''
							},
							modalorden: {
								open: false,
								title: '',
								subtitle: '',
								tipo: ''
							}
						})
					} else {
						this.setState({open: false});
					}
				}
				
				if (r.button === btn[1]) {
				}
				
			});
		} else {
			if (index) {
				this.setState({
					modal: {
						open: false,
						title: '',
						tipo: ''
					},
					modalpagos: {
						open: false,
						title: '',
						tipo: ''
					},
					modalorden: {
						open: false,
						title: '',
						tipo: ''
					}
				})
			} else {
				this.setState({open: false});
			}
		}
	};
	
	total_descuento = (total, porcentaje_descuento_por_producto, porcentaje_descuento_total_venta) => {
		return 0;
		total = Number(total);
		porcentaje_descuento_por_producto = Number(porcentaje_descuento_por_producto);
		porcentaje_descuento_total_venta = Number(porcentaje_descuento_total_venta);
		if (porcentaje_descuento_por_producto > 0) {
			return (porcentaje_descuento_por_producto * total) / 100;
		} else if (porcentaje_descuento_total_venta > 0) {
			return (porcentaje_descuento_total_venta * total) / 100;
		} else {
			return 0;
		}
	};
	
	updateDesglose = (i) => {
		let prod = [];
		if (i) {
			prod = FieldsJs.Copy(this.state.formpagos.productos);
		} else {
			prod = FieldsJs.Copy(this.state.productos);
		}
		console.log("PRODUCTOS::: ", prod);
		let total = 0;
		let descuento = 0;
		let iva = 16;
		for (let i = 0; i < prod.length; i++) {
			let item = prod[i];
			let total_producto_actual = 0;
			if (item.venta_cantidad > 0 && item.venta_precio_unitario > 0) {
				
				total_producto_actual += (item.venta_cantidad * item.venta_precio_unitario);
				
				for (let j = 0; j < item.venta_producto_tipo_requerimiento.length; j++) {
					total_producto_actual += (item.venta_producto_tipo_requerimiento[j].costo_adicional || 0)
				}
				
				for (let j = 0; j < item.venta_producto_requerimiento_adicional.length; j++) {
					total_producto_actual += (item.venta_producto_requerimiento_adicional[j].costo_adicional || 0)
				}
				
				total += total_producto_actual;
				
				prod[i].venta_descuento = this.total_descuento(total_producto_actual, (item.venta_porcentaje_descuento || 0), (this.state.porcentaje_descuento || 0));
				
				descuento += prod[i].venta_descuento;
			}
		}
		console.log(descuento);
		let subtotal = (total / ((iva / 100) + 1));
		return {
			iva: iva,
			subtotal: subtotal,
			iva_total: total - subtotal,
			total: total,
			descuento: descuento,
			productos: prod,
		};
	};
	
	toggle_linea_produccion = (ver_producto_anticipi_linea_produccion) => {
		
		let productos_anticipo = this.state.productos_anticipo;
		
		if (ver_producto_anticipi_linea_produccion) {
			for (let i = 0; i < productos_anticipo.length; i++) {
				productos_anticipo[i].cubrir_anticipo_checked = false;
				productos_anticipo[i].cubrir_total_checked = false;
			}
			let formpagos = this.state.formpagos;
			formpagos.productos_anticipo = productos_anticipo;
			this.setState({
				formpagos: formpagos,
				productos_anticipo: productos_anticipo
			})
		} else {
			for (let i = 0; i < productos_anticipo.length; i++) {
				if (productos_anticipo[i].cubrir_total === 1) {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					productos_anticipo[i].cubrir_total_checked = false;
				} else {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					productos_anticipo[i].cubrir_total_checked = true;
				}
			}
			let formpagos = this.state.formpagos;
			formpagos.productos_anticipo = productos_anticipo;
			this.setState({
				formpagos: formpagos,
				productos_anticipo: productos_anticipo
			})
		}
	};
	
	selectProducto = (item) => {
		console.log("PRODUCTO ::: ", item);
		this.setState({
			_id_producto: item.id_producto,
			_producto: item.producto,
			_descripcion: item.descripcion,
			_id_cat_grupo: item.id_cat_grupo,
			//_id_cat_categoria: item.id_cat_categoria,
			_cantidad: 1,
			_precio_unitario: item.precio_unitario,
		});
		CatService.ListTipoRequerimiento(item.id_cat_grupo).then((response) => {
			this.setState({
				cat_tipo_requerimiento: response.data
			});
		});
	};
	
	onKeyUp = (event) => {
		if (event.charCode === 13) {
			let cat_grupo = FieldsJs.Copy(this.state.cat_grupo);
			for (let i = 0; i < cat_grupo.length; i++) {
				if (cat_grupo[i].clave === this.state._codigo_producto) {
					this.setState({
						// _codigo_producto: cat_grupo[i].clave,
						_id_cat_grupo: cat_grupo[i].id_cat_grupo,
						_cantidad: 1,
						_precio_unitario: cat_grupo[i].precio
					});
				}
			}
		}
	};
	
	onKeyUpProducto = (_id_cat_grupo) => {
		let cat_grupo = FieldsJs.Copy(this.state.cat_grupo);
		for (let i = 0; i < cat_grupo.length; i++) {
			if (Number(cat_grupo[i].id_cat_grupo) === Number(_id_cat_grupo)) {
				this.setState({
					_codigo_producto: cat_grupo[i].clave,
					// _id_cat_grupo: cat_grupo[i].id_cat_grupo,
					_cantidad: 1,
					_precio_unitario: cat_grupo[i].precio
				});
			}
		}
		
	};
	
	addPlan = (select_plan) => {
		
		try {
			/*if (!select_plan.fecha_inicio) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Fecha Inicio del Plan."
				});
			}
			if (!select_plan.id_cat_plan) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Plan."
				});
			}
			if (!select_plan.plan_select.precio) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Precio unitario."
				});
			}*/
			
			let product = FieldsJs.Copy(this.state.productos);
			let costo_adicional = 0;
			
			product.push({
				fecha_solicitud: null,
				fecha_inicio: select_plan.fecha_inicio,
				fecha_fin: select_plan.fecha_fin,
				venta_producto: select_plan.nombre,
				venta_descripcion: "",
				id_cat_grupo: "",
				id_cat_plan: select_plan.id_cat_plan,
				venta_cantidad: 1,
				venta_precio_unitario: select_plan.precio,
				venta_costo_adicional: costo_adicional,
				venta_precio: (1 * select_plan.precio) + Number(costo_adicional),
				venta_porcentaje_descuento: 0,
				venta_descuento: 0,
				venta_producto_tipo_requerimiento: [],
				venta_producto_requerimiento_adicional: [],
				is_plan: true
			});
			
			this.setState({
				productos: product
			}, () => {
				console.log("SELECTPLAN:::: ", select_plan, this.state.productos);
			});
			
			setTimeout(() => {
				this.updatePrecio();
			}, 800);
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
		
		
	};
	
	render() {
		
		return (
			<div className='OrdenDeVenta ContaineViewComponet padding-0'>
				
				<Fragment>
					
					<ModalPagarOrdenDeVenta
						modal={this.state.modalpagos} form={this.state.formpagos} Pagar={this.Pagar}
						closeModal={this.closeModal} handleChangePago={this.handleChangePago}
						handleChangePagoIdCatFormaPago={this.handleChangePagoIdCatFormaPago}
						pagos={{
							ws_lista_pagos: this.state.ws_lista_pagos,
							ws_por_pagar: this.state.ws_por_pagar,
							ws_total_pagado: this.state.ws_total_pagado,
							ws_total_venta: this.state.ws_total_venta,
							ws_descuento_venta: this.state.ws_descuento_venta,
							ws_anticipo_completo: this.state.ws_anticipo_completo || false,
							ws_numero_pedido: this.state.ws_numero_pedido || '',
						}}
						VerExportarOrden={this.VerExportarOrden}
						showSnackBars={this.showSnackBars}
						productos_anticipo={this.state.productos_anticipo}
						handleChange={this.handleChange}
						toggle_linea_produccion={this.toggle_linea_produccion}
						
						id_venta_pagar_exportar={this.state.id_venta_pagar_exportar}
					/>
				
				</Fragment>
				
				<Fragment>
				
				
				</Fragment>
				
				<Fragment>
					
					<ModalAccionPDF
						open={this.state.open}
						closeModal={this.closeModal}
						closeModalPedido={this.closeModalPedido}
						showSnackBars={this.showSnackBars}
						id_venta={this.state.id_venta_pagar_exportar}
						cliente={this.state.cliente_pagar_exportar}
					/>
				
				</Fragment>
				
				<div className={'w-100'} align={'center'}>
					<section style={{maxWidth: '1400px', width: '100%'}}>
						<ContenidoOrdenVenta
							RefreshListCliente={this.RefreshListCliente}
							handleChange={this.handleChange}
							handleChangeRange={this.handleChangeRange}
							updatePrecio={this.updatePrecio}
							mas={this.mas}
							menos={this.menos}
							eliminar_requerimiento={this.eliminar_requerimiento}
							eliminar_producto_atributo={this.eliminar_producto_atributo}
							agregar_producto_atributo={this.agregar_producto_atributo}
							producto_requerimiento_adicional={this.state.producto_requerimiento_adicional}
							agregar_producto_requerimiento_adicional={this.agregar_producto_requerimiento_adicional}
							eliminar_producto_requerimiento_adicional={this.eliminar_producto_requerimiento_adicional}
							cat_tipo_requerimiento={this.state.cat_tipo_requerimiento}
							cat_costo_mensajeria={this.state.cat_costo_mensajeria}
							cat_como_te_enteraste={this.state.cat_como_te_enteraste}
							cat_estado={this.state.cat_estado}
							cat_municipio={this.state.cat_municipio}
							cat_grupo={this.state.cat_grupo}
							cat_planes={this.state.cat_planes}
							cat_productos={this.state.cat_productos}
							productos={this.state.productos}
							form={this.state}
							editar={this.props.params.editar === 'editar'}
							id_venta={this.state.id_venta}
							suggestions={this.state.clientes_datos_suggestions}
							handleChangeSingle={this.handleChangeSingle}
							onKeyUp={this.onKeyUp}
							onKeyUpProducto={this.onKeyUpProducto}
							selectCodigo={this.selectCodigo}
							OrdenDeVentaContinuar={this.OrdenDeVentaContinuar}
							OrdenDeVentaGuardar={this.OrdenDeVentaGuardar}
							OrdenDeVentaActualizar={this.OrdenDeVentaActualizar}
							updateDesglose={this.updateDesglose}
							showSnackBars={this.showSnackBars}
							addPlan={this.addPlan}
							selectProducto={this.selectProducto}
							OrdenDeVentaContinuarBoton={this.OrdenDeVentaContinuarBoton}
							cat_categorias={this.state.cat_categorias}
							DefaultNumPag={this.DefaultNumPag}
							AplicarPaginacion={this.AplicarPaginacion}
							paginacion={this.state.paginacion}
						/>
					</section>
				</div>
			</div>
		);
	}
}

OrdenDeVenta.propTypes = {
	params: PropTypes.object.isRequired,
};

export default IntegrationNotistack(OrdenDeVenta);
