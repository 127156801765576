import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodoPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodo_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoSangre: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_sangre').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroCliente: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_cliente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioEjecutivo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_ejecutivo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioPromotor: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_promotor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClienteInternaPlazaDireccion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cliente_interna_plaza_direccion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioAcreditado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_acreditado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComprobanteIngreso: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/comprobante_ingreso').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListIdentificacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/identificacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListArchivoIntegracionExpediente: (form) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/archivo_integracion_expediente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCategoria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/categoria', {}, {spinner: spinner || false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComoTeEnteraste: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/como_te_enteraste').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProveedor: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proveedor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPago: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProductos: (paginacion) => {
		let params = {
			filtro: {
				clave: '',
				producto: '',
				activo: '1'
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEnvioEstatus: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/envio_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoMensajeria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_mensajeria').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListBolsas: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/bolsas').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListDomicilios: (id_usuario) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/domicilios/'+id_usuario).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCostoMensajeria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/costo_mensajeria').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatVentaProceso: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/venta_status_proceso').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
