import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Carta from '../../../../assets/img/icons/lineas-de-texto.svg';
import Ticket from '../../../../assets/img/icons/lineas-de-texto.svg';


class ModalTipoTicket extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	verPDF = () => {
		this.props.exportar(this.props.item);
	}
	
	verPDFTicket = () => {
		this.props.exportarTicket(this.props.item);
	}
	
	render() {
		
		const bg_carta = {
			backgroundImage: `url(${Carta})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center'
		};
		
		const bg_ticket = {
			backgroundImage: `url(${Ticket})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center'
		};
		
		
		return (
			<div>
				
				{
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography align={'center'}>¿Cómo deseas ver el ticket?</Typography>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Card className={'cursor-pointer text-center'} onClick={() => this.verPDF()}>
									<div style={bg_carta} className={'h-70-px margin-15'} />
									<Typography className={'margin-10'}>
										Tamaño Carta
									</Typography>
								</Card>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Card className={'cursor-pointer text-center'} onClick={() => this.verPDFTicket()}>
									<div style={bg_ticket} className={'h-70-px margin-15'} />
									<Typography className={'margin-10'}>
										Ticket
									</Typography>
								</Card>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'margin-0'}></div>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
						</Fragment>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTipoTicket.propTypes = {
	componente: PropTypes.element,
	item: PropTypes.object.isRequired,
	exportar: PropTypes.object.isRequired,
	RefreshList: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalTipoTicket;
