import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {FieldsJs} from '../../../../settings/General/General';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {CONFIG} from '../../../../settings/Config/Config';
import disenador_vacio from "../../../../assets/img/icons/modal-lista-disenador-vacio.svg";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";


class ModalDisenador extends Component {
	
	
	render() {
		
		const src = CONFIG.src;
		
		const foto_default = CONFIG.foto_default;
		
		
		const bg_disenador_vacio = {
			backgroundImage: `url(${disenador_vacio})`
		};
		
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal(1)} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.props.modal.title}<br/>
						{!(this.props.form.id_venta_producto > 0) ? (
							<div className={'px-12 text-gray'}>
								Asignar los diseñadores a toda la orden de venta
							</div>
						) : ''}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{!(this.props.form.id_venta_producto > 0) ? (
								<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-20 text-center text-gray'}>
										Asignación general para la orden de venta
									</Typography>
								</Grid>
							) : ''}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-100-130-px'}>
										<TextField
											select
											fullWidth
											onChange={this.props.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_usuario"
											label="Diseñador"
											value={this.props.form.id_usuario}
										>
											<option value={''}>&nbsp;</option>
											{this.props.cat_usuario_disenador.map(option => (
												<option key={option.id_usuario} value={option.id_usuario}>
													{option.nombre} {option.apellido_paterno} {option.apellido_materno}
												</option>
											))}
										</TextField>
									</Grid>
									<Grid item className={'w-130-px'}>
										<Button onClick={this.props.add} color="default"
										        style={{border: "solid 1px gray"}}>
											Seleccionar
										</Button>
									</Grid>
								</Grid>
							</Grid>
							
							{this.props.form.venta_producto_disenador_asignado.length <= 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}
								      className={'padding-15'}>
									<div
										className={'bg-img-contain-x-center-y-center h-100-px w-100-px opacity-lista-vacia'}
										style={bg_disenador_vacio}/>
									<Typography className={'px-18 text-center text-gray'}>
										No se ha asignado<br/>uno o mas diseñadores
									</Typography>
								</Grid>
							) : ''}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<List className={''}>
									{this.props.form.venta_producto_disenador_asignado.map((item, key) => (
										<Fragment key={key}>
											<ListItem alignItems="flex-start" className={'bg-lightgray margin-10-B'}>
												<ListItemAvatar>
													<Avatar alt="Remy Sharp"
													        src={item.foto ? (src + item.foto) : foto_default}/>
												</ListItemAvatar>
												<ListItemText
													primary={item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno}
													secondary={
														<React.Fragment>
															<Typography
																component="span"
																variant="body2"
																style={{
																	display: 'inline',
																}}
																color="textPrimary"
															>{item.correo_electronico} </Typography>
															<Typography
																component="span"
																variant="body2"
																style={{
																	display: 'inline',
																}}
																color="textPrimary"
															> {item.telefono} {item.celular}</Typography>
														
														</React.Fragment>
													}
												/>
											</ListItem>
										</Fragment>
									))}
								</List>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.props.closeModal(1)} color="primary">
							Cerrar
						</Button>
						
						{FieldsJs.inArray([1, 2], this.props.form.id_cat_venta_producto_status_proceso) ? (
							<Button
								onClick={() => this.props.asignar(this.props.form.id_venta, this.props.form.id_venta_producto, this.props.form.venta_producto_disenador_asignado)}
								color="primary">
								{this.props.form.id_cat_venta_producto_status_proceso === 1 ? 'Asignar' : (this.props.form.id_cat_venta_producto_status_proceso === 2 ? 'Reasignar diseñador' : '')}
							</Button>
						) : (
							<Fragment>
								{!(this.props.form.id_venta_producto > 0) ? (
									<Button
										onClick={() => this.props.asignar(this.props.form.id_venta, null, this.props.form.venta_producto_disenador_asignado)}
										color="primary">
										Asignar
									</Button>
								) : ''}
							</Fragment>
						)}
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalDisenador.propTypes = {
	add: PropTypes.func.isRequired,
	asignar: PropTypes.func.isRequired,
	cat_usuario_disenador: PropTypes.array.isRequired,
	form: PropTypes.object.isRequired,
};


export default ModalDisenador;
