import Pusher from 'pusher-js';

import {CONFIG} from "../../settings/Config/Config";

Pusher.logToConsole = CONFIG.pusher_app_log_to_console;

let pusher = new Pusher(CONFIG.pusher_app_key, {
	cluster: CONFIG.pusher_app_cluster,
	forceTLS: CONFIG.pusher_app_force_tls
});

export const PusherSubscribe = (channel, resolve) => {
	let data = pusher.subscribe(channel || 'my-channel-monitor');
	resolve(data);
};

export const PusherUnSubscribe = (channel, resolve) => {
	let data = pusher.unsubscribe(channel || 'my-channel-monitor');
	resolve(data);
};

const PusherWatchChannelEvent = (event, resolve) => {
	pusher.bind(event || 'my-event-monitor', function (data) {
		resolve(data);
	});
};

export default PusherWatchChannelEvent;
