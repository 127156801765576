import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ClienteService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				cliente: filtro.cliente || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Clientes_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form, cliente_como_te_enteraste) => {
		let ids_cliente_como_te_enteraste = [];
		for (let i = 0; i < cliente_como_te_enteraste.length; i++) {
			if (cliente_como_te_enteraste[i].checked) {
				ids_cliente_como_te_enteraste.push(cliente_como_te_enteraste[i].id_cat_como_te_enteraste);
			}
		}
		let params = {
			id_cliente: null,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			razon_social: form.razon_social,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			activo: form.activo ? 1 : 0,
			
			id_cliente_direccion: null,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			
			ids_cliente_como_te_enteraste: ids_cliente_como_te_enteraste
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Clientes_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form, cliente_como_te_enteraste) => {
		let ids_cliente_como_te_enteraste = [];
		for (let i = 0; i < cliente_como_te_enteraste.length; i++) {
			if (cliente_como_te_enteraste[i].checked) {
				ids_cliente_como_te_enteraste.push(cliente_como_te_enteraste[i].id_cat_como_te_enteraste);
			}
		}
		let params = {
			id_cliente: form.id_cliente,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			razon_social: form.razon_social,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			activo: form.activo ? 1 : 0,
			
			id_cliente_direccion: form.id_cliente_direccion,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			
			ids_cliente_como_te_enteraste: ids_cliente_como_te_enteraste
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Clientes_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Clientes_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
