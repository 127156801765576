/*

npm i -g npm-check-updates
ncu -u
npm install

*/

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

let a, s;
let pusher_app_key = '5c5a8cb3628ccf0ce916';

console.log("HOSTNAME::: ",hostname);

switch (hostname) {
	case 'localhost':
	case '127.0.0.1':
		a = 'http://localhost/api_zapateria/public/api/';
		s = 'http://localhost/api_zapateria/public/';
		pusher_app_key = '5c5a8cb3628ccf0ce916';
		break;
	case 'zapateriaselene.soltrab.com':
		a = 'https://api-selene.soltrab.com/public/api/';
		s = 'https://api-selene.soltrab.com/public/';
		pusher_app_key = '5c5a8cb3628ccf0ce916';
		break;
	case 'zapateriaselene.acrescotec.com':
		a = 'https://apizapateria.acrescotec.com/public/api/';
		s = 'https://apizapateria.acrescotec.com/public/';
		pusher_app_key = '5c5a8cb3628ccf0ce916';
		break;
	case 'sistema.zapateriaselene.com':
		a = 'https://api.zapateriaselene.com/public/api/';
		s = 'https://api.zapateriaselene.com/public/';
		pusher_app_key = '5c5a8cb3628ccf0ce916';
		break;
	default:
		a = protocol + '//api' + hostname + '/public/api/';
		s = protocol + '//api' + hostname + '/public/';
		pusher_app_key = '5c5a8cb3628ccf0ce916';
}

const api = a;
const src = s;

const basename = hostname === 'localhost' ? (Number(port) === 3000 || Number(port) === 3001 || Number(port) === 3002 || Number(port) === 3003 ? '' : '/zapateria/build/') : '/';

export const CONFIG = {
	prefix: 'zapateria',
	id_project: 2,
	api: api,
	src: src,
	basename: basename,
	foto_default: src + 'file/perfil/default-perfil.svg',
	portada_default: src + 'file/portada/default-portada.svg',
	menu: 1,
	debug: true,
	pusher_app_key: pusher_app_key,
	pusher_app_cluster: 'us2',
	pusher_app_force_tls: true,
	pusher_app_log_to_console: true,
	segundo_confirmar_token_expirado: 30,
	tipo_menu_contextual: 2,
	onesignal_hostname_prod: 'expediente.operadoraprocea.com',
	onesignal_app_id_prod: '6b907ea6-0d3a-4d23-8cb5-6b2c5b94ba8e',
	onesignal_app_id_test: '5e077659-5d4d-4420-9e62-f4a96330c4d6',
	titulo_alert_confirm: 'Zapatería Selene',
	
	
	DROPBOX_TOKEN: "cl1NIyf_FpoAAAAAAAAAAc7UahGzuEsbR8JCVYS1WRVsjgGOxZBwPDSsUxcMihol",
	DROPBOX_API: "https://api.dropboxapi.com/2/files/",
	DROPBOX_CONTENT: "https://content.dropboxapi.com/2/files/",
};
