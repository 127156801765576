import React, {Component} from 'react';

import Page404 from './Page404';

import './Page404.css';

class Page404Index extends Component {
	render() {
		return (
			<div className='Page404Index'>
				
				<Page404/>
			
			</div>
		);
	}
}

export default Page404Index;
