import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SexoService} from "../../../../services/_Cat/SexoService/SexoService";
import {
	BotonAccionAbrirModal,
} from "../../../Include/MiniComponents/GlobalComponent";
import SVGImprimir from "../../../../assets/img/icons/orden-venta-imprimir.svg";
import Typography from "@material-ui/core/Typography";
import SVGVer from "../../../../assets/img/icons/orden-venta-ver.svg";
import SVGDescargar from "../../../../assets/img/icons/orden-venta-descargar.svg";
import Fab from "@material-ui/core/Fab";
import {Send} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

class ModalAccionPdfVentas extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			correo_electronico: ""
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	exportar = (tipo, sendmail) => {
		let cliente = this.props.cliente || {};
		let correo_electronico = this.state.correo_electronico || cliente.correo_electronico;
		let params = {
			id_venta: this.props.id_venta,
			tipo: tipo,
			sendmail: sendmail ? 1 : 0,
			correo_electronico: correo_electronico,
		};
		if (sendmail) {
			HttpRequest.export('vxid', ['orden', 'venta'], params, !(tipo === 3), false, false, false, (response) => {
				this.props.showSnackBars('success', response.message);
				this.setState({
					correo_electronico: "",
				});
			}, (error) => {
				this.props.showSnackBars('error', error.message);
			});
		} else {
			HttpRequest.export('vxid', ['orden', 'venta'], params, !(tipo === 3))
		}
	};

	
	render() {
		
		console.log(this.props.cliente);
		let cliente = this.props.cliente || {};
		let correo_electronico = this.state.correo_electronico || cliente.correo_electronico;
		const estilo = {
			height: "150px",
			width: "150px",
			borderRadius: "100%",
			cursor: "pointer",
			marginBottom: "15px",
			marginTop: "15px",
		};
		const configuracion = {
			imprimir: false,
			ver: true,
			descargar: false,
			sendmail: true,
		};
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => this.open()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
						
						{/*<DialogTitle>*/}
						{/*	/!*{'Orden de venta generada con exito'}*!/*/}
						{/*</DialogTitle>*/}
						
						<DialogContent>
							
							{/*<DialogContentText>*/}
							{/*</DialogContentText>*/}
							
							<div className={'padding-40'}>
								<Grid container spacing={5}>
									
									{configuracion.imprimir ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<img
												src={SVGImprimir}
												alt=""
												style={estilo}
												onClick={() => this.exportar(1, false)}
											/>
											<Typography variant="h6" className={'l-h-20 px-16'}>
												Imprimir orden de venta
											</Typography>
										</Grid>
									) : null}
									
									{configuracion.ver ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<img
												src={SVGVer}
												alt=""
												style={estilo}
												onClick={() => this.exportar(2, false)}
											/>
											<Typography variant="h6" className={'l-h-20 px-16'}>
												Ver e imprimir
											</Typography>
										</Grid>
									) : null}
									
									{configuracion.descargar ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<img
												src={SVGDescargar}
												alt=""
												style={estilo}
												onClick={() => this.exportar(3, false)}
											/>
											<Typography variant="h6" className={'l-h-20 px-16'}>
												Descargar
											</Typography>
										</Grid>
									) : null}
									
									{configuracion.sendmail ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Typography variant="h5" align={'center'}>
														{this.props.cliente.razon_social || this.props.cliente.nombre_completo || "No especificado..."}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={2} md={2} lg={2} xl={2}/>
												<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
													<TextField
														fullWidth
														required
														label={"Correo electrónico"}
														name={"correo_electronico"}
														value={correo_electronico}
														margin={"none"}
														onChange={this.handleChange}
													/>
												</Grid>
												<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
													<Fab variant="extended" size="small" color="primary"
													     aria-label="Enviar cotización" onClick={() => this.exportar(2, true)}>
														<Send className={'margin-5-R px-14'}/> enviar
													</Fab>
												</Grid>
												<Grid item xs={12} sm={2} md={2} lg={2} xl={2}/>
											</Grid>
										</Grid>
									) : null}
								
								</Grid>
							</div>
						
						</DialogContent>
						
						<DialogActions>
							<Grid container spacing={0}>
								<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<Button onClick={() => this.close()} color="primary">Cerrar</Button>
								</Grid>
							</Grid>
						</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAccionPdfVentas.propTypes = {
	componente: PropTypes.element,
	open: PropTypes.string.isRequired,
	id_venta: PropTypes.object.isRequired,
	cliente: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAccionPdfVentas;
