import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";
import ModalComentario from "../../Comentario/Includes/ModalComentario";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {Sms} from '@material-ui/icons';
import {FieldsJs} from "../../../../settings/General/General";

class Monitor extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Grid container spacing={4}>
				
				
				{lista.map((item, key) => (
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							
							</Grid>
							
							
						</Grid>
					</Grid>
				))}
			</Grid>
		)
	}
}

Monitor.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default Monitor;
