import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CategoriaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Categoria_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_categoria: null,
			clave: form.clave,
			categoria: form.categoria,
			descripcion: form.descripcion,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Categoria_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_categoria: form.id_cat_categoria,
			clave: form.clave,
			categoria: form.categoria,
			descripcion: form.descripcion,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Categoria_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_categoria) => {
		let params = {
			id_cat_categoria: id_cat_categoria
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Categoria_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
