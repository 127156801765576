import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Checkbox from "@material-ui/core/Checkbox/index";
import FormGroup from "@material-ui/core/FormGroup/index";
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import BoxSelectFile from "../../../../Include/MiniComponents/BoxSelectFile";
import Grid from "@material-ui/core/Grid";
import {EnteroSolo} from "../../../../../settings/General/General";

class ModalCostoEnvio extends Component {
	
	render() {
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.props.modal.title} Costo de Envió
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="none"
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									required
									helperText="Requerido"
									name="id_cat_tipo_mensajeria"
									label="Tipo Mensajería"
									value={this.props.form.id_cat_tipo_mensajeria}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.props.form.lista_cat_tipo_mensajeria.map(option => (
										<option key={option.id_cat_tipo_mensajeria} value={option.id_cat_tipo_mensajeria}>
											{option.tipo_mensajeria}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									fullWidth
									required
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
									type="text"
									margin="none"
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									name="cantidad_pares_inicio"
									label="Cantidad Inicio"
									value={this.props.form.cantidad_pares_inicio}
								/>
							</Grid>
					
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									fullWidth
									required
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
									type="text"
									margin="none"
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									name="cantidad_pares_fin"
									label="Cantidad Fin"
									value={this.props.form.cantidad_pares_fin}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									required
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
									type="text"
									margin="none"
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									name="costo"
									label="Costo"
									value={this.props.form.costo}
								/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.props.closeModal()} color="primary">
							{this.props.modal.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.props.modal.tipo !== 'view' ? (
							<Button onClick={() => this.props.save()} color="primary">
								{this.props.form.id_cat_categoria > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

export default ModalCostoEnvio;
