import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {CadenaDomicilio, EnteroSolo, FieldsJs, FloatSolo, MinMax} from '../../../../settings/General/General';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import add_cliente_venta from '../../../../assets/img/icons/add-cliente-venta.svg';
import edit_cliente_venta from '../../../../assets/img/icons/edit-cliente-venta.svg';
import view_cliente_venta from '../../../../assets/img/icons/view-cliente-venta.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/agregar-producto-atributo.svg';
import carnet_identificacion from '../../../../assets/img/icons/carnet-de-identidad.svg';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import NativeSelect from "@material-ui/core/NativeSelect";
import moment from "moment";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {Badge, CardActionArea, CardActions, CardMedia} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Paginacion from "../../../Include/Paginacion/Paginacion";
import ModalProductosPreOrden from "./ModalProductosPreOrden";
import {Alert} from "@material-ui/lab";


const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ContenidoOrdenVenta extends Component {
	
	
	obtenerViernes = () => {
		const nombreDelDiaSegunFecha = fecha => [
			'domingo',
			'lunes',
			'martes',
			'miércoles',
			'jueves',
			'viernes',
			'sábado',
			'domingo',
		][new Date(fecha).getDay()];
		
		function sumarDias(fecha, dias) {
			fecha.setDate(fecha.getDate() + dias);
			dia = nombreDelDiaSegunFecha(fecha);
			//fecha = new Date(fecha);
			fecha = moment(fecha).format('DD-MM-yy');
			fecha = DateFormat.getFechaTextEspacio(fecha);
			return fecha;
		}
		
		var dia = nombreDelDiaSegunFecha(new Date());
		
		switch (dia) {
			/*case "sábado":
				dia = sumarDias(new Date(), 13);
				break;
			case "domingo":
				dia = sumarDias(new Date(), 12);
				break;*/
			case "lunes":
				dia = sumarDias(new Date(), 11);
				break;
			case "martes":
				dia = sumarDias(new Date(), 10);
				break;
			case "miércoles":
				dia = sumarDias(new Date(), 9);
				break;
			case "jueves":
				dia = sumarDias(new Date(), 8);
				break;
			case "viernes":
				dia = sumarDias(new Date(), 7);
				break;
			case "sábado":
				dia = sumarDias(new Date(), 6);
				break;
			case "domingo":
				dia = sumarDias(new Date(), 5);
				break;
			default:
				dia = sumarDias(new Date(), 5);
				break;
		}
		
		
		return dia;
	}
	
	render() {
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = this.props.updateDesglose();
		
		const {classes} = this.props;
		
		let disabledInputVenta = !!this.props.id_venta;
		
		let disabledInputVentaEditar = !!this.props.id_venta && !this.props.editar;
		
		return (
			<Fragment>
				
				<section className={'contentenido-nueva-venta w-auto padding-20-L padding-20-R'}>
					
					<Grid container spacing={3} direction="row" justify="flex-end" alignItems="flex-end"
					      className={'margin-0-B margin-0-T'}>
						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={2} className={'h-100'}>
								{!disabledInputVentaEditar && !disabledInputVenta === false ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Alert severity="warning">Se encuentra en modo edición del pedido con id: 12</Alert>
									</Grid>
								) : null}
								
								{/*<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={'h-40-px'}>
									
									<RadioGroup row aria-label="position"
									            name="factura_cotizacion_nota_sencilla_interno"
									            value={this.props.form.factura_cotizacion_nota_sencilla_interno}
									            onChange={(e) => this.props.handleChange(e)}>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="factura"
											label="Factura"
											disabled={disabledInputVenta}
										/>
										
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="nota_sencilla"
											label="Nota sencilla"
											disabled={disabledInputVenta}
										/>
									
									</RadioGroup>
								
								</Grid>*/}
								
								<Grid item xs={12} sm={6} md={2} lg={2} xl={2} align={"right"}>
									<Button
										fullWidth
										variant="contained"
										onClick={() => {
											let smg = "";
											if (!disabledInputVentaEditar && !disabledInputVenta === false) {
												smg = "¿Estas seguro de salir de la modificación del pedido, se perderán los cambios realizados?";
											} else {
												if (!disabledInputVenta) {
													smg = "¿Estas seguro de salir de la configuración del pedido, se perderán los datos capturados?";
												} else {
													smg = "¿Deseas salir de la vista previa del pedido?";
												}
											}
											PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, smg).then((r) => {
												if (r.button === 'Aceptar') {
													window.history.back();
												}
											});
										}}
										style={{
											backgroundColor: "white",
											color: "black",
											textTransform: "none",
											padding: "7px 0px",
											fontSize: "13px",
										}}
									>
										{!disabledInputVentaEditar && !disabledInputVenta === false ? "Atras" : (!disabledInputVenta ? "Cancelar pedido" : "Regresar")}
									</Button>
								</Grid>
								
								<Fragment>
									
									<Grid item xs={12} sm={6} md={2} lg={2} xl={2} align={"right"}>
										<ModalProductosPreOrden
											componente={
												<Button
													fullWidth
													variant="contained"
													//onClick={this.props.OrdenDeVentaContinuar}
													style={{
														backgroundColor: "#595959",
														color: "white",
														textTransform: "none",
														padding: "4px 0px",
														fontSize: "16px",
													}}
												>
													{!disabledInputVentaEditar && !disabledInputVenta === false ? "Modificar pedido" : "Agregar al carrito"}
													
													{this.props.productos.length > 0 ? (
														<Badge badgeContent={this.props.productos.length} color="primary" style={{color: 'white', top: '-10px', left: '10px'}}/>
													) : null}
												
												</Button>
											}
											tipo={!disabledInputVentaEditar && !disabledInputVenta === false ? 'edit' : 'add'}
											item={this.props.form.form}
											productos={this.props.productos}
											cat_costo_mensajeria={this.props.cat_costo_mensajeria}
											menos={this.props.menos}
											showSnackBars={this.props.showSnackBars}
											OrdenDeVentaContinuar={this.props.OrdenDeVentaContinuar}
											OrdenDeVentaGuardar={this.props.OrdenDeVentaGuardar}
											handleChange={this.props.handleChange}
										/>
									</Grid>
								</Fragment>
								
								<Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
									<Typography className={'text-right px-16'}>
										{"El pedido se enviará el día viernes " + this.obtenerViernes()}
									</Typography>
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100-60-px'}>
									
									<div className={'h-100-2-px'}>
										<div className={'padding-0 h-100'}>
											<div className={'padding-0 h-100'}>
												<Grid container spacing={4} justify="flex-start" alignItems="flex-start">
													<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
														<Typography variant="h6" component="h6" align={'left'} style={{margin: '0px 0px 5px 0px'}}>
															PRODUCTOS
														</Typography>
													</Grid>
													{/*<Grid item xs={12} sm={6} md={6} lg={6} xl={6} align={'right'}>
														<ModalFiltros
															componente={(
																<div className={'vertical-inline text-right cursor-filtros'}>
																	<div className={'v-center'}>
																		<Typography variant="h6" component="h6" align={'right'} style={{margin: '0px 0px 5px 0px'}}>
																			FILTROS
																		</Typography>
																	</div>
																	<div className={'v-center'}>
																		<FilterList/>
																	</div>
																</div>
															)}
															showSnackBars={this.props.showSnackBars}
															handleChange={this.props.handleChange}
															RefreshList={this.props.RefreshList}
															cat_categorias={this.props.cat_categorias}
														/>
													</Grid>*/}
												</Grid>
												
												<Grid container spacing={4} justify="flex-start" alignItems="flex-start">
													{this.props.cat_productos.map((prod, key) => (
														<Fragment key={key}>
															{prod.categoria_nueva ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
																	<span className={'px-16'} style={{fontSize: 12, borderRadius: '6px', color: 'white', textAlign: 'center', margin: 0, padding: '2px 5px 2px 5px', background: '#1c9997'}}>
																		{prod.categoria}
																	</span>
																</Grid>
															) : null }
															
															<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
																<Card className={classes.root}>
																	<CardActionArea>
																		{/*<CardMedia className={classes.media} image={CONFIG.src + prod.imagen} title="Producto" />*/}
																		<Avatar
																			style={{
																				width: '100%',
																				height: 200
																			}}
																			alt={prod.imagen}
																			src={CONFIG.src + prod.imagen}
																			variant={'square'}
																		/>
																		<CardContent style={{padding: 10}}>
																			<Typography gutterBottom variant="h5" component="h2" style={{fontSize: 12, color: 'gray', textAlign: 'left', margin: 0, padding: 0}}>
																				{prod.codigo}
																			</Typography>
																			<Typography gutterBottom variant="h5" component="h2">
																				{prod.producto}
																			</Typography>
																			<Typography gutterBottom variant="h5" component="h2" style={{color: 'red'}}>
																				${FieldsJs.Currency(prod.precio)}
																			</Typography>
																			<Typography variant="body2" color="textSecondary" component="p">
																				{prod.descripcion}
																			</Typography>
																			<Grid container spacing={2} justify="flex-start" alignItems="flex-start">
																				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																					<NativeSelect
																						name={"color_pedido__" + key}
																						disabled={disabledInputVentaEditar}
																						value={prod.color_pedido}
																						onChange={(e) => {
																							this.props.handleChange(e, 'cat_productos', 'color_pedido');
																							this.props.updatePrecio();
																						}}
																						inputProps={{
																							'aria-label': 'talla',
																						}}
																						style={{width: '100%'}}
																					>
																						<option value="">Color</option>
																						{prod.colores.map((col, colkey) => (
																							<option key={colkey} value={col.color} children={col.color}/>
																						))
																						}
																					</NativeSelect>
																				</Grid>
																				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
																					<NativeSelect
																						name={"talla_pedido__" + key}
																						disabled={disabledInputVentaEditar}
																						value={prod.talla_pedido}
																						onChange={(e) => {
																							this.props.handleChange(e, 'cat_productos', 'talla_pedido');
																							this.props.updatePrecio();
																						}}
																						inputProps={{
																							'aria-label': 'talla',
																						}}
																						style={{width: '100%'}}
																					>
																						<option value="">Talla</option>
																						{prod.tallas.map((tal, talkey) => (
																							<option key={talkey} value={tal.talla} children={tal.talla}/>
																						))
																						}
																					</NativeSelect>
																				</Grid>
																				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
																					<TextField
																						fullWidth
																						type="number"
																						margin="none"
																						className={classes.root}
																						inputProps={{
																							className: classes.input,
																							placeholder: "Cantidad"
																						}}
																						onKeyPress={EnteroSolo}
																						name={'cantidad_pedido__' + key}
																						onChange={(e) => {
																							this.props.handleChange(e, 'cat_productos', 'cantidad_pedido');
																							this.props.updatePrecio();
																						}}
																						value={prod.cantidad_pedido}
																						disabled={disabledInputVentaEditar}
																					/>
																				</Grid>
																			
																			</Grid>
																		</CardContent>
																	</CardActionArea>
																	<CardActions>
																		<Button fullWidth variant="contained" color="primary" onClick={() => this.props.mas(prod)}
																		        className={'btn-default-primary text-transform-none'}>
																			Agregar
																		</Button>
																	</CardActions>
																</Card>
															</Grid>
														</Fragment>
													))}
													<Paginacion
														total={this.props.paginacion.total}
														page={this.props.paginacion.page}
														limit={this.props.paginacion.limit}
														rangos={this.props.paginacion.rangos}
														onClick={(data) => this.props.AplicarPaginacion(data)}
													/>
												</Grid>
											</div>
										</div>
									</div>
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</Grid>
				
				</section>
			
			</Fragment>
		);
	}
}

ContenidoOrdenVenta.propTypes = {
	classes: PropTypes.object.isRequired,
	RefreshListCliente: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	handleChangeRange: PropTypes.func.isRequired,
	updatePrecio: PropTypes.func.isRequired,
	
	mas: PropTypes.func.isRequired,
	menos: PropTypes.func.isRequired,
	
	eliminar_requerimiento: PropTypes.func.isRequired,
	eliminar_producto_atributo: PropTypes.func.isRequired,
	agregar_producto_atributo: PropTypes.func.isRequired,
	
	producto_requerimiento_adicional: PropTypes.array.isRequired,
	
	agregar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	eliminar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	
	cat_como_te_enteraste: PropTypes.array.isRequired,
	cat_tipo_requerimiento: PropTypes.array.isRequired,
	cat_productos: PropTypes.array.isRequired,
	cat_costo_mensajeria: PropTypes.array.isRequired,
	productos: PropTypes.array.isRequired,
	cat_estado: PropTypes.array.isRequired,
	cat_municipio: PropTypes.array.isRequired,
	cat_grupo: PropTypes.array.isRequired,
	cat_planes: PropTypes.array.isRequired,
	//fecha_solicitud: PropTypes.array.isRequired,
	suggestions: PropTypes.array.isRequired,
	handleChangeSingle: PropTypes.func.isRequired,
	updateDesglose: PropTypes.func.isRequired,
	
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	OrdenDeVentaGuardar: PropTypes.func.isRequired,
	OrdenDeVentaActualizar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	selectProducto: PropTypes.func.isRequired,
	onKeyUp: PropTypes.func.isRequired,
	onKeyUpProducto: PropTypes.func.isRequired,
	addPlan: PropTypes.func.isRequired,
	cat_categorias: PropTypes.array,
};

export default withStyles(styles)(ContenidoOrdenVenta);
