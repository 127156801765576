import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const AcreditadosService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				correo_electronico: filtro.correo_electronico || null,
				celular: filtro.celular || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acreditados_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_acreditado: form.id_acreditado || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			celular: form.celular || '',
			correo_electronico: form.correo_electronico || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1:2,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acreditados_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_acreditado: form.id_acreditado || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			celular: form.celular || '',
			correo_electronico: form.correo_electronico || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1:2,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acreditados_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_acreditado) => {let params = {
			id_acreditado: id_acreditado
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acreditados_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
