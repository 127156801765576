import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import AutoCompleteCliente from "../../OrdenDeVenta/Includes/AutoCompleteCliente";
import {FieldsJs} from "../../../../settings/General/General";
import {ClienteService} from "../../../../services/_Sis/ClienteService/ClienteService";
import add_cliente_venta from "../../../../assets/img/icons/add-cliente-venta.svg";
import edit_cliente_venta from "../../../../assets/img/icons/edit-cliente-venta.svg";
import view_cliente_venta from "../../../../assets/img/icons/view-cliente-venta.svg";
import {OrdenDeVentaService} from "../../../../services/_Sis/OrdenDeVentaService/OrdenDeVentaService";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import ModalCliente from "../../Cliente/Includes/ModalCliente";

const formulario = {
	id_cliente: null,
	id_cat_tipo_persona: 1,
	tipo_persona: 'fisica',
	razon_social: '',
	nombre: '',
	apellido_paterno: '',
	apellido_materno: '',
	correo_electronico: '',
	telefono: '',
	celular: '',
	rfc: '',
	activo: true,
	
	id_cliente_direccion: null,
	calle: '',
	numero_exterior: '',
	numero_interior: '',
	codigo_postal: '',
	colonia: '',
	id_cat_estado: '',
	id_cat_municipio: '',
	estado: '',
	municipio: '',
	
	ids_cliente_como_te_enteraste: [],
};

class ModalSeleccionarCliente extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			modalcliente: {
				open: false
			},
			form: {
				id_cliente: props.item.id_cliente,
				id_cat_tipo_persona: 1,
				tipo_persona: 'fisica',
				razon_social: '',
			},
			form_seleccionado: {},
			clientes_datos_suggestions: [],
		};
	}
	
	openModal = () => {
		
		console.log(this.props.item);
		
		this.setState({
			modal: {
				open: true
			},
			form: {
				id_cliente: this.props.item.id_cliente
			},
			form_seleccionado: {
				id_cliente: this.props.item.id_cliente
			},
		});
		
		setTimeout(() => {
			this.ListarDeCliente();
		});
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	
	handleChangeSingle = (e, name) => {
		console.log(name, e);
		
		let form = FieldsJs.Copy(formulario);
		
		for (let i = 0; i < this.state.clientes_datos_suggestions.length; i++) {
			if (this.state.clientes_datos_suggestions[i].id_cliente === e.value) {
				for (let key in formulario) {
					form[key] = this.state.clientes_datos_suggestions[i][key];
				}
			}
		}
		
		this.setState({
			[name]: e.value,
			form: FieldsJs.Copy(form),
			form_seleccionado: FieldsJs.Copy(form)
		});
	};
	
	
	ListarDeCliente = () => {
		ClienteService.Listar({activo: 1}, {}).then((response) => {
			
			let clt = response.data;
			
			let form = this.state.form;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form.id_cliente)) {
					form = clt[i];
				}
			}
			
			let form_seleccionado = this.state.form_seleccionado;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form.id_cliente)) {
					form_seleccionado = clt[i];
				}
			}
			
			this.setState({
				clientes_datos_suggestions: clt,
				form: form,
				form_seleccionado: form_seleccionado,
			});
		});
	};
	
	update_campos_tipo_persona = () => {
		if (this.state.form.tipo_persona === "moral") {
			let form = this.state.form;
			form.id_cat_tipo_persona = 2;
			form.nombre = '';
			form.apellido_paterno = '';
			form.apellido_materno = '';
			this.setState({
				form: form,
				form_seleccionado: form
			});
		}
		
		if (this.state.form.tipo_persona === "fisica") {
			let form = this.state.form;
			form.id_cat_tipo_persona = 1;
			form.razon_social = '';
			this.setState({
				form: form,
				form_seleccionado: form
			})
		}
	};
	
	RefreshListCliente = (id_cliente) => {
		if (id_cliente > 0) {
			this.update_campos_tipo_persona();
			setTimeout(() => {
				let form = FieldsJs.Copy(this.state.form);
				form.id_cliente = id_cliente;
				this.setState({
					id_cliente: form.id_cliente,
					form: FieldsJs.Copy(form),
					form_seleccionado: FieldsJs.Copy(form),
				});
				console.log('111111', form);
				this.ListarDeCliente();
			});
		} else {
			this.update_campos_tipo_persona();
			setTimeout(() => {
				let form = FieldsJs.Copy(this.state.form);
				this.setState({
					id_cliente: this.state.form.id_cliente,
					form: FieldsJs.Copy(form),
					form_seleccionado: FieldsJs.Copy(form),
				});
				console.log('000000', form);
				this.ListarDeCliente();
			});
		}
	};
	
	AsignarCliente = (item, id_venta) => {
		
		console.log(id_venta, item.id_cliente);
		
		try {
			if (!(item.id_cliente > 1)) {
				throw Object({
					status: false,
					mensaje: "Selecciona un cliente para realizar esta operación"
				});
			}
			OrdenDeVentaService.AsignarCliente(id_venta, item.id_cliente).then((response) => {
				this.closeModal(1);
				this.props.watchAsignarCliente();
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: this.state.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: this.state.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Asignar cliente
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} className={'h-400-px'}>
								
								<div className={'row-flex'}>
									<div className="w-100-120-px">
										<AutoCompleteCliente
											suggestions={this.state.clientes_datos_suggestions}
											value={this.state.form.id_cliente}
											name={'id_cliente'} id={'id_cliente'}
											label={'nombre_completo'}
											handleChangeSingle={this.handleChangeSingle}
										/>
									</div>
									<div className="w-40-px vertical-inline">
										<div className={'w-100 v-end'} align={'right'}>
											<ModalCliente
												tipo={'view'}
												item={this.state.form_seleccionado}
												RefreshList={() => this.RefreshListCliente(null)}
												showSnackBars={this.props.showSnackBars}
												componente={(
													<div
														className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
														style={btn_view}
													/>
												)}
											/>
										</div>
									</div>
									<div className="w-40-px vertical-inline">
										<div className={'w-100 v-end'} align={'right'}>
											<ModalCliente
												tipo={'edit'}
												item={this.state.form_seleccionado}
												RefreshList={() => this.RefreshListCliente(null)}
												showSnackBars={this.props.showSnackBars}
												componente={(
													<div
														className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
														style={btn_edit}
													/>
												)}
											/>
										</div>
									</div>
									<div className="w-40-px vertical-inline">
										<div className={'w-100 v-end'} align={'right'}>
											<ModalCliente
												tipo={'add'}
												item={{}}
												RefreshList={(id_cliente) => this.RefreshListCliente(id_cliente)}
												showSnackBars={this.props.showSnackBars}
												componente={(
													<div
														className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
														style={btn_add}
													/>
												)}
											/>
										</div>
									</div>
								</div>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Grid container spacing={0} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<Button onClick={() => this.closeModal(1)} color="primary">
									Cerrar
								</Button>
								
								<div className={'w-30-px h-10-px'} style={{display: "inline-block"}}/>
								
								<Button onClick={() => this.AsignarCliente(this.state.form, this.props.id_venta)}
								        color="primary">
									Aceptar
								</Button>
							
							</Grid>
						
						</Grid>
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalSeleccionarCliente.propTypes = {
	id_venta: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	watchAsignarCliente: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSeleccionarCliente;
