import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalProducto from './Includes/ModalProducto';

import {ProductoService} from '../../../services/_Sis/ProductoService/ProductoService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosProductos from "./Includes/FiltrosProductos";
import ModalCatalogo from "./Includes/ModalCatalogo";
import Grid from "@material-ui/core/Grid";


class Producto extends Component {
	
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			filtro: {
				clave: '',
				producto: '',
				activo: ''
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_producto: []
			
		}
		
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	Listar = () => {
		ProductoService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			this.setState({
				listar_producto: response.data,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_producto: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={12} sm={6} md={6} lg={7} xl={7} align={'left'}>
						<Typography variant={'h5'} className={'text-left'}>
							Producto
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
						<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
							<Grid item xs={12} sm={6} md={6} lg={5} xl={4}>
								{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
									<div className={'v-center'}>
										<ModalCatalogo
											tipo={'add'}
											item={{}}
											RefreshList={this.RefreshList}
											showSnackBars={this.showSnackBars}
										/>
									</div>
								) : ''}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
								{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
									<div className={'v-center'}>
										<Fab variant="extended" size="small" color="primary" aria-label="PDF"
										     className={'margin-10-L bg-danger'}>
											<PictureAsPdf className={'margin-5-R px-14'}/>
											PDF
										</Fab>
									</div>
								) : ''}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
								{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
									<div className={'v-center'}>
										<Fab variant="extended" size="small" color="primary" aria-label="Excel"
										     className={'margin-10-L bg-green'}>
											<EventNote className={'margin-5-R px-14'}/>
											Excel
										</Fab>
									</div>
								) : ''}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
								<div className={'v-center'}>
									<Fab variant="extended" size="small" color="primary" aria-label="Add"
									     className={'margin-10-L'} onClick={this.Listar}>
										<Sync className={'margin-5-R px-14'}/>
										Actualizar
									</Fab>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				
				
				<FiltrosProductos
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_producto.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_producto}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
					<ModalProducto
						id_producto={null}
						tipo={'add'}
						item={{}}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : ''}
			
			</div>
		);
	}
}

Producto.propTypes = {};

export default IntegrationNotistack(Producto);
