import React, {Component} from 'react';

import CambiarContrasena from './CambiarContrasena';

import './CambiarContrasena.css';

class CambiarContrasenaIndex extends Component {
	render() {
		return (
			<div className='CambiarContrasenaIndex'>
				
				<CambiarContrasena/>
			
			</div>
		);
	}
}

export default CambiarContrasenaIndex;
