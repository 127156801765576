import React, {Component, Fragment} from 'react';

import Header from '../../Include/Header/Header';
import Footer from '../../Include/Footer/Footer';

import OrdenDeVenta from './OrdenDeVenta';
import './OrdenDeVenta.css';

import {VerificarTokenAccess} from '../../../services/_Sis/VerificarTokenAccess/VerificarTokenAccess';
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TokenNoValido from "../../../componets/Include/TokenNoValido/TokenNoValido";
import {GetTipoMenu} from "../../../settings/General/General";

class OrdenDeVentaIndex extends Component {
	
	state = {
		access: false
	};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			access: null,
			params: props.params || {}
		};
		
		VerificarTokenAccess.Active().then((response) => {
			this.setState({
				access: 1
			});
		}).catch((error) => {
			this.setState({
				access: 0
			});
		});
	}
	
	render() {
		return (
			<div className='OrdenDeVentaIndex'>
				
				<Header/>
				
				<div className={`Container ${GetTipoMenu()}`}>
					
					{this.state.access === 1 ? (
						
						<Fragment>
							
							<OrdenDeVenta params={this.state.params}/>
							
							<Footer/>
						
						</Fragment>
					
					) : ''}
					
					{this.state.access === 0 ? (
						
						<Fragment>
							
							<TokenNoValido/>
						
						</Fragment>
					
					) : ''}
				
				</div>
			
			</div>
		);
	}
}

export class OrdenDeVentaIndex1 extends Component {
	render() {
		return (
			<Fragment>
				<OrdenDeVentaIndex params={this.props.match.params}/>
			</Fragment>
		);
	}
}

export class OrdenDeVentaIndex2 extends Component {
	render() {
		return (
			<Fragment>
				<OrdenDeVentaIndex params={this.props.match.params}/>
			</Fragment>
		);
	}
}

export class OrdenDeVentaIndex3 extends Component {
	render() {
		return (
			<Fragment>
				<OrdenDeVentaIndex params={this.props.match.params}/>
			</Fragment>
		);
	}
}

export class OrdenDeVentaIndex4 extends Component {
	render() {
		return (
			<Fragment>
				<OrdenDeVentaIndex params={this.props.match.params}/>
			</Fragment>
		);
	}
}
