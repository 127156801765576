import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";


class FormPagoEfectivo extends Component {
	
	cambio = () => {
		if (Number(this.props.form.monto) > Number(this.props.form.pago_con)) {
			return 0;
		} else {
			return this.props.form.pago_con - this.props.form.monto;
		}
	};
	
	handleChangeDropzoneArea = (file) => {
		this.setState({files:file}, () => {
			if (this.state.files.length > 0) {
				this.FileBase64(this.state.files[0]);
			}
		})
	};
	
	FileBase64 = (e, id) => {
		
		let fil = {files: [e]};
		console.log("FILEEE:::::",e);
		let formatos = [
			"image/jpeg",
			"image/png"
		];
		FileBase64.Base64(fil, formatos).then((response) => {
			this.props.handleChangePago({
				target: {
					name: 'imagen_base64',
					value: response.base64
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_base64Tipo',
					value: response.base64Tipo
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_archivo',
					value: response.archivo
				}
			});
			this.props.handleChangePago({
				target: {
					name: 'imagen_formato',
					value: response.formato
				}
			});
		}).catch((error) => {
			console.log("ERROR:::::",error);
			this.props.showSnackBars('error', error.mensaje);
			this.setState({
				imagen_base64: "",
				imagen_base64Tipo: "",
				imagen_archivo: "",
				imagen_formato: "",
			});
		});
	};
	
	render() {
		
		const cambio_total = this.cambio();
		
		return (
			<Fragment>
				<div className={'borde-punteado-gray-1 padding-15'}>
					<Grid container spacing={1}>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Monto
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="monto"
									           value={this.props.form.monto}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						{/*<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Con cuánto paga
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="pago_con"
									           value={this.props.form.pago_con}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={'px-18'}>
								Cambio
							</Typography>
							<Typography className={'px-26'}>
								$ {FieldsJs.Currency(cambio_total)}
							</Typography>
						</Grid>*/}
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<div className={'margin-10-B margin-10-T'}>
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={true}
									filesLimit={1}
									dropzoneText={'Arrastre y suelte una imagen del pago aquí o haga clic'}
									acceptedFiles={["image/png", "image/jpeg","image/*"]}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
								
								{/*<BoxSelectFile
									FormatAccepted={["image/png", "image/jpeg", "application/pdf"]}
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Comprobante de pago</b>
									)}
									style={{
										color: 'black',
										height: '115px',
									}}
									item={{
										base64: this.props.form.imagen_base64,
										base64Tipo: this.props.form.imagen_base64Tipo,
										archivo: this.props.form.imagen_archivo,
										formato: this.props.form.imagen_formato,
									}}
									onChange={(r) => {
										this.props.handleChangePago({
											target: {
												name: 'imagen_base64',
												value: r.base64
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_archivo',
												value: r.archivo
											}
										});
										this.props.handleChangePago({
											target: {
												name: 'imagen_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>*/}
							</div>
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Button variant="contained" color="primary" onClick={this.props.Pagar} className={'btn-default-primary'}>
								Realizar pago
							</Button>
						</Grid>
					
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

FormPagoEfectivo.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FormPagoEfectivo;
