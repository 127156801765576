import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import {VerifiedUser} from "@material-ui/icons"
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {ExpedienteService} from "../../../../services/_Sis/ExpedienteService/ExpedienteService";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import valido from '../../../../assets/img/icons/valido.svg';
import no_valido from '../../../../assets/img/icons/no_valido.svg';
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";

class ModalValidacion extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_expediente: (props.item || {}).id_expediente,
			id_acreditado: '',
			curp: '',
			rfc: '',
		};
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		try {
			
			if (!FieldsJs.Field(this.state.curp)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo CURP es requerido.",
				});
			}
			if (!FieldsJs.Field(this.state.rfc)) {
				throw Object({
					status: false,
					popup_alert: false,
					mensaje: "Campo RFC es requerido.",
				});
			}
			
			if (this.state.id_expediente > 0) {
				ExpedienteService.Modificar(this.state).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} else {
				ExpedienteService.Agregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList(response.data);
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		} catch (e) {
			if (!!e.popup_alert === true) {
			
			} else {
				this.props.showSnackBars('error', e.mensaje);
			}
		}
	};
	
	add = () => {
		try {
			const {item} = this.props;
			
			if (item.validated === 'curp') {
				
				
				if (!FieldsJs.Field(item.curp)) {
					throw Object({
						status: false,
						popup_alert: false,
						mensaje: "Campo CURP es requerido.",
					});
				}
				
				if (item.curp.length <= 17) {
					throw Object({
						status: false,
						popup_alert: false,
						mensaje: "CURP incompleto.",
					});
				}
				
				let msg = `¿Deseas validar la CURP: ${item.curp}?`;
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						ExpedienteService.Validacion(item).then((response) => {
							let data = response.data;
							this.setState({
								id_expediente: data.id_expediente || "",
								birthdate: data.birthdate || "",
								curp: data.curp || "",
								apellido_materno: data.mothers_maiden_name || "",
								nombre: data.names || "",
								nacionalidad: data.nationality || "",
								apellido_paterno: data.paternal_surname || "",
								aprobacion_documento: data.probation_document || "",
								aprobacion_documento_data: data.probation_document_data || {},
								valido: data.renapo_valid || false,
								sexo: data.sex || "",
								estatus: data.status_curp || "",
								activo: (data.activo === 1),
							});
							
							this.open();
							
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
						
					}
				});
				
				
				/*id_expediente: data.id_expediente || '',
					birthdate: data.birthdate || "16/08/1989",
					curp: data.curp || "MEAC890816HCSDRR00",
					apellido_materno: data.mothers_maiden_name || "ARIAS",
					nombre: data.names || "CARLOS ALBERTO",
					nacionalidad: data.nationality || "MEXICO",
					apellido_paterno: data.paternal_surname || "MEDINA",
					aprobacion_documento: data.probation_document || "ACTA DE NACIMIENTO",
					aprobacion_documento_data: data.probation_document_data || {
					"anioReg": "1989",
					"crip": "",
					"cveEntidadEmisora": "",
					"cveMunicipioReg": "101",
					"foja": "",
					"folioCarta": "",
					"libro": "",
					"numActa": "01581",
					"numEntidadReg": "07",
					"numRegExtranjeros": "",
					"tomo": ""
				},
					valido: data.renapo_valid || false,
					sexo: data.sex || "HOMBRE",
					estatus: data.status_curp || "RCN",
					activo: (data.activo === 1),*/
				
				console.log(this.props);
				
			} else {
				if (!FieldsJs.Field(item.rfc)) {
					throw Object({
						status: false,
						popup_alert: false,
						mensaje: "Campo RFC es requerido.",
					});
				}
				
				if (item.rfc.length <= 11) {
					throw Object({
						status: false,
						popup_alert: false,
						mensaje: "RFC incompleto.",
					});
				}
				
				let msg = `¿Deseas validar el RFC: ${item.rfc}?`;
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						ExpedienteService.Validacion(item).then((response) => {
							let data = response.data;
							this.setState({
								id_expediente: data.id_expediente || "",
								birthdate: data.birthdate || "",
								curp: data.curp || "",
								apellido_materno: data.mothers_maiden_name || "",
								nombre: data.names || "",
								nacionalidad: data.nationality || "",
								apellido_paterno: data.paternal_surname || "",
								aprobacion_documento: data.probation_document || "",
								aprobacion_documento_data: data.probation_document_data || {},
								valido: data.renapo_valid || false,
								sexo: data.sex || "",
								estatus: data.status_curp || "",
								activo: (data.activo === 1),
							});
							
							this.open();
							
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
						
					}
				});
				
			}
			
		} catch (e) {
			if (!!e.popup_alert === true) {
			
			} else {
				this.props.showSnackBars('error', e.mensaje);
			}
		}
		
		
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			default:
				funcion = () => {
				};
		}
		
		const disabledFieldPromotorIntegracionExpediente = this.state.disabledFieldPromotorIntegracionExpediente;
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_expediente}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography variant={'h5'} className={'text-center'}>
							{'CURP: ' + this.state.curp.toUpperCase()}
						</Typography>
					</DialogTitle>
					
					<DialogContent style={{cursor: 'move'}}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<div align={'center'} className={'w-100'}>
									<DivSvg img={this.state.valido ? valido : no_valido} height={150} width={150}/>
								</div>
							</Grid>
							{this.state.valido ? (
								<Fragment>
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Valido:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.valido ? 'Si' : 'No'}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Fecha de Nacimiento:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.birthdate}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Nombre(s):
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.nombre}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Apellido Paterno:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.nombre}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Nacionalidad:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.nacionalidad}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Documento de Aprobación:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.aprobacion_documento}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Sexo:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.sexo}</div>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										Estatus:
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<div style={{fontWeight: 'bold'}}>{this.state.estatus}</div>
									</Grid>
								
								</Fragment>
							) : (
								<Fragment>
									
									<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<div style={{fontWeight: 'bold'}}>La CURP ingresada no es válida.</div>
									</Grid>
								</Fragment>
							)}
						
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.closeModal()} color="primary">
							{'Cerrar'}
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalValidacion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalValidacion;
