import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {CadenaDomicilio, EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import styles from "react-date-range/dist/styles";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {EditOutlined, Add} from "@material-ui/icons";
import ModalCambiarDomicilio from "../../Perfil/Includes/ModalCambiarDomicilio";


class ModalProductosPreOrden extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_cat_tipo_mensajeria: '',
			lista_producto: [],
			lista_producto_original: [],
			mensajerias: [],
			bolsas: [],
			list_domicilios: [],
			costo_mensajeria: 0,
			costo_bolsa: 0,
			id_cat_bolsa: '',
			cantidad_bolsa: '',
			bolsas_seleccionadas: [],
			
			filtro: {
				clave: '',
				producto: '',
				activo: '',
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {item} = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, item);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			this.mensajerias().then((res) => {
				const {item} = this.props;
				this.obtenerCostoMensajeria();
				
				this.setState({
					id_cat_tipo_mensajeria: item.id_cat_tipo_mensajeria || '',
				});
			});
			
			this.domicilios().then((res) => {
				const {item} = this.props;

				this.setState({
					id_usuario_direccion: item.id_usuario_direccion || '',
				});
			});
			
			this.bolsas().then((res) => {
				const {item} = this.props;

				this.setState({
					id_cat_bolsa: item.id_cat_bolsa || '',
					cantidad_bolsa: item.cantidad_bolsa || '',
					bolsas_seleccionadas: item.bolsas_seleccionadas || []
				},() => {
					this.obtenerCostoBolsa();
				});
			});
			
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	mensajerias = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoMensajeria().then((response) => {
				this.setState({
					mensajerias: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					mensajerias: []
				});
				reject(error);
				
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	bolsas = () => {
		return new Promise((resolve, reject) => {
			CatService.ListBolsas().then((response) => {
				this.setState({
					bolsas: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					bolsas: []
				});
				reject(error);
				
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	domicilios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListDomicilios(this.Usr.id_usuario).then((response) => {
				this.setState({
					list_domicilios: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					list_domicilios: []
				});
				reject(error);
				
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	AplicarFiltro = () => {
		this.init();
	}
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	realizarPedido = () => {
		
		try {
			if (!FieldsJs.Field(this.state.id_cat_tipo_mensajeria)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona el tipo de mensajeria que deseas.")
				});
			}
			
			/*if (!FieldsJs.Field(this.state.id_usuario_direccion)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona la dirección.")
				});
			}*/
			
			this.props.OrdenDeVentaGuardar(this.state.id_cat_tipo_mensajeria, this.state.costo_mensajeria, this.state.id_usuario_direccion, this.state.bolsas_seleccionadas).then((response) => {
				this.close();
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
		
	}
	
	modificarPedido = () => {
		
		try {
			if (!FieldsJs.Field(this.state.id_cat_tipo_mensajeria)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona el tipo de mensajeria que deseas.")
				});
			}
			
			this.close();
			this.props.OrdenDeVentaGuardar(this.state.id_cat_tipo_mensajeria, this.state.costo_mensajeria, this.state.id_usuario_direccion, this.state.bolsas_seleccionadas);
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
		
	}
	
	obtenerTotal = () => {
		let productos = FieldsJs.Copy(this.props.productos);
		let total = 0;
		
		if (productos.length > 0) {
			for (let i = 0; i < productos.length; i++) {
				total = total + (productos[i].venta_cantidad * productos[i].venta_precio_unitario);
			}
		}
		
		total = total + Number(this.state.costo_mensajeria) + Number(this.state.costo_bolsa);
		
		return total;
	}
	
	obtenerSubTotal = () => {
		let productos = FieldsJs.Copy(this.props.productos);
		let total = 0;
		
		if (productos.length > 0) {
			for (let i = 0; i < productos.length; i++) {
				total = total + (productos[i].venta_cantidad * productos[i].venta_precio_unitario);
			}
		}
		
		total = total;
		
		return total;
	}
	
	obtenerTotalPares = () => {
		let productos = FieldsJs.Copy(this.props.productos);
		let total = 0;
		
		if (productos.length > 0) {
			for (let i = 0; i < productos.length; i++) {
				total = total + productos[i].venta_cantidad;
			}
		}
		
		return total;
	}
	
	obtenerCostoMensajeria = () => {
		let costo = 0;
		let cm = FieldsJs.Copy(this.props.cat_costo_mensajeria);
		let productos = FieldsJs.Copy(this.props.productos);
		let cantidad = 0;
		
		if (productos.length > 0) {
			for (let i = 0; i < productos.length; i++) {
				cantidad = cantidad + productos[i].venta_cantidad;
			}
		}
		
		if (cm.length > 0) {
			for (let i = 0; i < cm.length; i++) {
				if (Number(this.state.id_cat_tipo_mensajeria) === Number(cm[i].id_cat_tipo_mensajeria) && Number(cantidad) >= Number(cm[i].cantidad_pares_inicio) && Number(cantidad) <= Number(cm[i].cantidad_pares_fin)) {
					costo = cm[i].costo;
				}
			}
		}
		
		this.setState({
			costo_mensajeria: costo
		});
		
		return costo;
	}
	
	obtenerCostoBolsa = () => {
		let costo = 0;
		
		if(this.state.bolsas_seleccionadas.length > 0) {
			for (let i = 0; i < this.state.bolsas_seleccionadas.length; i++) {
				costo = costo + (Number(this.state.bolsas_seleccionadas[i].costo) * Number(this.state.bolsas_seleccionadas[i].cantidad_bolsa));
			}
		}
		
		this.setState({
			costo_bolsa: costo
		});
		
		return costo;
	}
	
	agregarBolsa = () => {
		
		try {
			if (!FieldsJs.Field(this.state.id_cat_bolsa)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona el tipo de bolsa.")
				});
			}
			if (!FieldsJs.Field(this.state.cantidad_bolsa)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona la cantidad de bolsa(s).")
				});
			}
			let band = false;
			let bolsa = '';
			let costo = 0;
			let bolsas = FieldsJs.Copy(this.state.bolsas);
			let bolsas_seleccionadas = FieldsJs.Copy(this.state.bolsas_seleccionadas);
			
			
			
			for (let i = 0; i < this.state.bolsas_seleccionadas.length; i++) {
				if (Number(this.state.bolsas_seleccionadas[i].id_cat_bolsa) === Number(this.state.id_cat_bolsa)) {
					band = true;
					this.state.bolsas_seleccionadas[i].cantidad_bolsa = Number(this.state.bolsas_seleccionadas[i].cantidad_bolsa) + Number(this.state.cantidad_bolsa);
				}
			}
			
			for (let i = 0; i < bolsas.length; i++) {
				if (Number(bolsas[i].id_cat_bolsa) === Number(this.state.id_cat_bolsa)) {
					bolsa = bolsas[i].bolsa + ' - $' + FieldsJs.Currency(bolsas[i].costo);
					costo = bolsas[i].costo;
				}
			}
			
			if (!band) {
				bolsas_seleccionadas.push({"id_cat_bolsa":this.state.id_cat_bolsa, "cantidad_bolsa": this.state.cantidad_bolsa, "costo": costo, "bolsa": bolsa});
				this.setState({bolsas_seleccionadas: bolsas_seleccionadas, id_cat_bolsa: '', cantidad_bolsa: ''},  () =>{
					this.obtenerCostoBolsa();
				});
			} else {
				this.setState({id_cat_bolsa: '', cantidad_bolsa: ''},  () =>{
					this.obtenerCostoBolsa();
				});
			}
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	}
	
	removeBolsa = (key) => {
		let bol = FieldsJs.Copy(this.state.bolsas_seleccionadas);
		bol.splice(key, 1);
		this.setState({bolsas_seleccionadas: bol});
	}
	
	render() {
		const is_root = this.is_root();
		let {item} = this.props;
		const {classes} = this.props;
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						<Grid container spacing={0} className={'cotizador_fondo'}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								Productos
								{this.props.productos.length > 0 ? (
									<Fragment>
										<Typography className={'px-13 text-left'} style={{fontWeight: 400}}>
											Tu pedido tiene un total de <o className={'px-18'} style={{color: '#3f51b5', fontWeight: 600}}>{this.obtenerTotalPares()}</o> pares.
										</Typography>
										<Typography className={'px-13 text-left'} style={{fontWeight: 400}}>
											El anticipio del 50% es de: <o className={'px-18'} style={{color: '#3f51b5', fontWeight: 600}}>${FieldsJs.Currency(Number(this.obtenerTotal())/2)}</o>.
										</Typography>
									</Fragment>
								) : null}
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'right'}>
								{this.props.productos.length > 0 ? (
									<Fragment>
										<div className={'px-12  text-right'}>
											SubTotal: <span className={'text-right'} style={{color: 'red'}}>${FieldsJs.Currency(this.obtenerSubTotal())}</span>
										</div>
										<div className={'px-12  text-right'}>
											Costo de envío: <span className={'text-right'} style={{color: 'red'}}>${FieldsJs.Currency(this.state.costo_mensajeria)}</span>
										</div>
										{FieldsJs.Field(this.state.costo_bolsa) ? (
											<div className={'px-12  text-right'}>
												Costo de bolsa(s): <span className={'text-right'} style={{color: 'red'}}>${FieldsJs.Currency(this.state.costo_bolsa)}</span>
											</div>
										): null}
										
										<div className={'text-right'}>
											Total: <span className={'px-25 text-right'} style={{color: 'red'}}>${FieldsJs.Currency(Number(this.obtenerTotal()))}</span>
										</div>
									</Fragment>
								) : null}
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.props.productos.length > 0 ? (
									<Fragment>
										<article className={'borde-punteado-lightgray-1 bg-lightgray-1 bg-white'} style={{
											overflowX: "auto"
										}}>
											
											<table width={'100%'}>
												<thead>
												<tr>
													<th className={'padding-10-T padding-10-B px-14'}>Eliminar</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Categoria</th>
													{/*<th className={'padding-10-T padding-10-B px-14 text-center'}>Código</th>*/}
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Modelo</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Descripción</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Color</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Talla</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Cantidad</th>
													<th className={'padding-10-T padding-10-B px-14 text-center'}>Precio</th>
												</tr>
												</thead>
												<tbody>
												{this.props.productos.map((item, key) => (
													<Fragment key={key}>
														<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
															<td className={'padding-4'} align={'center'} width={40}>
																<div className={'cursor-pointer'} style={{
																	padding: '6px',
																	background: 'red',
																	height: '6px',
																	width: '6px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '100%',
																	fontSize: '12px',
																}}
																     onClick={() => this.props.menos(item, key)}>x
																</div>
															</td>
															
															<td className={'padding-4 text-center'}>
																{item.venta_categoria}
															</td>
															
															{/*<td className={'padding-4 text-center'}>
																{item.venta_codigo}
															</td>*/}
															
															<td className={'padding-4 text-center'}>
																{item.venta_producto}
															</td>
															
															<td className={'padding-4 text-center'}>
																{item.venta_descripcion}
															</td>
															
															<td className={'padding-4 text-center'}>
																{item.venta_color}
															</td>
															
															<td className={'padding-4 text-center'}>
																{item.venta_talla}
															</td>
															
															<td className={'padding-4 text-center'}>
																{item.venta_cantidad}
															</td>
															
															<td className={'padding-4 text-right'}>
																$ {FieldsJs.Currency(item.venta_precio_unitario)}
															</td>
														</tr>
													</Fragment>
												))}
												</tbody>
											</table>
										
										</article>
										
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-T'}>
												<TextField
													select
													fullWidth
													onChange={(e) => {
														this.handleChange(e);
														setTimeout(() => {
															this.obtenerCostoMensajeria();
														}, 800);
													}}
													SelectProps={{
														native: true,
														MenuProps: {},
													}}
													name="id_cat_tipo_mensajeria"
													label="Tipo Mensajería"
													value={this.state.id_cat_tipo_mensajeria}
												>
													<option key={0} value={''}/>
													{this.state.mensajerias.map(option => (
														<option key={option.id_cat_tipo_mensajeria} value={option.id_cat_tipo_mensajeria}>
															{option.tipo_mensajeria}
														</option>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-T'}>
											</Grid>
											
											<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={''}>
												<TextField
													select
													fullWidth
													onChange={(e) => {
														this.handleChange(e);
													}}
													SelectProps={{
														native: true,
														MenuProps: {},
													}}
													name="id_cat_bolsa"
													label="Bolsa"
													value={this.state.id_cat_bolsa}
												>
													<option key={0} value={''}/>
													{this.state.bolsas.map(option => (
														<option key={option.id_cat_bolsa} value={option.id_cat_bolsa}>
															{option.bolsa + ' - $' + FieldsJs.Currency(option.costo)}
														</option>
													))}
												</TextField>
											</Grid>
											
											<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={''}>
												<Grid container spacing={1} alignItems={'flex-end'}>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={''}>
													
														<TextField
														fullWidth
														type="number"
														className={classes.root}
														inputProps={{
															className: classes.input,
															placeholder: "Cantidad"
														}}
														onKeyPress={EnteroSolo}
														name={'cantidad_bolsa'}
														label="Cantidad"
														onChange={(e) => {
															this.handleChange(e);
														}}
														value={this.state.cantidad_bolsa}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
														<Button fullWidth variant="contained" color="primary" onClick={() => this.agregarBolsa()} className={'btn-default-primary text-transform-none cursor-pointer'}>
															Agregar
														</Button>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-T'}>
											</Grid>
											
											
												{this.state.bolsas_seleccionadas.map((item, key) => (
													<Fragment>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<span className={'cursor-pointer margin-5-R padding-5-L padding-5-R'} style={{color: 'white', background: 'red', borderRadius: 10}} onClick={() => this.removeBolsa(key)}> x </span>
															<span className={'text-right margin-10-R padding-5-L padding-5-R'} style={{color: 'white', background: '#3f51b5', borderRadius: 10}}>{item.cantidad_bolsa + ' ' + item.bolsa}</span>
														</Grid>
													</Fragment>
												))}
											
											
											
											
											{/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-T'}>
												<div className={'vertical-inline w-100'}>
													<div className={'v-center w-50-px'}>
														<ModalCambiarDomicilio
															form={this.state}
															tipo={'add'}
															component={(
																<IconButton aria-label="settings">
																	<Add/>
																</IconButton>
															)}
															refresh={this.domicilios}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
													<div className={'v-center  w-100-50-px'}>
														<TextField
															select
															required={FieldsJs.inArray([1,2], this.state.id_cat_tipo_mensajeria)}
															fullWidth
															onChange={(e) => {
																this.handleChange(e);
															}}
															SelectProps={{
																native: true,
																MenuProps: {},
															}}
															name="id_usuario_direccion"
															label="Dirección de entrega"
															value={this.state.id_usuario_direccion}
														>
															<option key={0} value={''}/>
															{this.state.list_domicilios.map(option => (
																<option key={option.id_usuario_direccion} value={option.id_usuario_direccion}>
																	{CadenaDomicilio(option || {})}
																</option>
															))}
														</TextField>
													</div>
												</div>
											</Grid>*/}
											
											
										</Grid>
										
										{/*<Paginacion
											total={this.state.paginacion.total}
											page={this.state.paginacion.page}
											limit={this.state.paginacion.limit}
											rangos={this.state.paginacion.rangos}
											onClick={(data) => this.AplicarPaginacion(data)}
										/>*/}
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										mensaje={'No se han seleccionado productos.'}
									/>
								)}
							
							</Grid>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'left'}>
								{this.props.productos.length > 0 ? (
									<Fragment>
										{this.props.tipo === 'add' ? (
											<Button fullWidth variant="contained" color="primary" onClick={() => this.realizarPedido()} className={'btn-default-primary text-transform-none cursor-pointer'}>
												Generar Pedido
											</Button>
										) : (
											<Button fullWidth variant="contained" color="primary" onClick={() => this.modificarPedido()} className={'btn-default-primary text-transform-none cursor-pointer'}>
												Modificar Pedido
											</Button>
										)}
									</Fragment>
								) : null}
							</Grid>
						</Grid>
					
					</DialogContent>
					
					{/*<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'left'}>
								{this.props.productos.length > 0 ? (
									<Button fullWidth variant="contained" color="primary" onClick={() => this.realizarPedido()} className={'btn-default-primary text-transform-none cursor-pointer'}>
										Generar Pedido
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>*/}
				
				</Dialog>
			
			</div>
		);
	}
}

ModalProductosPreOrden.propTypes = {
	item: PropTypes.object,
	classes: PropTypes.object.isRequired,
	RefreshListCliente: PropTypes.func,
	showSnackBars: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	cat_costo_mensajeria: PropTypes.array.isRequired,
	productos: PropTypes.array.isRequired,
	menos: PropTypes.func.isRequired,
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	OrdenDeVentaGuardar: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalProductosPreOrden);



