import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const ProductoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				clave: filtro.clave || null,
				producto: filtro.producto || null,
				activo: filtro.activo || null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_producto: form.id_producto,
			id_cat_grupo: form.id_cat_grupo,
			id_cat_categoria: form.id_cat_categoria,
			clave: form.codigo,
			producto: form.producto,
			descripcion: form.descripcion,
			cantidad: form.cantidad,
			colores: form.colores,
			tallas: form.tallas,
			precio_unitario: form.precio_unitario,
			codigo_descuento: form.codigo_descuento,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_producto: form.id_producto,
			id_cat_grupo: form.id_cat_grupo,
			id_cat_categoria: form.id_cat_categoria,
			clave: form.codigo,
			producto: form.producto,
			descripcion: form.descripcion,
			cantidad: form.cantidad,
			colores: form.colores,
			tallas: form.tallas,
			precio_unitario: form.precio_unitario,
			codigo_descuento: form.codigo_descuento,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarCatalogo: (form) => {
		let params = {
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Modificar_Catalogo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_producto) => {
		let params = {
			id_producto: id_producto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Codigo: (id_cat_categoria) => {
		let params = {
			id_cat_categoria:id_cat_categoria
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Producto_Codigo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
