import React, {Component} from 'react';

import LogIn from './LogIn';

import './LogIn.css';

class LogInIndex extends Component {
	render() {
		return (
			<div className='LogInIndex'>
				
				<LogIn/>
			
			</div>
		);
	}
}

export default LogInIndex;
