import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const ExpedienteService = {
	Listar: (filtro, paginacion) => {
		let fecha_ingreso = filtro.fecha_ingreso ? DateFormat.FormatSql(filtro.fecha_ingreso):null;
		
		let params = {
			filtro: {
				id_expediente: filtro.id_expediente || null,
				nombre: filtro.nombre || null,
				curp: filtro.curp || null,
				rfc: filtro.rfc || null,
				id_cat_identificacion: filtro.id_cat_identificacion || null,
				fecha_ingreso: fecha_ingreso || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Expediente_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_nacimiento = DateFormat.FormatSql(form.fecha_nacimiento);
		let fecha_ingreso = DateFormat.FormatSql(form.fecha_ingreso);
		
		let params = {
			id_expediente: form.id_expediente,
			id_acreditado: form.id_acreditado,
			curp: form.curp,
			rfc: form.rfc,
			curp_valido: form.curp_valido,
			rfc_valido: form.rfc_valido,
			fecha_nacimiento: fecha_nacimiento,
			fecha_ingreso: fecha_ingreso,
			id_cat_sexo: form.id_cat_sexo,
			id_cat_identificacion: form.id_cat_identificacion,
			direccion: form.direccion,
			id_cat_comprobante_ingreso: form.id_cat_comprobante_ingreso,
			quien_es_quien: form.quien_es_quien,
			actua_por: form.actua_por,
			archivos: form.archivos || [],
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Expediente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_nacimiento = DateFormat.FormatSql(form.fecha_nacimiento);
		let fecha_ingreso = DateFormat.FormatSql(form.fecha_ingreso);
		
		let params = {
			id_expediente: form.id_expediente,
			id_acreditado: form.id_acreditado,
			curp: form.curp,
			rfc: form.rfc,
			curp_valido: form.curp_valido,
			rfc_valido: form.rfc_valido,
			fecha_nacimiento: fecha_nacimiento,
			fecha_ingreso: fecha_ingreso,
			id_cat_sexo: form.id_cat_sexo,
			id_cat_identificacion: form.id_cat_identificacion,
			direccion: form.direccion,
			id_cat_comprobante_ingreso: form.id_cat_comprobante_ingreso,
			quien_es_quien: form.quien_es_quien,
			actua_por: form.actua_por,
			archivos: form.archivos || [],
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Expediente_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_expediente) => {let params = {
			id_expediente: id_expediente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Expediente_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Validacion: (form) => {let params = {
			validated: form.validated,
			curp: form.curp || '',
			rfc: form.rfc || ''
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Expediente_Validacion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
