import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {ProductoService} from '../../../../services/_Sis/ProductoService/ProductoService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import Categoria from "../../Cat/Categoria/Categoria";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import {DropzoneArea} from "material-ui-dropzone";
import {CONFIG} from "../../../../settings/Config/Config";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";

class ModalProducto extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_producto: props.id_producto || 0,
			id_cat_grupo: '',
			id_cat_categoria: '',
			clave: '',
			producto: '',
			descripcion: '',
			color: '',
			talla: '',
			cantidad: '',
			precio_unitario: '',
			codigo_descuento: '',
			imagen_base64Tipo: '',
			imagen_base64: '',
			imagen_archivo: '',
			imagen_formato: '',
			imagen: '',
			activo: true,
			
			
			lista_grupo: [],
			lista_categoria: [],
			colores: [],
			tallas: [],
			files: [],
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			CatService.ListGrupo(true).then(response => {
				this.setState({
					lista_grupo: response.data,
				});
				resolve(true);
			}).catch(error => {
				this.props.showSnackBars('error', error.mensaje);
				this.setState({
					lista_grupo: [],
				});
				reject(false);
			});
			CatService.ListCategoria(true).then(response => {
				this.setState({
					lista_categoria: response.data,
				});
				resolve(true);
			}).catch(error => {
				this.props.showSnackBars('error', error.mensaje);
				this.setState({
					lista_categoria: [],
				});
				reject(false);
			});
		});
	};
	
	save = () => {
		if (this.state.id_producto > 0) {
			ProductoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ProductoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	nuevoCodigo = (id_cat_categoria) => {
		ProductoService.Codigo(id_cat_categoria).then((response) => {
			this.setState({
				clave: response.data.codigo,
				codigo: response.data.numero
			});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_producto: '',
			id_cat_grupo: '',
			id_cat_categoria: '',
			clave: '',
			codigo: '',
			colores: [],
			tallas: [],
			producto: '',
			descripcion: '',
			cantidad: '',
			precio_unitario: '',
			codigo_descuento: '',
			imagen_base64Tipo: '',
			imagen_base64: '',
			imagen_archivo: '',
			imagen_formato: '',
			imagen: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			id_producto: id_producto || '',
			id_cat_grupo: item.id_cat_grupo || '',
			id_cat_categoria: item.id_cat_categoria || '',
			clave: item.clave || '',
			codigo: item.codigo || '',
			colores: item.colores || [],
			tallas: item.tallas || [],
			producto: item.producto || '',
			descripcion: item.descripcion || '',
			cantidad: item.cantidad || '',
			precio_unitario: item.precio_unitario || '',
			codigo_descuento: item.codigo_descuento || '',
			imagen_base64Tipo: item.imagen_base64Tipo || '',
			imagen_base64: item.imagen_base64 || '',
			imagen_archivo: item.imagen_archivo || '',
			imagen_formato: item.imagen_formato || '',
			imagen: item.imagen || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			id_producto: id_producto || '',
			id_cat_grupo: item.id_cat_grupo || '',
			id_cat_categoria: item.id_cat_categoria || '',
			clave: item.clave || '',
			codigo: item.codigo || '',
			colores: item.colores || [],
			tallas: item.tallas || [],
			producto: item.producto || '',
			descripcion: item.descripcion || '',
			cantidad: item.cantidad || '',
			precio_unitario: item.precio_unitario || '',
			codigo_descuento: item.codigo_descuento || '',
			imagen_base64Tipo: item.imagen_base64Tipo || '',
			imagen_base64: item.imagen_base64 || '',
			imagen_archivo: item.imagen_archivo || '',
			imagen_formato: item.imagen_formato || '',
			imagen: item.imagen || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	agregar_color = () => {
		
		try {
			
			if (this.state.color <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Campo color requerido.")
				});
			}
			
			let arr_temp = this.state.colores;
			let color = this.state.color;
			let exist_empresa = false;
			
			for (let i = 0; i < this.state.colores.length; i++) {
				if (this.state.colores[i].color === color) {
					exist_empresa = true;
				}
			}
			
			if (exist_empresa) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("El color ya se encuentra agregado.")
				});
			}
			
			arr_temp.push({
				color: color,
			});
			
			this.setState({
				colores: arr_temp,
				color: '',
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	agregar_talla = () => {
		
		try {
			
			if (this.state.talla <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Campo talla requerido.")
				});
			}
			
			let arr_temp = this.state.tallas;
			let talla = this.state.talla;
			let exist_empresa = false;
			
			for (let i = 0; i < this.state.tallas.length; i++) {
				if (this.state.tallas[i].talla === talla) {
					exist_empresa = true;
				}
			}
			
			if (exist_empresa) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("La talla ya se encuentra agregado.")
				});
			}
			
			arr_temp.push({
				talla: talla,
			});
			
			this.setState({
				tallas: arr_temp,
				talla: ''
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	eliminar_color = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.colores);
		arr_temp.splice(key, 1);
		this.setState({
			colores: arr_temp
		});
	};
	
	eliminar_talla = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.tallas);
		arr_temp.splice(key, 1);
		this.setState({
			tallas: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_producto > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (file) => {
			this.setState({files:file}, () => {
				if (this.state.files.length > 0) {
					this.FileBase64(this.state.files[0]);
				}
			})
	};
	
	FileBase64 = (e, id) => {
		
		let fil = {files: [e]};
		console.log("FILEEE:::::",e);
		let formatos = [
			"image/jpeg",
			"image/png"
		];
		FileBase64.Base64(fil, formatos).then((response) => {
			this.setState({
				imagen_base64: response.base64,
				imagen_base64Tipo: response.base64Tipo,
				imagen_archivo: response.archivo,
				imagen_formato: response.formato,
			});
		}).catch((error) => {
			console.log("ERROR:::::",error);
			this.props.showSnackBars('error', error.mensaje);
			this.setState({
				imagen_base64: "",
				imagen_base64Tipo: "",
				imagen_archivo: "",
				imagen_formato: "",
			});
		});
	};
	
	
	render() {
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<Grid>
				
				{BTN_ACTION}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Producto</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										// this.nuevoCodigo(e.target.value);
									}}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									required
									helperText="Requerido"
									name="id_cat_categoria"
									label="Categoría"
									value={this.state.id_cat_categoria}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_categoria.map(option => (
										<option key={option.id_cat_categoria} value={option.id_cat_categoria}>
											{option.categoria}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									required
									//onKeyPress={EnteroSolo}
									//inputProps={{maxLength: 10}}
									name="clave"
									label="Código"
									value={this.state.clave}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									required
									name="producto"
									label="Modelo"
									value={this.state.producto}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
								
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									required
									name="color"
									label="Color"
									value={this.state.color}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline h-100'}>
									<Button size={'small'} variant="contained"
									        onClick={this.agregar_color}
									        className={'text-white v-end text-transform-none ' + (this.props.tipo === 'view' ? 'btn-gray' : 'btn-default-primary')}
									        disabled={this.props.tipo === 'view'}>
										Agregar
									</Button>
								</div>
							
							</Grid>
							
							{this.state.colores.length > 0 ? (
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<div className={'w-100 text-left'}>
										{this.state.colores.map((item, key) => (
											<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
												<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
													{item.color}
												</div>
												<div className={'padding-3 padding-10-R v-center text-white'}>
													{this.props.tipo === 'view' ? null : (
														<DeleteOutlined className={'px-18 text-white'} onClick={() => this.eliminar_color(key)}/>
													)
													}
												</div>
											</div>
										))
										}
									</div>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
								
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 10}}
									name="talla"
									label="Talla"
									value={this.state.talla}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline h-100'}>
									<Button size={'small'} variant="contained"
									        onClick={this.agregar_talla}
									        className={'text-white v-end text-transform-none ' + (this.props.tipo === 'view' ? 'btn-gray' : 'btn-default-primary')}
									        disabled={this.props.tipo === 'view'}>
										Agregar
									</Button>
								</div>
							</Grid>
							
							{this.state.tallas.length > 0 ? (
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<div className={'w-100 text-left'}>
										{this.state.tallas.map((item, key) => (
											<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
												<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
													{item.talla}
												</div>
												<div className={'padding-3 padding-10-R v-center text-white'}>
													{this.props.tipo === 'view' ? null : (
														<DeleteOutlined className={'px-18 text-white'} onClick={() => this.eliminar_talla(key)}/>
													)
													}
												</div>
											</div>
										))
										}
									</div>
								</Grid>
							) : null}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									name="descripcion"
									label="Descripción"
									value={this.state.descripcion}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="none"
									name="precio_unitario"
									label="Precio unitario"
									value={this.state.precio_unitario}
								/>
							
							</Grid>
							
							{ this.state.imagen ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<img src={CONFIG.src + this.state.imagen} alt="{{env('FROM_NAME_SOUPORT')}}" width="100px" height="auto" />
								</Grid>
							) : null }
							
							{this.state.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={1}
										dropzoneText={'Arrastre y suelte una imagen del producto aquí o haga clic'}
										acceptedFiles={["image/png", "image/jpeg","image/*"]}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
									{/*<BoxSelectFile
									FormatAccepted={["image/png", "image/jpeg"]}
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Imagen Producto</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.state.imagen_base64,
										base64Tipo: this.state.imagen_base64Tipo,
										archivo: this.state.imagen_archivo,
										formato: this.state.imagen_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'imagen_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'imagen_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
									disabled={this.state.tipo === 'view'}
								/>*/}
								</Grid>
							) : null }
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.close()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_producto > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}

ModalProducto.propTypes = {
	id_producto: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalProducto;
