class HomeResponsive {
	get = () => {
		let whith = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		let height = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		let settings = {};
		return new Promise(resolve => {
			if (whith <= 399) {
				if (height <= 399) {
					settings = {
						spacing: 3,
						widthImg: (150) / 3,
						marginImg: (30) / 3,
						widthContent: (400) / 3,
						heightContent: (80) / 3,
						heightText: (70) / 3,
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 3,
						widthImg: (150) / 3,
						marginImg: (30) / 3,
						widthContent: (400) / 3,
						heightContent: (80) / 3,
						heightText: (70) / 3,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 3,
						widthImg: (150) / 3,
						marginImg: (30) / 3,
						widthContent: (400) / 3,
						heightContent: (80) / 3,
						heightText: (70) / 3,
					}
				}
			} else if (whith >= 400 && whith <= 575) {
				if (height <= 399) {
					settings = {
						spacing: 5,
						widthImg: (150) / 2,
						marginImg: (30) / 2,
						widthContent: (400) / 2,
						heightContent: (80) / 2,
						heightText: (70) / 2,
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 5,
						widthImg: (150) / 2,
						marginImg: (30) / 2,
						widthContent: (400) / 2,
						heightContent: (80) / 2,
						heightText: (70) / 2,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 5,
						widthImg: (150) / 2,
						marginImg: (30) / 2,
						widthContent: (400) / 2,
						heightContent: (80) / 2,
						heightText: (70) / 2,
					}
				}
			} else if (whith >= 576 && whith <= 767) {
				if (height <= 399) {
					settings = {
						spacing: 5,
						widthImg: ((150) / 2),
						marginImg: ((30) / 2),
						widthContent: ((400) / 2),
						heightContent: ((80) / 2),
						heightText: ((70) / 2),
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 5,
						widthImg: ((150) / 3) * 2,
						marginImg: ((30) / 3) * 2,
						widthContent: ((400) / 3) * 2,
						heightContent: ((80) / 3) * 2,
						heightText: ((70) / 3) * 2,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 5,
						widthImg: ((150) / 3) * 2,
						marginImg: ((30) / 3) * 2,
						widthContent: ((400) / 3) * 2,
						heightContent: ((80) / 3) * 2,
						heightText: ((70) / 3) * 2,
					}
				}
			} else if (whith >= 768 && whith <= 991) {
				if (height <= 399) {
					settings = {
						spacing: 10,
						widthImg: ((150) / 2),
						marginImg: ((30) / 2),
						widthContent: ((400) / 2),
						heightContent: ((80) / 2),
						heightText: ((70) / 2),
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 10,
						widthImg: ((150) / 3) * 2,
						marginImg: ((30) / 3) * 2,
						widthContent: ((400) / 3) * 2,
						heightContent: ((80) / 3) * 2,
						heightText: ((70) / 3) * 2,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 10,
						widthImg: ((150) / 3) * 2,
						marginImg: ((30) / 3) * 2,
						widthContent: ((400) / 3) * 2,
						heightContent: ((80) / 3) * 2,
						heightText: ((70) / 3) * 2,
					}
				}
			} else if (whith >= 992 && whith <= 1199) {
				if (height <= 399) {
					settings = {
						spacing: 10,
						widthImg: (150 / 2),
						marginImg: (30 / 2),
						widthContent: (400 / 2),
						heightContent: (80 / 2),
						heightText: (70 / 2),
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 10,
						widthImg: 150,
						marginImg: 30,
						widthContent: 400,
						heightContent: 80,
						heightText: 70,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 10,
						widthImg: 150,
						marginImg: 30,
						widthContent: 400,
						heightContent: 80,
						heightText: 70,
					}
				}
			} else if (whith >= 1200) {
				if (height <= 399) {
					settings = {
						spacing: 10,
						widthImg: (150 / 2),
						marginImg: (30 / 2),
						widthContent: (400 / 2),
						heightContent: (80 / 2),
						heightText: (70 / 2),
					}
				} else if (height >= 400 && height <= 575) {
					settings = {
						spacing: 10,
						widthImg: 150,
						marginImg: 30,
						widthContent: 400,
						heightContent: 80,
						heightText: 70,
					}
				} else if (height >= 576) {
					settings = {
						spacing: 10,
						widthImg: 150,
						marginImg: 30,
						widthContent: 400,
						heightContent: 80,
						heightText: 70,
					}
				}
			}
			resolve(settings);
		});
	}
}

export default new HomeResponsive();
