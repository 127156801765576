import React, {Component} from 'react';

import RegistroDatosPersonales from './RegistroDatosPersonales';

import './RegistroDatosPersonales.css';

class RegistroDatosPersonalesIndex extends Component {
	render() {
		return (
			<div className='RegistroDatosPersonalesIndex'>
				
				<RegistroDatosPersonales/>
			
			</div>
		);
	}
}

export default RegistroDatosPersonalesIndex;
